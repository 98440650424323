import React from 'react';
import classNames from 'classnames';

import FormFieldError from './FormFieldError';

const FormItem = ( {
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  type,
  name,
  label,
  disabled,
} ) => (
  <div
    className={classNames( 'form__item', {
      'form-error': errors[name] && touched[name],
    } )}
  >
    <label htmlFor={name}>{label}</label>
    <input
      type={type}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values}
      disabled={disabled}
    />
    { errors[name] && touched[name] && (
    <FormFieldError errorMsg={errors[name]} />
    )}
  </div>
);

export default FormItem;
