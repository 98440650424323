import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const HorizontalNavBarItem = ({
  path,
  label,
}) => {
  // Capture search params from current url location.
  const { search } = useLocation();

  return (
    <NavLink
      to={`${path}${search}`}
      className="type-cta-md"
    >
      {label}
    </NavLink>
  );
};

export default HorizontalNavBarItem;
