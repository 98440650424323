import objectPath from 'object-path';
import {
  patchResidentInDrupal,
  postResidentInDrupal,
  createResidentTitle,
} from '../../Resident/ResidentHelpers';

import {
  toIsoString,
  approximateMyFavoritePageUrl,
} from '../../../utils/common';

/**
 * @param {object} selectedResident
 * @param {object} values
 *
 * @returns {object}
 */
export function compareIncomingCrmDataToFormValues(selectedResident, values) {
  const updatedFormData = {};
  let formIntent = 'No Changes';

  // Create an association between form fields and CRM data to easily check
  // for modified form data. These are the keys for `FormFields: CRMData`.
  const comparisonMatcher = {
    first_name: 'first_name',
    last_name: 'last_name',
    email: 'email',
    person2_id: 'person2_id',
    person2_first_name: 'person2_first_name',
    person2_last_name: 'person2_last_name',
    person2_email: 'person2_email',
  };

  // Check to see if the data in the form has changed from what
  // we got back from the CRM. To know if we need to update the CRM with
  // user updated info.
  for (const formDataKey in comparisonMatcher) {
    const crmDataKey = comparisonMatcher[formDataKey];

    // Compare form data with CRM data by matching deep object paths.
    // See: https://www.npmjs.com/package/object-path
    if (values[formDataKey] !== objectPath.get(selectedResident, crmDataKey)) {
      // I'm using formDataKey as the for the updatedFormData array because
      // using the crmkey gives back strings that look like object.properties,
      // but aren't.
      updatedFormData[formDataKey] = values[formDataKey];
    }
  }

  if (Object.keys(updatedFormData).length > 0) {
    formIntent = 'Update Resident(s) and/or New Spouse';
  }

  return {
    formIntent,
    updatedFormData,
  };
}

/**
 *
 * @param {array} updateResidents
 * @param {object} cookieData
 * @param {string} urlSlug
 *
 * @returns {object}
 */
export function updateNewResidentCookieAfterResidentUpdate(
  updateResidents,
  cookieData,
  urlSlug
) {
  const newCookieData = cookieData;

  const person1Data = updateResidents[0].data;
  newCookieData.drupal_uuid = person1Data.id;

  // We can't retrieve a new person's My Favorites URL because it is made
  // on load of node edit form or save of a node, neither of which have
  // happened. Instead, we'll approximate it. As long as the nid is correct, it will work.
  const url = approximateMyFavoritePageUrl(
    person1Data.attributes.title,
    person1Data.attributes.drupal_internal__nid,
    urlSlug
  );
  newCookieData.my_favorites_page = url;

  // Get the last array item, it will always be the most recent session.
  const sessions = person1Data?.attributes?.field_yt_sessions;
  const currentSession = sessions?.length > 0 ? sessions[sessions.length - 1] : null;
  if ( currentSession ) {
    newCookieData.yourtour_active_session = currentSession;
  }

  return newCookieData;
}

/**
 *
 * @param {array} updateResidents
 * @param {object} cookieData
 *
 * @returns {object}
 */
export function updateExistingResidentCookieAfterResidentUpdate(
  residents,
  cookieData
) {
  const person1Data = residents[0].data;

  // Get the last array item, it will always be the most recent session.
  const sessions = person1Data?.attributes?.field_yt_sessions;
  const currentSession = sessions ? sessions[sessions.length - 1] : null;

  // Make new cookie data.
  const newCookieData = cookieData;
  if (currentSession) {
    newCookieData.yourtour_active_session = currentSession;
  }

  return newCookieData;
}

/**
 *
 * @param {object} person1AttributesData
 * @param {object} person2AttributesData
 * @param {object} apiActions
 *
 * @returns {array}
 */
export async function conditionallyPostCoupleToDrupal(
  person1AttributesData,
  person2AttributesData,
  apiActions,
  communityUuid
) {
  // This has to be done in sync because Drupal invalidates tokens for each
  // post/patch action. Therefore, if we try to send them async, one will always
  // fail with the wrong token.
  let person1Promise = null;
  if (apiActions.postPerson1 && person1AttributesData) {
    person1Promise = await postResidentInDrupal(
      person1AttributesData,
      communityUuid
    ).catch((error) => {
      console.log(error.message);
    });
  }

  let person2Promise = null;
  // get new token since the previous request invalidates the current one
  if (apiActions.postPerson2 && person2AttributesData) {
    person2Promise = await postResidentInDrupal(
      person2AttributesData,
      communityUuid
    ).catch((error) => {
      console.log(error.message);
    });
  }

  const newResidents = [person1Promise, person2Promise];

  return newResidents;
}

/**
 * @param {Object} person1AttributesData
 * @param {Object} person2AttributesData
 * @param {Object} uuids
 * @param {Object} apiActions
 *
 * @returns {Array}
 */
export async function conditionallyPatchCoupleInDrupal(
  person1AttributesData,
  person2AttributesData,
  uuids,
  apiActions
) {
  // This has to be done in order because Drupal invalidates tokens for each
  // post/patch action. Therefore, if we try to send them async, one will always
  // fail with the wrong token.

  // Patch person 1 with attribute and spouse changes.
  let person1Promise = {};
  if (apiActions.patchPerson1 || apiActions.removePerson1Spouse) {
    const spouse = apiActions.removePerson1Spouse ? '' : uuids.person1Spouse;
    person1Promise = await patchResidentInDrupal(
      uuids.person1,
      person1AttributesData,
      spouse,
      {}
    ).catch((error) => {
      console.log(error.message);
    });
  }

  // Patch person 2 with attribute and spouse changes.
  let person2Promise = {};
  if (apiActions.patchPerson2 || apiActions.removePerson2Spouse) {
    const spouse = apiActions.removePerson2Spouse ? '' : uuids.person2Spouse;
    person2Promise = await patchResidentInDrupal(
      uuids.person2,
      person2AttributesData,
      spouse,
      {}
    ).catch((error) => {
      console.log(error.message);
    });
  }
  const updatedResidents = [person1Promise, person2Promise];

  return updatedResidents;
}

/**
 * @param {Object} values
 * @param {String} formIntent
 * @param {Object} selectedResidentCrmData
 * @param {Object} updatedFormData
 *
 * @returns {Object}
 */
export function createPersonAttributeDataUsingIntent(
  values,
  formIntent,
  selectedResidentCrmData,
  updatedFormData
) {
  // Format incoming data to post/patch to Drupal via jsonapi with the
  // appropriate drupal property names and data structure. Just changed values
  // are sent to drupal, so don't add empty properties.

  // Assemble the changed/new data.
  let person1AttributesData = {};
  let person2AttributesData = {};

  switch (formIntent) {
    case 'New Resident(s)': {
      // Person 1.
      const title = createResidentTitle(
        values.first_name,
        values.last_name,
        values.email
      );
      person1AttributesData = {
        field_email: values.email,
        field_first_name: values.first_name,
        field_last_name: values.last_name,
        title,
      };

      if (selectedResidentCrmData?.crmIndividualId) {
        person1AttributesData.field_crm_individual_id =
          selectedResidentCrmData.crmIndividualId;
      }

      if (selectedResidentCrmData?.crmProspectId) {
        person1AttributesData.field_crm_prospect_id =
          selectedResidentCrmData.crmProspectId;
      }

      // Person 2.
      if (
        values.person2_first_name ||
        values.person2_last_name ||
        values.person2_email
      ) {
        const person2Title = createResidentTitle(
          values.person2_first_name,
          values.person2_last_name,
          values.person2_email
        );
        person2AttributesData = {
          field_email: values.person2_email,
          field_first_name: values.person2_first_name,
          field_last_name: values.person2_last_name,
          title: person2Title,
        };

        const person2CrmIndividualId =
          selectedResidentCrmData?.person2CrmIndividualId || null;
        if (person2CrmIndividualId) {
          person2AttributesData.field_crm_individual_id = person2CrmIndividualId;
        }

        if (selectedResidentCrmData?.crmProspectId) {
          person2AttributesData.field_crm_prospect_id =
            selectedResidentCrmData.crmProspectId;
        }
      }

      break;
    }
    case 'Update Resident(s) and/or New Spouse':
      // Person 1.
      if (updatedFormData.email) {
        person1AttributesData.field_email = updatedFormData.email;
      }
      if (updatedFormData.first_name) {
        person1AttributesData.field_first_name = updatedFormData.first_name;
      }
      if (updatedFormData.last_name) {
        person1AttributesData.field_last_name = updatedFormData.last_name;
      }
      if (updatedFormData.first_name || updatedFormData.last_name) {
        const firstName = updatedFormData.first_name || values.first_name;
        const lastName = updatedFormData.last_name || values.last_name;
        const email = updatedFormData.email || values.email;
        person1AttributesData.title = createResidentTitle(
          firstName,
          lastName,
          email
        );
      }

      // Person 2.
      if (
        updatedFormData.person2_email === '' &&
        updatedFormData.person2_first_name === '' &&
        updatedFormData.person2_last_name === '' &&
        values.person2_drupal_uuid === ''
      ) {
        // Do nothing, no data was changed.
      } else {
        if (
          updatedFormData.person2_email ||
          updatedFormData.person2_email === ''
        ) {
          person2AttributesData.field_email = updatedFormData.person2_email;
        }
        if (
          updatedFormData.person2_first_name ||
          updatedFormData.person2_first_name === ''
        ) {
          person2AttributesData.field_first_name =
            updatedFormData.person2_first_name;
        }
        if (
          updatedFormData.person2_last_name ||
          updatedFormData.person2_last_name === ''
        ) {
          person2AttributesData.field_last_name =
            updatedFormData.person2_last_name;
        }
        if (
          updatedFormData.person2_first_name ||
          updatedFormData.person2_last_name
        ) {
          const firstName =
            updatedFormData.person2_first_name || values.person2_first_name;
          const lastName =
            updatedFormData.person2_last_name || values.person2_last_name;
          const email = updatedFormData.person2_email || values.person2_email;
          person2AttributesData.title = createResidentTitle(
            firstName,
            lastName,
            email
          );
        }
      }
      break;
    default:
      break;
  }

  return {
    person1AttributesData,
    person2AttributesData,
  };
}

/**
 * @param {Object} values
 * @param {String} formIntent
 * @param {Object} person1AttributesData
 * @param {Object} person2AttributesData
 *
 * @returns {Object}
 */
export function processFormDataUsingIntent(
  values,
  formIntent,
  person1AttributesData,
  person2AttributesData
) {
  // Null value means don't update anything.
  // {} and '' value means erase what's there.

  const uuids = {
    person1: null,
    person2: null,
    person1Spouse: null,
    person2Spouse: null,
  };

  if (values.drupal_uuid !== '') {
    uuids.person1 = values.drupal_uuid;
    uuids.person2Spouse = values.drupal_uuid;
  }
  if (values.person2_drupal_uuid !== '') {
    uuids.person2 = values.person2_drupal_uuid;
    uuids.person1Spouse = values.person2_drupal_uuid;
  }

  const apiActions = {
    patchPerson1: false,
    postPerson1: false,
    patchPerson2: false,
    postPerson2: false,
    removePerson1Spouse: false,
    removePerson2Spouse: false,
  };

  // Determine what to do with the data.
  switch (formIntent) {
    case 'New Resident(s)':
      // There's no data in Drupal for these folks, post them to Drupal.
      apiActions.postPerson1 = true;

      // Check for Person 2.
      if (Object.keys(person2AttributesData).length > 0) {
        apiActions.postPerson2 = true;
        // Once we post Person 2, we need to update Person 1's relationship to them.
        apiActions.patchPerson1 = true;
        // Then we need to patch Person 2 wih relationship to Person 1.
        apiActions.patchPerson2 = true;
      }

      break;
    case 'Update Resident(s) and/or New Spouse':
      // Something changed, but there are a lot of scenarios to account for.

      // Check if Person 1 fields changed (fname, lname, or email).
      if (Object.keys(person1AttributesData).length > 0) {
        apiActions.patchPerson1 = true;
      }

      // Check if Person 2 fields changed (fname, lname, or email).
      if (Object.keys(person2AttributesData).length) {
        // In all scenarios, that means we're going to patch Person 2 -
        // either because they already exist or because we'll have to create them
        // and then patch them with a relationship to Person 1.
        apiActions.patchPerson2 = true;

        // Check if all the data removed for Person 2.
        // If Person 2 data was empty when received from the CRM, it wouldn't
        // have been added to updatedFormData, so we know the user really did
        // clear all the fields. We also know person 2 had to already be in
        // drupal to be returned by the crm data.
        if (
          person2AttributesData.field_first_name === '' &&
          person2AttributesData.field_last_name === '' &&
          person2AttributesData.field_email === '' &&
          values.person2_drupal_uuid
        ) {
          // Don't delete Person 2, but we need remove the relationship
          // between Person 1 and Person 2. Set Person 1 and Person 2 spouse
          // uuids to an empty string. This tells updateResidentInCRM remove
          // spouse relationship.
          apiActions.patchPerson1 = true;
          apiActions.removePerson1Spouse = true;
          apiActions.removePerson2Spouse = true;
          uuids.person1Spouse = '';
          uuids.person2Spouse = '';

          // We don't want to update any changed Person 2 attributes becaue we
          // don't want the deleted info from the form to delete the Drupal
          // data. So instead, clear the updated person2AttributesData
          person2AttributesData = {};
        }

        // Check if all the Person 2 values changed.
        else if (
          person2AttributesData.field_first_name &&
          person2AttributesData.field_last_name &&
          person2AttributesData.field_email
        ) {
          // Check if Person 2 uuid already exists.
          if (values.person2_drupal_uuid && values.person2_drupal_uuid !== '') {
            // That means Person 2 already existed in Drupal. This means we
            // have 2 choices:
            // 1) We interpret this as the wrong person was entered to begin
            //    with and we remove the existing Person 2 relationship (not
            //    delete Person 2) and create a new one.
            // 2) We interpret this as there were just small changes to each
            //    field and we just update Person 2. We're going with #2
            //    because guessing people's intent is hard and #2 its easier.
            // Maybe long term we can add a checkbox that says something
            // like "This is the wrong spouse". Long story short, patch
            // Person 2 - which is the default.
          }
          // Person 2 didn't exist - they're new.
          else {
            // Post new Person 2.
            apiActions.postPerson2 = true;
            // Patch both with spouse uuids.
            apiActions.patchPerson1 = true;
          }
        }
        // If there wasn't already an id for Person 2, we need to post them
        // and patch spouse relationships to both.
        else if (!values.person2_drupal_uuid) {
          apiActions.postPerson2 = true;
          apiActions.patchPerson1 = true;
          apiActions.patchPerson2 = true;
        } else {
          apiActions.patchPerson2 = true;
        }
      }
      break;
    case 'No Changes':
      // Nothing changed, so we don't need send any data to Drupal.
      break;
    default:
      break;
  }

  const updatedPerson2AttributesData = person2AttributesData;

  return {
    apiActions,
    uuids,
    updatedPerson2AttributesData,
  };
}

/**
 * @param {object} uuids
 * @param {object} values
 * @param {object} selectedResidentCrmData
 * @param {string} urlSlug
 *
 * @returns {object}
 */
export function createInitialResidentCookieData(
  uuids,
  values,
  selectedResidentCrmData,
  urlSlug,
  salespersonNid,
) {
  // Write cookies from crm and form data.

  const now = new Date();
  const iso = toIsoString(now);

  const cookieData = {
    drupal_uuid: uuids.person1,
    first_name: values.first_name,
    last_name: values.last_name,
    person2_drupal_uuid: uuids.person2,
    person2_first_name: values.person2_first_name,
    person2_last_name: values.person2_last_name,
    favorites_page_message: selectedResidentCrmData?.favoritesPageMessage,
    yourtour_active_session: {
      value: iso,
      end_value: iso,
      uuid: null,
      user_id: salespersonNid,
    },
  };

  // If the current my favorites page value is null, approximate the url.
  let myFavoritesPage = values.my_favorites_page;
  if (!myFavoritesPage) {
    const fullName = `${values.first_name} ${values.last_name}`;
    const nid = selectedResidentCrmData?.drupalNid;
    if (nid) {
      myFavoritesPage = approximateMyFavoritePageUrl(fullName, nid, urlSlug);
    }
  }

  cookieData.my_favorites_page = myFavoritesPage;

  return cookieData;
}

/**
 * @param {object} selectedResidentCrmData
 *
 * @returns {object}
 */
export function createInitialFavoritesCookieData(selectedResidentCrmData) {
  // Write cookies from crm data.
  const cookieData = {
    favorites: {},
  };

  // Add favorites to cookie from CRM, if they exist.
  const favoritePlaces = selectedResidentCrmData?.favoriteAmenities;
  cookieData.favorites.place = [];
  if (favoritePlaces?.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const place of favoritePlaces) {
      cookieData.favorites.place.push(place.id);
    }
  }

  const favoriteFloorPlans = selectedResidentCrmData?.favoriteFloorPlans;
  cookieData.favorites.floor_plan = [];
  if (favoriteFloorPlans?.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const floorPlan of favoriteFloorPlans) {
      cookieData.favorites.floor_plan.push(floorPlan.id);
    }
  }

  return cookieData;
}

/**
 * @param {object} selectedResidentCrmData
 *
 * @returns {object}
 */
export function createInitialMediaFavoritesCookieData(selectedResidentCrmData) {
  // Write cookies from crm data.
  const cookieData = {
    favorites: {},
  };

  const favoriteMedia = selectedResidentCrmData?.favoriteMedia;
  cookieData.favorites.virtual_tour = [];
  cookieData.favorites.video = [];
  cookieData.favorites.photo_gallery = [];

  if (favoriteMedia?.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const media of favoriteMedia) {
      cookieData.favorites[media.type].push(media.id);
    }
  }

  return cookieData;
}
