import React from 'react';

const MapControlsZoomResetIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
  >
    <path
      d="M19,0.14C8.6,0.14,0.14,8.6,0.14,19S8.6,37.86,19,37.86S37.86,29.4,37.86,19S29.4,0.14,19,0.14z M20.5,34.79v-4.8
        c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.5v4.8C9.95,34.08,3.92,28.05,3.21,20.5h4.81c0.83,0,1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5
        H3.21C3.92,9.95,9.95,3.92,17.5,3.21v4.81c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5V3.21c7.55,0.71,13.58,6.74,14.29,14.29h-4.8
        c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5h4.8C34.08,28.05,28.05,34.08,20.5,34.79z"
    />
    <g>
      <circle className="pathlike" cx="19" cy="19" r="3.92" />
    </g>
  </svg>
);

export default MapControlsZoomResetIcon;
