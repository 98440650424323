import React, { useState } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ModalLiteHeader from './ModalLiteHeader';
import Portal from '../../utils/Portal';

import './Modal.scss';

const ModalLite = ( props ) => {
  const [ modalScrollActive, setModalScrollActive ] = useState( false );

  const {
    isOpen,
    modalStyle,
    modalSize,
    modalClassName,
    showCloseButton,
    showBlur,
    onEnter,
    handleModalHide,
    onExitFinish,
    setModalIsOpen,
    modalLiteHeaderData,
  } = props;

  const handleModalScroll = ( event ) => {
    if ( event.currentTarget.scrollTop > 20 ) {
      setModalScrollActive( true );
    } else {
      setModalScrollActive( false );
    }
  };

  return (
    <Portal portalId="modal-root">
      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames="modalLite"
        unmountOnExit
        onExit={handleModalHide}
        onEnter={onEnter}
        onExited={() => {
          if ( onExitFinish ) {
            onExitFinish();
          }
        }}
      >
        <div className={classnames( 'modalContainer', { 'modal-scroll-active': modalScrollActive } )}>

          { showBlur && (
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            <div
              className="blur closeMe"
              onClick={handleModalHide}
            />
          )}

          { modalLiteHeaderData && (
            <ModalLiteHeader
              title={modalLiteHeaderData.title}
              navBtns={modalLiteHeaderData.navBtns}
              setModalIsOpen={setModalIsOpen}
            />
          )}

          <div
            className={classnames( `modal ${modalClassName || ''}`, {
              'modal--floating modal--small ': modalSize === 'small',
              'modal--small-medium ': modalSize === 'small-medium',
              'modal--floating modal--medium': modalSize === 'medium',
              'modal--noHeader modal--small-medium ': modalSize === 'noheader-small-medium',
            } )}
            onScroll={handleModalScroll}
            style={modalStyle}
          >
            { showCloseButton && (
              <button
                type="button"
                className="modal__close--alt"
                onClick={handleModalHide}
              >
                <span>Close</span>
              </button>
            )}

            <div className="modalContent">
              { props.children }
            </div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default ModalLite;
