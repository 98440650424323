import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';

import { toIsoString } from '../../../utils/common';
import { getResidentYtSessions, patchResidentInDrupal } from '../../Resident/ResidentHelpers';
import { combineAndFormatYtSessionsForDrupal } from '../PersonalizationHelpers';

import './Favorite.scss';

const cookies = new Cookies();

const Favorite = ( {
  type,
  uuid,
  hasShadow,
} ) => {
  const [ favorited, setFavorited ] = useState( false );

  const { crmConfig } = useContext( ConfigContext );

  const { machineName } = crmConfig;

  // When type or uuid change, favorite everything in the cookie and remove existing favorites.
  useEffect( () => {
    if ( type && uuid ) {
      let cookieName = null;
      if ( type === 'virtual_tour' || type === 'video' || type === 'photo_gallery' ) {
        cookieName = 'media_favorites';
      } else {
        cookieName = 'favorites';
      }
      const cookieData = cookies.get( cookieName );
      const favorites = cookieData?.favorites[type];
      if ( favorites?.includes( uuid ) ) {
        setFavorited( true );
      } else {
        setFavorited( false );
      }
    }
  }, [ type, uuid ] );

  const getCookiesAndUpdateThem = () => {
    let cookieName = null;
    if ( type === 'virtual_tour' || type === 'video' || type === 'photo_gallery' ) {
      cookieName = 'media_favorites';
    } else {
      cookieName = 'favorites';
    }

    const favoritesCookieData = cookies.get( cookieName );

    // Check if there there already favorites of this type.
    const favorites = favoritesCookieData?.favorites;
    const favoritesHasType = favoritesCookieData
      ? Object.prototype.hasOwnProperty.call( favorites, type ) : false;

    if ( favoritesHasType ) {
      // State before click.
      if ( favorited ) {
        // If it was already favorited it should be in the array, find the
        // index.
        const match = uuid;
        const index = favoritesCookieData.favorites[type].indexOf( match );
        // Remove the favorited item.
        if ( index >= 0 ) {
          favoritesCookieData.favorites[type].splice( index, 1 );
        }
        // If this leaves the property empty, remove it.
        if ( favoritesCookieData.favorites[type].length === 0 ) {
          delete favoritesCookieData.favorites[type];
        }
      } else {
        // ELSE - It wasn't previously favorited, add it.
        favoritesCookieData.favorites[type].push( uuid );
      }
    } else {
      // ELSE - There aren't any favorites of this type.
      // Make a new property for this type and add our id to it.
      favoritesCookieData.favorites[type] = [];
      favoritesCookieData.favorites[type].push( uuid );
    }

    // Make new favorite cookie data.
    const newFavoritesCookieData = {
      favorites: favoritesCookieData.favorites,
    };

    // Delete old favorites cookies.
    cookies.remove( cookieName );

    // Set new favorites cookie.
    cookies.set( cookieName, newFavoritesCookieData, {
      path: '/',
      maxAge: 14400, // 4 hour cookie expiration.
      domain: window.location.hostname,
    } );

    // Handle current yt session time.
    const residentCookie = cookies.get( 'resident' );

    // Get a new end time for session. We update the end time every time a
    // favorite is submitted so that if the comm admin doens't end the session
    // correctly there's atleast some sense of the length of the session.
    const now = new Date();
    const iso = toIsoString( now );
    residentCookie.yourtour_active_session.end_value = iso;

    // Delete old yourtour session cookie.
    cookies.remove( 'resident' );

    // Set new yourtour session cookie.
    cookies.set( 'resident', residentCookie, {
      path: '/',
      maxAge: 14400, // 4 hour cookie expiration.
      domain: window.location.hostname,
    } );
  };

  const patchResidentInCRM = async () => {
    const residentCookieData = cookies.get( 'resident' );

    // Update end time for current session incase the comm admin doesn't
    // complete the Personaliation process.
    const residentCookie = cookies.get( 'resident' );
    const now = new Date();
    const iso = toIsoString( now );
    residentCookie.yourtour_active_session.end_value = iso;

    const attributesData = await combineAndFormatYtSessionsForDrupal( residentCookie );

    let favoriteRelationships = [];
    if ( type === 'virtual_tour' || type === 'video' || type === 'photo_gallery' ) {
      const cookie = cookies.get( 'media_favorites' );
      const mediaFavoriteTypes = cookie.favorites;
      // Media favorites all get sent to the same field, so even if we have to
      // send ALL of media favorites, not just the ones of this type.
      for (const type in mediaFavoriteTypes) {
        const favorites = mediaFavoriteTypes[type];
        if ( favorites ) {
          for ( const favorite of favorites ) {
            const favoriteRelationship = {
              type: `node--${type}`,
              id: favorite,
            };
            favoriteRelationships.push( favoriteRelationship );
          }
        }
      }
      if ( favoriteRelationships.length === 0 ) {
        favoriteRelationships = null;
      }
    } else {
      const cookie = cookies.get( 'favorites' );
      const favorites = cookie.favorites[type];
      if ( favorites ) {
        // eslint-disable-next-line no-restricted-syntax
        for ( const favorite of favorites ) {
          const favoriteRelationship = {
            type: `node--${type}`,
            id: favorite,
          };
          favoriteRelationships.push( favoriteRelationship );
        }
      } else {
        favoriteRelationships = null;
      }
    }

    let relationshipsData = {};
    switch ( type ) {
      case 'floor_plan':
        relationshipsData = {
          field_favorite_floor_plans: {
            data: favoriteRelationships,
          },
        };
        break;
      case 'place':
        relationshipsData = {
          field_favorite_amenities: {
            data: favoriteRelationships,
          },
        };
        break;
      case 'virtual_tour':
      case 'video':
      case 'photo_gallery':
        relationshipsData = {
          field_favorite_media: {
            data: favoriteRelationships,
          },
        };
        break;
      default:
    }

    await patchResidentInDrupal(
      residentCookieData.drupal_uuid,
      attributesData,
      null,
      relationshipsData,
    )
      .catch( ( error ) => {
        // eslint-disable-next-line no-console
        console.log( error.message );
      } );
  };

  const buttonClick = () => {
    getCookiesAndUpdateThem();

    // Change the appearance and state of the button.
    setFavorited( !favorited );

    // Send data to Drupal.
    if ( machineName === 'drupal' ) {
      patchResidentInCRM();
    }
  };

  return (
    <div className="favoriteBtn-positioner">
      <button
        className={classNames( 'favoriteBtn', {
          'favoriteBtn--active ': favorited,
          'favoriteBtn--shadow ': hasShadow,
        } )}
        onClick={buttonClick}
        type="button"
      >
        <i className="material-icons not-liked">favorite_border</i>
        <i className="material-icons is-liked">favorite</i>
        <span className="favoriteBtn-overlay" />
      </button>
    </div>
  );
};

Favorite.propTypes = {
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  hasShadow: PropTypes.bool,
};

Favorite.defaultProps = {
  hasShadow: false,
};

export default Favorite;
