import pathBounds from '../../utils/PathBounds';

export function calcPathCenterCoords(svgData) {
  let width = 0;
  let height = 0;
  let x = 0;
  let y = 0;

  if (svgData) {
    const [left, top, right, bottom] = pathBounds(svgData);
    width = right - left;
    height = bottom - top;
    x = left + width / 2;
    y = top + height / 2;
  }

  const centerCoords = {
    x,
    y,
  };

  return centerCoords;
}

