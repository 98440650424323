import React, { useContext } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import FadingContainer from '../FadingContainer/FadingContainer';
import './LoadingSpinner.scss';

const LoadingSpinner = ({ color }) => {
  const { config } = useContext(ConfigContext);

  // Default to primary color if no custom color was passed in.
  const primaryColor = config && config.primaryColor ? config.primaryColor : '#ffffff';
  const strokeColor = color ? color : primaryColor;
  return (
    <FadingContainer>
    <div className="loadingSpinnerContainer">
      {/* By Sam Herbert(@sherb), for everyone. More @http://goo.gl/7AJzbL */}
      <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        xmlns="http://www.w3.org/2000/svg"
        stroke={strokeColor}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle cx="5" cy="50" r="5">
              <animate
                attributeName="cy"
                begin="0s"
                dur="2.2s"
                values="50;5;50;50"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="cx"
                begin="0s"
                dur="2.2s"
                values="5;27;49;5"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="27" cy="5" r="5">
              <animate
                attributeName="cy"
                begin="0s"
                dur="2.2s"
                from="5"
                to="5"
                values="5;50;50;5"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="cx"
                begin="0s"
                dur="2.2s"
                from="27"
                to="27"
                values="27;49;5;27"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="49" cy="50" r="5">
              <animate
                attributeName="cy"
                begin="0s"
                dur="2.2s"
                values="50;50;5;50"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="cx"
                from="49"
                to="49"
                begin="0s"
                dur="2.2s"
                values="49;5;27;49"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
          </g>
        </g>
      </svg>
    </div>
    </FadingContainer>
  );
};

export default LoadingSpinner;
