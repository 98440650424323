export function getActiveGuideData( siteSeeGuideId, siteSeeGuideData ) {
  let data = null;
  if ( ( typeof siteSeeGuideId === 'number' || typeof siteSeeGuideId === 'boolean' ) && siteSeeGuideData ) {
    data = siteSeeGuideData.find( ( { index } ) => index === siteSeeGuideId );
  } else if ( siteSeeGuideData ) {
    data = siteSeeGuideData.find( ( { id } ) => id === siteSeeGuideId );
  }
  return data;
}

export function appendSiteSeeGuideRefs( ref, siteSeeGuideId, siteSeeGuideRefs ) {
  const newSiteSeeGuideRefs = siteSeeGuideRefs;
  const refAlreadyExists = getActiveGuideData( siteSeeGuideId, siteSeeGuideRefs );
  const newSiteSeeGuideRef = {
    ref,
  };
  if ( typeof siteSeeGuideId === 'number' || typeof siteSeeGuideId === 'boolean' ) {
    newSiteSeeGuideRef.index = siteSeeGuideId;
  } else {
    newSiteSeeGuideRef.id = siteSeeGuideId;
  }
  // Add ref to array.
  if ( !refAlreadyExists ) {
    newSiteSeeGuideRefs.push( newSiteSeeGuideRef );
  }
  // Replace ref in array.
  else {
    const index = siteSeeGuideRefs.indexOf( refAlreadyExists );
    newSiteSeeGuideRefs[index] = newSiteSeeGuideRef;
  }
  return newSiteSeeGuideRefs;
}
