import {
  useContext, useCallback, useState, useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import MapContext from '../MapContext';
import OverlayView from './OverlayView';
import { createOverlayElement } from './helpers';

const OverlayViewContainer = ( { map, overlayBounds, content } ) => {
  const [ overlayDOMElem, setOverlayDOMElem ] = useState( null );
  const [ overlayView, setOverlayView ] = useState( null );

  const { setCurrentOverlayWidth } = useContext( MapContext );

  // Make a div to hold the overlay
  const setOverlayDOMElemCallback = useCallback( () => {
    const el = createOverlayElement();
    setOverlayDOMElem( el );
  }, [] );

  useEffect( () => {
    setOverlayDOMElemCallback();
  }, [ setOverlayDOMElemCallback ] );

  // Create the overlay
  useEffect( () => {
    if ( overlayDOMElem ) {
      const view = new OverlayView( {
        content: overlayDOMElem,
        overlayBounds,
        setCurrentOverlayWidth,
      } );
      setOverlayView( view );
    }
  }, [
    overlayDOMElem, overlayBounds, setCurrentOverlayWidth,
  ] );

  // Tie the overlay to the Google Map
  useEffect( () => {
    if ( overlayView && map ) {
      overlayView.setMap( map );
    }
  }, [ overlayView, map ] );

  return overlayView ? ReactDOM.createPortal( content, overlayDOMElem ) : null;
};

OverlayViewContainer.propTypes = {
  map: PropTypes.object,
  svg: PropTypes.object,
  tileInfo: PropTypes.object,
};

export default OverlayViewContainer;
