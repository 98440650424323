import { getAuthClient } from '../auth';
import { userNormalizeData } from './UserNormalizeData';

export async function getNormalizedUserDataByUuid( uid ) {
  const url = `/jsonapi/user/user/${uid}`;

  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers( {
      'X-Consumer-ID': process.env.REACT_APP_DRUPAL_CONSUMER_UUID,
    } ),
  };

  // Fetch data
  const auth = getAuthClient();
  const response = await auth.fetchWithAuthentication( url, options, null );
  return userNormalizeData( response );
}

export function createSalespersonName( salesperson ) {
  let name = salesperson.displayName;
  const firstName = salesperson.fieldFirstName;
  const lastName = salesperson.fieldLastName;
  if ( firstName && lastName ) {
    name = `${firstName} ${lastName}`;
  }
  return name;
}
