import React from 'react';

import MapChunk from '../MapChunk';
import UnitShape from '../MapUnit/UnitShape';
import PlaceShape from '../MapPlace/MapPlaceShape/MapPlaceShape';

const MapLevelShapes = ( {
  units,
  places,
  levelOtherSvgData,
  unitBaseStyling,
  placeBaseStyling,
} ) => (
  <g>

    { levelOtherSvgData && (
      <MapChunk markupString={levelOtherSvgData} />
    )}

    { units?.length > 0 && units.map( ( unit ) => (
      <UnitShape
        key={unit?.info?.uuid}
        svgData={unit}
        unitBaseStyling={unitBaseStyling}
      />
    ) )}

    {/* I can't remember why this has to here exactly.
      It has something to do with svgs not rendering when they have no children. */}
    {' '}

    { places?.length > 0 && places.map( ( place ) => (
      <PlaceShape
        key={place?.pin?.uuid}
        svgData={place}
        placeBaseStyling={placeBaseStyling}
        unitBaseStyling={unitBaseStyling}
      />
    ) )}
  </g>
);

export default MapLevelShapes;
