import pathBounds from '../../../utils/PathBounds';

// Get longitude of Google maps tile.
function tile2lng( x, z ) {
  return ( x / 2 ** z ) * 360 - 180;
}

// Get latitude of Google maps tile.
function tile2lat( y, z ) {
  const n = Math.PI - ( 2 * Math.PI * y ) / 2 ** z;
  return ( 180 / Math.PI ) * Math.atan( 0.5 * ( Math.exp( n ) - Math.exp( -n ) ) );
}

export function getOverlayBounds( overlaysInfo ) {
  // Calcluate top right corner longitude and latitude of overlay bounds. Since
  // the tile2 functions give us the top left coords of a tile, we actually have
  // to ues the tile to the right of the top right tile, hence the + 1.
  const neTileX = parseInt( overlaysInfo.topRightX, 10 ) + 1;
  const neLng = tile2lng( neTileX, overlaysInfo.zoom );
  const neLat = tile2lat( overlaysInfo.topRightY, overlaysInfo.zoom );

  // Calcluate bottom left corner longitude and latitude of overlay bounds.
  // Since the tile2 functions give us the top left coords of a tile, we
  // actually have to ues the tile below the the bottom left tile, hence the - 1.
  const swTileY = parseInt( overlaysInfo.bottomLeftY, 10 ) + 1;
  const swTileX = parseInt( overlaysInfo.bottomLeftX, 10 );
  const swLng = tile2lng( swTileX, overlaysInfo.zoom );
  const swLat = tile2lat( swTileY, overlaysInfo.zoom );

  const bounds = new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng( swLat, swLng ),
    new window.google.maps.LatLng( neLat, neLng ),
  );

  return bounds;
}

export async function getLatLngFromAddress( address ) {
  return new Promise( ( resolve, reject ) => {
    ( async () => {
      const encodedAddress = encodeURIComponent( address );
      const requestURL = `https://maps.googleapis.com/maps/api/geocode/json?address=1${encodedAddress}&key=AIzaSyBXESsl6mv15V9mP8bEY7iQgYr14kEjUAk`;
      try {
        const response = await fetch( requestURL );
        const data = await response.json();
        const latLng = {
          lat: data.results[0].geometry.location.lat,
          lng: data.results[0].geometry.location.lng,
        };
        resolve( latLng );
      } catch ( err ) {
        reject( err );
      }
    } )();
  } );
}

export async function getCenter( campusCenter, address ) {
  let center = null;

  // First, use the campus center entered in the CMS if available.
  if ( campusCenter.lat && campusCenter.lng ) {
    center = campusCenter;
  }

  // Next, try getting the center from the address.
  else if ( address ) {
    try {
      center = await getLatLngFromAddress( address );
    } catch ( err ) {
      console.error( 'Failed to get lat lng from address', err );
    }
  }

  // Default to Winston-Salem, NC.
  else {
    center = {
      lat: 36.115,
      lng: -80.259,
    };
  }
  return center;
}

export function createOverlayElement() {
  const el = document.createElement( 'div' );
  el.style.position = 'absolute';
  el.style.display = 'inline-block';
  el.style.width = '9999px';
  return el;
}

export function getUnitDisplayMode(
  matchingUnitData,
  activeLevelOfCareUuid,
  mapActiveUnitData,
) {
  let displayMode = 'disabled';

  // Does this unit match any of the filter results?
  const isActive = mapActiveUnitData.hasOwnProperty( matchingUnitData.svgId );

  const unitLevelofCareUUid = matchingUnitData.levelOfCareUuid
    ? matchingUnitData.levelOfCareUuid
    : null;
  const matchesLevelOfCare = unitLevelofCareUUid === activeLevelOfCareUuid;

  switch ( true ) {
    case matchesLevelOfCare && isActive:
      displayMode = 'active';
      break;
    case matchesLevelOfCare:
      displayMode = 'inactive';
      break;
    case !matchesLevelOfCare:
      displayMode = 'disabled';
      break;
    default:
      break;
  }

  return displayMode;
}

export function calcPathCenterCoords( svgData ) {
  let width = 0;
  let height = 0;
  let x = 0;
  let y = 0;

  if ( svgData ) {
    const [
      left, top, right, bottom,
    ] = pathBounds( svgData );
    width = right - left;
    height = bottom - top;
    x = left + width / 2;
    y = top + height / 2;
  }

  const centerCoords = {
    x,
    y,
  };

  return centerCoords;
}

export function getMaxZoomBounds( maxZoomBoundsConfig ) {
  let maxZoomBounds = null;
  if (
    maxZoomBoundsConfig.north !== ''
    && maxZoomBoundsConfig.north !== null
    && maxZoomBoundsConfig.north !== undefined
    && maxZoomBoundsConfig.south !== ''
    && maxZoomBoundsConfig.south !== null
    && maxZoomBoundsConfig.south !== undefined
    && maxZoomBoundsConfig.east !== ''
    && maxZoomBoundsConfig.east !== null
    && maxZoomBoundsConfig.east !== undefined
    && maxZoomBoundsConfig.west !== ''
    && maxZoomBoundsConfig.west !== null
    && maxZoomBoundsConfig.west !== undefined
  ) {
    maxZoomBounds = maxZoomBoundsConfig;
  }
  return maxZoomBounds;
}

export function getCampusBounds( campusBoundsConfig ) {
  let campusBounds = null;
  if (
    campusBoundsConfig.north !== ''
    && campusBoundsConfig.north !== null
    && campusBoundsConfig.north !== undefined
    && campusBoundsConfig.south !== ''
    && campusBoundsConfig.south !== null
    && campusBoundsConfig.south !== undefined
    && campusBoundsConfig.east !== ''
    && campusBoundsConfig.east !== null
    && campusBoundsConfig.east !== undefined
    && campusBoundsConfig.west !== ''
    && campusBoundsConfig.west !== null
    && campusBoundsConfig.west !== undefined
  ) {
    campusBounds = campusBoundsConfig;
  }
  return campusBounds;
}

export default getOverlayBounds;
