/* eslint-disable import/prefer-default-export */
const JSONAPIDeserializer = require( 'jsonapi-serializer' ).Deserializer;

export const userNormalizeData = async ( responseData ) => {
  const normalizedData = {};

  // first get what we can out of the serialized data
  const options = {
    keyForAttribute: 'camelCase',
  };
  const serializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );

  return serializedData;
};
