import React, { useContext } from 'react';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import ModalTrigger from '../Modal/ModalTrigger';
import Favorite from '../Personalization/Favorite/Favorite';

const PlaceListItem = ( {
  coverImage,
  title,
  uuid,
  description,
} ) => {
  const { personalizationActive } = useContext( ConfigContext );

  const imgBg = {
    backgroundImage: `url(${coverImage})`,
  };

  const modalInfo = {
    title: {
      title,
    },
  };

  const innerMarkup = (
    <div>
      <div className="imgContainer">
        <div className="imgHolder" style={{ ...imgBg }} />
        <h2 className="gridItemTitle type-display-xl">{title}</h2>
      </div>
    </div>
  );

  return (
    <div className="gridItem">

      <ModalTrigger
        uuid={uuid}
        nodeType="place"
        triggerType="div"
        markup={innerMarkup}
        modalInfo={modalInfo}
      />

      { personalizationActive && (
        <Favorite
          type="place"
          uuid={uuid}
          hasShadow
        />
      )}

      { description && (
        <div className="gridItemInfo type-body-md">
          {description}
        </div>
      )}

    </div>
  );
};

export default PlaceListItem;
