import React, { useContext } from 'react';

import Cookies from 'universal-cookie';
import { joinNames } from '../../utils/common';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

const cookies = new Cookies();

const TimeoutMessage = () => {
  const {
    personalizationActive,
    salespersonName,
  } = useContext( ConfigContext );

  const resident = cookies.get( 'resident' );

  let residentNames = '';
  if ( personalizationActive ) {
    residentNames = joinNames( resident.first_name, resident.last_name, resident.person2_first_name, resident.person2_last_name, null, ' and ' );
  }

  return (
    <div className="floatingModal__copy">
      You’ve been idle for a while. To prevent problems,

      { personalizationActive
        && (
        <>
          &nbsp;personalization for
          {' '}
          <span>{residentNames}</span>
          {' '}
          will be saved and
        </>
        )}

      <span>
        {' '}
        {salespersonName}
      </span>
      {' '}
      will be logged out in:
    </div>
  );
};

export default TimeoutMessage;
