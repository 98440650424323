import React, { useContext } from 'react';

import RoadmapIcon from './RoadmapIcon';
import HybridIcon from './HybridIcon';

import MapContext from '../../../MapPage/MapContext';

import '../MapControls.scss';

const MapControlsToggle = () => {
  const {
    activeMapType,
    setActiveMapType,
  } = useContext( MapContext );

  let roadmapClass = '';
  let hybridClass = '';
  if ( activeMapType === 'roadmap' ) {
    // the roadmap is active, so show the hybrid map button
    hybridClass = 'mapControlToggle-toggleOption--show';
  }
  if ( activeMapType === 'hybrid' ) {
    // the hybrid map is active, so show the roadmap button
    roadmapClass = 'mapControlToggle-toggleOption--show';
  }

  return (
    <div className="mapControlToggle">
      <button
        type="button"
        className={`mapControlToggle-toggleOption ${roadmapClass}`}
        onClick={() => {
          setActiveMapType( 'roadmap' );
        }}
      >
        <RoadmapIcon />
      </button>

      <button
        type="button"
        className={`mapControlToggle-toggleOption ${hybridClass}`}
        onClick={() => {
          setActiveMapType( 'hybrid' );
        }}
      >
        <HybridIcon />
      </button>

    </div>
  );
};

export default MapControlsToggle;
