import React, { useEffect, useState } from 'react';

import DetailHeader from '../Detail/DetailHeader';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import VideoDetail from '../Video/VideoDetail';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';

import PlaceDetailNormalizeData from './PlaceNormalizeData';
import { getNormalizedApiDataByUuid } from '../../utils/ApiHelpers';
import FadingContainer from '../FadingContainer/FadingContainer';
import { assemblePlaceDetailNavBtnsData } from './PlaceHelpers';

import '../Detail/Detail.scss';

const PlaceDetail = ( {
  uuid,
  data,
  setNavBtns,
  showDescription,
  coverPhotoGalleryFallback,
  internalHeader,
  navClass,
} ) => {
  const [ placeData, setPlaceData ] = useState( null );
  const [ photoGalleryImages, setPhotoGalleryImages ] = useState( null );

  // const { config } = useContext( ConfigContext );

  // if we already have place data, set it
  useEffect( () => {
    if ( data ) {
      setPlaceData( data );
    }
  }, [ data ] );

  // get place data if we don't already have it
  useEffect( () => {
    if ( uuid ) {
      const fetchData = async () => {
        const include = [
          'field_cover_photo',
          'field_virtual_tour.field_three_sixties',
          // 'field_virtual_tour.field_three_sixties.field_button_text',
          'field_video',
          'field_photo_gallery.field_gallery_photos',
        ];
        const request = {
          entityType: 'node',
          contentType: 'place',
          uuid,
          params: {
            include: include.join( ',' ),
          },
        };
        const normalizedData = await getNormalizedApiDataByUuid(
          request,
          PlaceDetailNormalizeData,
        );
        setPlaceData( normalizedData );
      };
      fetchData();
    }
  }, [ uuid ] );

  // set navbtns if not internal header
  useEffect( () => {
    if ( placeData && !internalHeader ) {
      const navBtns = assemblePlaceDetailNavBtnsData( placeData, showDescription );
      setNavBtns( navBtns );
    }
  }, [ placeData, internalHeader, showDescription ] );

  const Header = () => {
    let header = null;
    if ( internalHeader && placeData && navClass ) {
      const headerText = {
        title: placeData.title,
        subtitle: null,
      };

      const navBtns = assemblePlaceDetailNavBtnsData( placeData, showDescription );

      header = (
        <DetailHeader
          navBtns={navBtns}
          text={headerText}
          navClass={navClass}
        />
      );
    }

    return header;
  };

  // just do all the photo gallery logic once
  useEffect( () => {
    if ( placeData ) {
      let images = null;
      if ( placeData.photoGalleryImages && placeData.photoGalleryImages.length > 0 ) {
        images = placeData.photoGalleryImages;
      } else if (
        coverPhotoGalleryFallback
        && placeData.coverImage
        && ( placeData.threeSixties.length === 0 )
        && !placeData.video
      ) {
        // if theres no vt, video, or gallery, and we want something to appear
        // make the cover photo a "gallery"
        images = [ {
          uuid: placeData.coverImage.id,
          caption: null,
          image: placeData.coverImage,
        } ];
      }
      setPhotoGalleryImages( images );
    }
  }, [ placeData ] );

  return (
    <FadingContainer>
      <div id="placeDetail">

        <Header />

        { showDescription && placeData?.description
          && (
          <div id="about-scroll" className="detailSection detailTable">
            <p className="detailTable-description">
              {placeData.description}
            </p>
          </div>
          )}

        {photoGalleryImages
          && (
          <PhotoGalleryDetail
            id="slideshow-scroll"
            uuid={null}
            images={photoGalleryImages}
            galleryClass="slideshow"
            imageClass="slideshowPhoto"
            className="detailSection detailSection--gallery"
          />
          )}

        { placeData?.video?.service && placeData?.video?.videoId
          && (
          <VideoDetail
            videoId={placeData.video.videoId}
            service={placeData.video.service}
            autoplay={false}
            className="detailSection"
          />
          )}

        { placeData?.threeSixties && placeData?.threeSixties.length > 0
          && (
          <VirtualTourDetail
            id="tour-scroll"
            uuid={null}
            threeSixtiesData={placeData.threeSixties}
            className="detailSection modalSection--sixteen9"
          />
          )}

      </div>
    </FadingContainer>
  );
};

export default PlaceDetail;
