export function assemblePlaceDetailNavBtnsData( placeData, showDescription ) {
  const navBtns = [];

  if ( placeData ) {
    const {
      description,
      threeSixties,
      video,
      photoGalleryImages,
    } = placeData;

    if ( description && showDescription ) {
      navBtns.push( {
        section: 'about-scroll',
        text: 'About',
      } );
    }

    if ( photoGalleryImages && photoGalleryImages.length > 0 ) {
      navBtns.push( {
        section: 'slideshow-scroll',
        text: 'Photos',
      } );
    }

    if ( video ) {
      navBtns.push( {
        section: 'video-scroll',
        text: 'Video',
      } );
    }

    if ( threeSixties && threeSixties.length > 0 ) {
      navBtns.push( {
        section: 'tour-scroll',
        text: 'Tour',
      } );
    }
  }
  return navBtns;
}

export default assemblePlaceDetailNavBtnsData;
