import { processUnserializedThreeSixties } from '../VirtualTour/VirtualTourDetailNormalizeData';

export default function placeDetailNormalizeData( responseData ) {


  let node = {};
  const drupalData = responseData.data;
  const drupalIncluded = responseData.included;

  // change included array into object with id as property name
  // so we can retrieve them by their id later
  const drupalIncludedById = {};
  if ( drupalIncluded ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  if ( drupalData ) {
    node = {
      title: drupalData.attributes.title,
      description: drupalData.attributes.field_description,
    };

    const virtualTourField = drupalData?.relationships?.field_virtual_tour?.data;
    if ( virtualTourField ) {
      const virtualTourId = virtualTourField.id;
      const virtualTourData = drupalIncludedById[virtualTourId];
      const threeSixtiesData = virtualTourData?.relationships?.field_three_sixties.data;
      const threeSixties = processUnserializedThreeSixties( threeSixtiesData, drupalIncludedById );
      node.threeSixties = threeSixties;
    }

    const videoField = drupalData.relationships.field_video.data;
    if ( videoField ) {
      const videoId = videoField.id;
      const videoData = drupalIncludedById[videoId];
      node.video = {
        videoId: videoData.attributes.field_video_id,
        service: videoData.attributes.field_video_service,
        autoplay: false,
      };
    }

    const photoGalleryField = drupalData.relationships.field_photo_gallery.data;
    if ( photoGalleryField ) {
      const photoGalleryId = photoGalleryField.id;
      const photoGalleryData = drupalIncludedById[photoGalleryId];
      const imagesData = photoGalleryData.relationships.field_gallery_photos.data;
      const images = [];
      if ( imagesData ) {
        imagesData.forEach( ( item, index ) => {
          const image = {
            id: item.id,
            image: {
              modal: imagesData[index].meta.imageDerivatives.links.modal.href,
            },
            caption: item.meta.alt,
          };
          images.push( image );
        } );
      }
      node.photoGalleryImages = images;
    }

    const coverImageField = drupalData.relationships.field_cover_photo.data;
    if ( !virtualTourField && !videoField && !photoGalleryField && coverImageField ) {
      const coverImageId = drupalData.relationships.field_cover_photo.data.id;
      const coverImageData = drupalIncludedById[coverImageId];
      node.photoGallery = {};
      node.photoGallery.images = [ {
        uuid: coverImageId,
        caption: null,
        image: {
          modal: coverImageData.meta.imageDerivatives.links.modal.href,
        },
      } ];
    }
  }

  return node;
}
