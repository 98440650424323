import React, { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';
import Cookies from 'universal-cookie';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import ModalLite from '../Modal/ModalLite';
import PersonalizationForm from './Form/FormComponents/PersonalizationForm';
import PersonalizationStatusMessage from './PersonalizationStatusMessage';
import { removeYtCookies } from './PersonalizationHelpers';
import './Personalization.scss';

const cookies = new Cookies();

const Personalization = ( { salespersonCrmId } ) => {
  const [ formModalSize, setFormModalSize ] = useState( 'small' );
  const [ formModalIsOpen, setFormModalIsOpen ] = useState( false );
  const [ currentStep, setCurrentStep ] = useState( 1 );
  const [ hasCRMResults, setHasCRMResults ] = useState( false );
  const [ cookieDataDump, setCookieDataDump ] = useState( null );
  const [ hasPersonalization, setHasPersonalization ] = useState( false );
  const [ showPersonalization, setShowPersonalization ] = useState( false );
  const [ isUpdatingProspect, setIsUpdatingProspect ] = useState( false );

  const {
    config,
    personalizationActive,
    setPersonalizationActive,
    crmConfig,
    settings,
    isLoggedIn,
  } = useContext( ConfigContext );

  const { machineName } = crmConfig;
  const { isKiosk } = settings;
  const { features } = config;

  // Do we show personalization?
  useEffect( () => {
    if ( !isKiosk
      && hasPersonalization
      && isLoggedIn ) {
      setShowPersonalization( true );
    } else {
      setShowPersonalization( false );
    }
  }, [
    isKiosk, hasPersonalization, isLoggedIn,
  ] );

  // Set personalizaiton access based on community config. community crm
  // creds and user crm creds.
  useEffect( () => {
    let rawHasPersonalization = false;
    // Does the community allow personalization?
    if ( features ) {
      rawHasPersonalization = features?.includes( 'personalization' );
      // Does the user have creds to make personaliazation work?
      // Sherpa requires them.
      // Enquire doesn't care.
      // Welcome Home doesn't require user creds, but will use them if they are available;
      if ( machineName === 'sherpa' && !salespersonCrmId ) {
        rawHasPersonalization = false;
      }
    }
    setHasPersonalization( rawHasPersonalization );
  }, [
    salespersonCrmId, features, machineName,
  ] );

  /**
   * On page load, checks for an active session for personalization.
   *
   * Sets the state to active when there's a session cookie present.
   */
  useEffect( () => {
    const residentSession = cookies.get( 'resident' );
    if ( residentSession ) {
      beginSession();
    }
  }, [] );

  // On load, listen for window or tab close/refresh and warn user.
  useEffect( () => {
    // If we're using a CRM, warn the user on tab close because CRM changes
    // aren't sent until "End Personalization" is clicked.
    if ( machineName !== 'drupal' ) {
      if ( personalizationActive ) {
        window.addEventListener( 'beforeunload', ( e ) => {
          e.preventDefault();
          e.returnValue = '';
        } );
      } else {
        window.removeEventListener( 'beforeunload' );
      }
    }
  }, [ machineName, personalizationActive ] );

  const windowWidth = window.width;
  useEffect( () => {
    // If we're below 1024 and on step 4 use the smaller modal.
    if ( currentStep === 4 ) {
      if ( window.innerWidth < 1024 ) {
        setFormModalSize( 'small' );
      } else {
        setFormModalSize( 'medium' );
      }
    }
  }, [ currentStep, windowWidth ] );

  /**
   * Go back a step the personalization process.
   *
   * If we're on step 2 going to 1, updated the modal size as well.
   */
  const goBackStep = () => {
    let goToPage;
    if ( currentStep === 2 ) {
      setFormModalSize( 'small' );
      goToPage = 1;
    }

    // If we're on the 'Not found' step, go back to step 1.
    if ( currentStep === 3 ) {
      setFormModalSize( 'small' );
      goToPage = 1;
    }

    if ( currentStep === 4 ) {
      // If we're on the 'Edit Info/Create new' step, go back to step 2. If
      // there were results found in step 2, go to page 2. If there were not, go
      // to page 1.
      goToPage = hasCRMResults ? 2 : 1;
      if ( !hasCRMResults ) {
        setFormModalSize( 'small' );
      }
    }

    setCurrentStep( goToPage );
  };

  /**
   * Cancel personalization and reset state variables.
   *
   * Removes any cookies that we have set.
   */
  const cancelPersonalization = () => {
    setFormModalSize( 'small' );
    setFormModalIsOpen( false );
    setPersonalizationActive( false );
    setHasCRMResults( false );
    removeYtCookies( cookies );
  };

  /**
   * Close modal by resetting state variables.
   */
  const closeModal = () => {
    setFormModalIsOpen( false );
    setFormModalSize( 'small' );
  };

  /**
   * Start/Stop the personalization process.
   *
   * Also present a modal to the user when cancelling the process to prevent
   * accidental interruption of a session.
   */
  const togglePersonalizationProcess = () => {
    // If personalization is currently active, warn the user
    if ( personalizationActive ) {
      setCurrentStep( 'endPersonalizationWarning' );
    } else {
      // Else, begin the personalization process.
      setCurrentStep( 1 );
    }
    setFormModalIsOpen( true );
    setFormModalSize( 'small' );
  };

  /**
   * Begins a personalization session by updating state variables.
   */
  const beginSession = () => {
    setPersonalizationActive( true );
  };

  return showPersonalization && (
    <div className="personalization">
      <ModalLite
        isOpen={formModalIsOpen}
        modalSize={formModalSize}
        handleModalHide={() => closeModal()}
        showBlur
      >
        <PersonalizationForm
          updateModalSize={setFormModalSize}
          beginSession={beginSession}
          closeModal={closeModal}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setHasCRMResults={setHasCRMResults}
          cookieDataDump={cookieDataDump}
          setCookieDataDump={setCookieDataDump}
          setPersonalizationActive={setPersonalizationActive}
          isUpdatingProspect={isUpdatingProspect}
          setIsUpdatingProspect={setIsUpdatingProspect}
        />

        <div className="personalization__inline-buttons personalization__form-navigation">

          {currentStep > 1 && currentStep <= 4 && (
            <>
              <button
                type="button"
                className="personalization__inline-button"
                onClick={() => goBackStep()}
              >
                Back to previous step
              </button>

              <div className="personalization__inline-button-separator">or</div>
            </>
          )}

          {currentStep <= 4 && (
            <button
              type="button"
              className="personalization__inline-button"
              onClick={() => cancelPersonalization()}
            >
              Continue without personalization
            </button>
          )}

          {currentStep === 'endPersonalizationWarning' && !isUpdatingProspect && (
            <button
              type="button"
              className="personalization__inline-button"
              onClick={() => closeModal()}
            >
              No
            </button>
          )}

          {currentStep === 'endPersonalization' && (
            <button
              type="button"
              className="personalization__inline-button"
              onClick={() => closeModal()}
            >
              Close
            </button>
          )}
        </div>
      </ModalLite>

      <PersonalizationStatusMessage personalizationActive={personalizationActive}/>

      <button
        className={classNames( 'personalization__toggle', {
          'personalization__toggle--active': personalizationActive,
        } )}
        onClick={() => togglePersonalizationProcess()}
        type="button"
      >
        {personalizationActive
          ? 'Stop Personalization'
          : 'Start Personalization'}
      </button>
    </div>
  );
};

export default Personalization;
