import React, { useContext } from 'react';

import MapContext from '../../MapPage/MapContext';
import ZoomZone from './ZoomZone';

import './ZoomZone.scss';

const ZoomZones = ( {
  zoomZonesActivationLevel,
  zoomZones,
  siteSeeGuideId,
  setSiteSeeGuideId,
  siteSeeGuideMode,
} ) => {
  const { activeZoom } = useContext( MapContext );
  if ( zoomZones && activeZoom >= zoomZonesActivationLevel ) {
    return (
      <g>
        { Object.keys( zoomZones ).map( ( key ) => {
          // Key is taxonomy term id from Drupal.
          const zoomZone = zoomZones[key];
          return (
            <ZoomZone
              key={key}
              zoomZone={zoomZone}
              siteSeeGuideMode={siteSeeGuideMode}
              siteSeeGuideId={siteSeeGuideId}
              setSiteSeeGuideId={setSiteSeeGuideId}
            />
          );
        } ) }
      </g>
    );
  }
  return null;
};

export default ZoomZones;
