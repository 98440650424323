import React from 'react';

const SubpaneButton = ({ btnText, subpaneDataId, setActiveSubpaneId, activeSubpaneId, setShowSubpaneNavBtns }) => {
  
  let activeClassName = activeSubpaneId === subpaneDataId ? 'subpaneButtons-nav__button subpaneButtons-nav__button--active' : 'subpaneButtons-nav__button';

  let windowWidth = window.innerWidth || 0;

  const handleOnClick = (subpaneDataId) => {
    if(windowWidth < 768) {
      setShowSubpaneNavBtns(false);
    }
    setActiveSubpaneId(subpaneDataId);
  }

  return (
    <button className={activeClassName} onClick={ () => handleOnClick(subpaneDataId) }>
      {btnText}
    </button>
  );
}

export default SubpaneButton;