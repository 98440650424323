import { processUnserializedThreeSixties } from '../VirtualTour/VirtualTourDetailNormalizeData';

export default function placeDetailNormalizeData( responseData, apiUrl ) {
  const cmsUrl = process.env.REACT_APP_YOUR_TOUR_CMS_URL;

  let node = {};
  const drupalData = responseData.data;
  const drupalIncluded = responseData.included;

  // change included array into object with id as property name
  // so we can retrieve them by their id later
  const drupalIncludedById = {};
  if ( drupalIncluded ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  if ( drupalData ) {
    node = {
      title: drupalData.attributes.title,
      description: drupalData.attributes.field_description,
    };

    const virtualTourField = drupalData.relationships.field_virtual_tour.data;
    if ( virtualTourField ) {
      const virtualTourId = virtualTourField.id;
      const virtualTourData = drupalIncludedById[virtualTourId];
      const threeSixtiesData = virtualTourData.relationships.field_three_sixties.data;
      const threeSixties = processUnserializedThreeSixties( threeSixtiesData, drupalIncludedById );
      node.threeSixties = threeSixties;
    }

    const videoField = drupalData.relationships.field_video.data;
    if ( videoField ) {
      const videoId = videoField.id;
      const videoData = drupalIncludedById[videoId];
      node.video = {
        title: videoData.attributes.title,
        service: videoData.attributes.field_video_service,
        serviceVideoId: videoData.attributes.field_video_id,
      };
    }

    const photoGalleryField = drupalData.relationships.field_photo_gallery.data;
    if ( photoGalleryField ) {
      const photoGalleryId = photoGalleryField.id;
      const photoGalleryData = drupalIncludedById[photoGalleryId];
      const imagesData = photoGalleryData.relationships.field_gallery_photos.data;
      const images = [];
      if ( imagesData ) {
        imagesData.forEach( ( item, index ) => {
          const image = {
            uuid: item.id,
            caption: item.meta.alt,
            image: {
              modal: imagesData[index].meta.imageDerivatives.links.modal.href,
            },
          };
          images.push( image );
        } );
      }
      node.photoGalleryImages = images;
    }

    // if theres no vt, video, or gallery, use the cover photo as a "gallery"
    // so there will be some content in the modal
    const coverImageField = drupalData.relationships.field_cover_photo.data;
    if ( !virtualTourField && !videoField && !photoGalleryField && coverImageField ) {
      const coverImageId = drupalData.relationships.field_cover_photo.data.id;
      const coverImageData = drupalIncludedById[coverImageId];
      node.photoGalleryImages = [ {
        uuid: coverImageId,
        caption: null,
        image: {
          modal: `${cmsUrl}/${coverImageData.attributes.uri.url}`,
        },
      } ];
    }
  }

  return node;
}
