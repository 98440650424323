import React, { useContext, useEffect } from 'react';

import classNames from 'classnames';
import ConfigContext from '../../../../utils/ConfigContext/ConfigContext';
import FormFieldError from '../FormComponents/FormFieldError';

const AccountResidentSelectionStep = ( {
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  crmData,
  residentSelected,
  setResidentSelected,
  setCurrentStep,
} ) => {
  const { crmConfig } = useContext( ConfigContext );

  const { machineName, humanName, loginUrl } = crmConfig;

  useEffect( () => {
    const { residentSelection } = values;
    if ( Number.isInteger( residentSelection ) ) {
      setResidentSelected( residentSelection );
    }
  }, [ values, setResidentSelected ] );

  // Form instructions for CRMs that don't allow editing.
  const NonEditableCrmInstructions = () => `Select one entry below and click the START PERSONALIZATION button. If edits are needed or the person you are looking for is not listed, click the 'Or Create New/Edit in ${humanName}' link. `;

  // Form instructions for Drupal, Welcome Home or other CRMs that allow changes.
  const EditableCrmInstructions = () => ' Select one entry below and click the CONTINUE button. If the person you are looking for is not listed, click the CREATE NEW link.';

  // Check if person has previously taken a YourTour. Only for Drupal right now.
  const SessionCheckInstructions = () => ' Starred entries have previously taken a YourTour.';

  // Select instructions based on CRM.
  const Instructions = () => {
    let instructions = null;
    switch ( machineName ) {
      case 'sherpa':
      case 'enquire':
        instructions = <NonEditableCrmInstructions />;
        break;
      case 'welcomeHome':
        instructions = <EditableCrmInstructions />;
        break;
      default:
        instructions = (
          <>
            <EditableCrmInstructions />
            <SessionCheckInstructions />
          </>
        );
    }
    return instructions;
  };

  // Conditionally render Date Column Header
  const DateColumnHeaderMarkup = () => {
    let markup = null;
    let phrase = null;
    switch ( machineName ) {
      case 'sherpa':
        phrase = 'Created';
        break;
      case 'enquire':
        phrase = 'Created';
        break;
      case 'welcomeHome':
      default:
        phrase = 'Changed';
    }

    if ( phrase ) {
      markup = (
        <div className="personalization__crm-table-label">
          { phrase }
        </div>
      );
    }

    return markup;
  };

  // Conditionally render Date Column
  const DateColumnMarkup = ( date ) => {
    if ( !date?.date || date.date === '' ) {
      return null;
    }

    const dateObject = new Date( date.date );
    const formattedDate = new Intl.DateTimeFormat( 'en-US' ).format( dateObject );

    if ( !formattedDate ) {
      return null;
    }

    let markup = null;
    switch ( machineName ) {
      case 'sherpa':
      case 'enquire':
      case 'welcomeHome':
      default:
        markup = (
          <div className="personalization__crm-option">
            { formattedDate }
          </div>
        );
    }
    return markup;
  };

  return (
    <div className="personalization__form-selection-step">

      <h3 className="personalization__form-header">
        Personalize
        {' '}
        <span>Your</span>
        Tour
      </h3>

      <div className="personalization__instructions">
        <Instructions />
      </div>

      <div className="form__items">
        <div
          className={classNames( 'form__item', {
            'form-error': errors.residentSelection && touched.residentSelection,
          } )}
        >
          <label htmlFor="residentSelection">Resident Selection - set by clicking on row below to make a selection</label>
          <input
            type="text"
            name="residentSelection"
            onChange={handleChange}
            onBlur={handleBlur}
            values={values?.residentSelection}
          />
        </div>
      </div>

      <div className="personalization__crm-table">
        <div className="personalization__crm-table-labels">
          <div className="fakeRadioSpacer" />
          <div className="personalization__crm-table-label">Person 1</div>
          <div className="personalization__crm-table-label personalization__crm-table-label--long">Email 1</div>
          <div className="personalization__crm-table-label">Person 2</div>
          <div className="personalization__crm-table-label personalization__crm-table-label--long">Email 2</div>
          <DateColumnHeaderMarkup />
        </div>

        <div className="personalization__crm-table-rows">
          {crmData.map( ( {
            firstName,
            lastName,
            email,
            person2FirstName,
            person2LastName,
            person2Email,
            changed,
            yourTourSessionsUuid,
          }, key ) => (
            <div
              className={classNames( 'personalization__crm-table-row', { selected: residentSelected === key } )}
              key={key}
              onClick={() => {
                setFieldValue( 'residentSelection', key );
                setResidentSelected( key );
              }}
            >
              <div className="radioBox">
                <div className="fakeRadio" />
              </div>
              <div className="personalization__crm-option">
                {`${firstName} ${lastName}`}
                { yourTourSessionsUuid && yourTourSessionsUuid.length > 0
                  && <>*</>}
              </div>
              <div className="personalization__crm-option personalization__crm-option--long personalization__crm-option--wrap">{email}</div>
              <div className="personalization__crm-option">{`${person2FirstName ?? ''} ${person2LastName ?? ''}`}</div>
              <div className="personalization__crm-option personalization__crm-option--long personalization__crm-option--wrap">{person2Email}</div>
              <DateColumnMarkup date={changed || null} />
            </div>
          ) )}
        </div>
      </div>

      <p className="personalization__crm-summary">
        {crmData.length > 1
          && (
          <>
            There are
            {' '}
            <span>
              {crmData.length}
              {' '}
              entries
            </span>
            {' '}
            matching your search.
          </>
          )}

        {crmData.length === 1
          && (
          <>
            There is
            {' '}
            <span>
              {crmData.length}
              {' '}
              entry
            </span>
            {' '}
            matching your search.
          </>
          )}
      </p>

      { errors.residentSelection && touched.residentSelection && (
        <FormFieldError errorMsg={errors.residentSelection} />
      )}

      <div className="personalization__inline-buttons">

        { machineName === 'drupal'
          ? (
            <>
              <button
                disabled={isSubmitting}
                type="submit"
                className="personalization__cta personalization__cta--secondary"
                onClick={() => {
                  setFieldValue( 'action', 'continue' );
                }}
              >
                Continue
              </button>

              <button
                disabled={isSubmitting}
                type="submit"
                className="personalization__cta-minor"
                onClick={() => {
                  setFieldValue( 'action', 'create new' );
                  setFieldValue( 'drupal_uuid', '' );
                  setFieldValue( 'first_name', '' );
                  setFieldValue( 'last_name', '' );
                  setFieldValue( 'email', '' );
                  setFieldValue( 'my_favorites_page', '' );
                  setFieldValue( 'person2_drupal_uuid', '' );
                  setFieldValue( 'person2_first_name', '' );
                  setFieldValue( 'person2_last_name', '' );
                  setFieldValue( 'person2_email', '' );
                  setFieldValue( 'resident', '' );
                  setResidentSelected( '' );

                  setFieldValue( 'action', 'create new' );
                }}
              >
                Or Create New
              </button>
            </>
          )
          : (
            <>
              <button
                disabled={isSubmitting}
                className="personalization__cta personalization__cta--secondary"
                type="submit"
              >
                Start Personalization
              </button>

              <a
                href={loginUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="personalization__cta-minor"
                onClick={() => {
                  setCurrentStep( 1 );
                }}
              >
                Or Create New/Edit in
                {' '}
                {humanName}
              </a>
            </>
          )}

      </div>
    </div>
  );
};

export default AccountResidentSelectionStep;
