class OverlayView extends window.google.maps.OverlayView {
  content = null;

  bounds = null;

  constructor( props ) {
    super( props );
    this.content = props.content;
    this.bounds = props.overlayBounds;
    this.setCurrentOverlayWidth = props.setCurrentOverlayWidth;
  }

  /** Called when the popup is added to the map. */
  onAdd = () => {
    this.getPanes().floatPane.appendChild( this.content );
  };

  /** Called when the popup is removed from the map. */
  onRemove = () => {
    if ( this.content.parentElement ) {
      this.content.parentElement.removeChild( this.content );
    }
  };

  /** Called each frame when the popup needs to draw itself. */
  draw = () => {
    if ( this.bounds ) {
      // We use the south-west and north-east
      // coordinates of the overlay to peg it to the correct position and size.
      // To do this, we need to retrieve the projection from the overlay.
      const overlayProjection = this.getProjection();

      if ( overlayProjection ) {
        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getSouthWest(),
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getNorthEast(),
        );

        const left = `${sw.x}px`;
        const top = `${ne.y}px`;
        const width = `${ne.x - sw.x}px`;
        const height = `${sw.y - ne.y}px`;

        // Resize the image's div to fit the indicated dimensions.
        this.content.style.left = left;
        this.content.style.top = top;
        this.content.style.width = width;
        this.content.style.height = height;

        const overlayWidth = ne.x - sw.x;
        this.setCurrentOverlayWidth( overlayWidth );
      }
    }
  };
}

export default OverlayView;
