import React from 'react';
import PropTypes from 'prop-types';

import LevelsOfCareListItemButton from './LevelsOfCareListItemButton';

const LevelsOfCareButtonList = ({ levelOfCareNames, goToNextPanel }) => {

  // We use All Levels of Care instead of mapFilterData because mapFilterData
  // will eventually filter out some levels of care based on filters. This
  // causes the Level of Care menu filter to get shorter when it's off-page and
  // that looks weird.
  return Object.keys( levelOfCareNames ).map(locKey => (
    <LevelsOfCareListItemButton
      key={locKey}
      name={levelOfCareNames[locKey].label}
      uuid={locKey}
      goToNextPanel={goToNextPanel}
    />
  ));
};

LevelsOfCareButtonList.propTypes = {
  levelOfCareNames: PropTypes.object,
  goToNextPanel: PropTypes.func.isRequired,
};

export default LevelsOfCareButtonList;