import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';
import { getActiveGuideData } from './SiteSeeGuideHelpers';

import ModalLite from '../../Modal/ModalLite';
import ModalDialog from '../../Modal/ModalDialog';
import ModalContentWrapper from '../../Modal/ModalContentWrapper';

import './SiteSeeGuides.scss';

import SiteSeeControlsGuide from './SiteSeeControlsGuide';
import SiteSeeZoomsGuide from './SiteSeeZoomsGuide';

// There are 2 "modes" of the guides:
// - tour, a full guided tour with steps
// - tip, a single help guide attached to an element.
const SiteSeeGuides = ( {
  siteSeeGuideId,
  setSiteSeeGuideId,
  showSiteSeeGuide,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
  setSiteSeeGuideMode,
  setActiveZoom,
  mapZoomToEnableLevels,
  siteSeeModalDims,
} ) => {
  const [ activeModalContent, setActiveModalContent ] = useState( {} );
  const [ modalStyle, setModalStyle ] = useState( {} );
  const [ arrowStyle, setArrowStyle ] = useState( {} );

  const [ activeGuideData, setActiveGuideData ] = useState( {} );
  const [ styling, setStyling ] = useState( {} );
  const [ index, setIndex ] = useState( 0 );
  const [ count, setCount ] = useState( 0 );
  const [ title, setTitle ] = useState( null );
  const [ btnText, setBtnText ] = useState( null );
  const [ content, setContent ] = useState( null );

  const [ trigger, setTrigger ] = useState( null );
  const [ onHoldSiteSeeGuideId, setOnHoldSiteSeeGuideId ] = useState( null );

  const { config, windowWidth, windowHeight } = useContext( ConfigContext );
  const { siteSeeIntro } = config;

  const rawSiteSeeGuideData = [
    {
      id: 'navigation',
      index: 1,
      title: 'Map Navigation',
      text: 'Click, hold and drag anywhere on a map to move the map around the screen.',
    },
    {
      id: 'controls',
      index: 2,
      title: 'Map Controls',
      component: <SiteSeeControlsGuide />,
      styling: {
        arrow: 'down',
        align: [ 'bottom',
          'right' ],
      },
    },
    {
      id: 'zooms',
      index: 3,
      title: 'Zoom In on Map Areas',
      component: <SiteSeeZoomsGuide />,
    },
    {
      id: 'filters',
      index: 4,
      title: 'Filters',
      text: 'Click the arrow to access various map filters to help you navigate our residences.',
      styling: {
        arrow: 'up',
        align: [ 'top',
          'left' ],
      },
      tourFunc: () => {
        setActiveZoom( mapZoomToEnableLevels );
      },
    },
    {
      id: 'levels',
      index: 5,
      title: 'Map Levels',
      text: 'Click the arrow to access the different levels of our community.',
      styling: {
        arrow: 'down',
        align: [ 'bottom',
          'left' ],
      },
    },
    {
      id: 'amenities',
      index: 6,
      title: 'Amenities',
      text: 'Click any label on the map with a + symbol to access photo galleries, videos, etc. of our amenities.',
      target: null,
    },
    {
      id: 'residences',
      index: 7,
      title: 'Residences',
      text: 'Click any residence represented by shapes of color to view floor plans, room dimensions, and more.',
      target: null,
    },
  ];

  const intro = {
    key: 'intro',
    title: 'Welcome to Our Virtual Visit',
    markup: siteSeeIntro || '',
    buttons: [ {
      key: 'startTour',
      text: 'Start Tour',
      func: () => {
        setTrigger( 'startTour' );
        setShowSiteSeeGuide( false );
        // Remaing funcs will be called in onExitFinishFunc after transition.
      },
    },
    {
      key: 'endTour',
      text: 'Skip Guided Tour',
      class: 'modal__minorBtn',
      func: () => {
        setTrigger( 'endTour' );
        setShowSiteSeeGuide( false );
        // Remaing funcs will be called in onExitFinishFunc after transition.
      },
    } ],
  };

  const warning = {
    key: 'warning',
    title: 'This action would end the Guided Tour. Are you sure you want to proceed?',
    buttons: [ {
      key: 'endTour',
      text: 'Yes',
      func: () => {
        setTrigger( 'endTour' );
        setShowSiteSeeGuide( false );
        // Remaing funcs will be called in onExitFinishFunc after transition.
      },
    },
    {
      key: 'cancelEndTour',
      text: 'No',
      class: 'modal__minorBtn',
      func: () => {
        setTrigger( 'cancelEndTour' );
        setShowSiteSeeGuide( false );
        // Remaing funcs will be called in onExitFinishFunc after transition.
      },
    } ],
  };

  // On load:
  // - Set the guide mode to 'tour'.
  // - Count the number of guide tips.
  useEffect( () => {
    setSiteSeeGuideMode( 'tour' );
    setCount( rawSiteSeeGuideData.length );
  }, [] );

  // On guide mode change to tour, intro is the active modal content.
  useEffect( () => {
    if ( siteSeeGuideMode === 'tour' ) {
      setShowSiteSeeGuide( true );
      setActiveModalContent( intro );
    } else {
      setSiteSeeGuideId( null );
    }
  }, [ siteSeeGuideMode ] );

  // If modal has non-tip content, remove modal options that generate positioning.
  useEffect(
    () => {
      if ( activeModalContent || Object.keys( activeModalContent ).length > 0 ) {
        setStyling( {} );
      }
    },
    [ activeModalContent ],
  );

  // Get position for anchored modals.
  useEffect(
    () => {
      if ( styling?.align
        && siteSeeModalDims?.x
        && siteSeeModalDims?.y
      ) {
        // I tried doing these base styles by adding a class and it broke the
        // transition. I give up and am just going to do it here.
        const rawModalStyle = {
          width: '500px',
          margin: 0,
          left: 'revert',
          bottom: 'revert',
          transform: 'none',
        };
        const rawArrowStyle = {};
        const padding = 45;
        const arrowBorder = '30px solid white';
        const arrowWidth = 30;
        const arrowHeight = 45;

        styling.align.forEach( ( element ) => {
          switch ( element ) {
            case 'top':
              rawModalStyle.top = siteSeeModalDims.bottom + padding;
              rawArrowStyle.borderBottom = arrowBorder;
              rawArrowStyle.top = -arrowHeight;
              break;
            case 'bottom':
              rawModalStyle.bottom = windowHeight - siteSeeModalDims.top + padding;
              rawArrowStyle.borderTop = arrowBorder;
              rawArrowStyle.bottom = -arrowHeight;
              break;
            case 'left':
              rawModalStyle.left = siteSeeModalDims.left + ( siteSeeModalDims.width / 2 ) - padding;
              rawArrowStyle.left = padding - ( arrowWidth / 2 );
              break;
            case 'right':
              rawModalStyle.right = windowWidth
                - siteSeeModalDims.right + ( siteSeeModalDims.width / 2 ) - padding;
              rawArrowStyle.right = padding - ( arrowWidth / 2 );
              break;
            default:
              break;
          }
        } );
        setArrowStyle( rawArrowStyle );
        setModalStyle( rawModalStyle );
      } else {
        setModalStyle( {} );
        setArrowStyle( {} );
      }
    },
    [
      siteSeeModalDims,
      siteSeeGuideId,
      styling,
    ],
  );

  // Get active guide tip data.
  useEffect( () => {
    const rawActiveGuideData = getActiveGuideData( siteSeeGuideId, rawSiteSeeGuideData ) || {};
    setActiveGuideData( rawActiveGuideData );
  }, [ siteSeeGuideId ] );

  // Set uses of active guide data.
  useEffect( () => {
    if ( activeGuideData ) {
      setIndex( activeGuideData.index );
      const rawTitle = siteSeeGuideMode === 'tour' ? `Tip ${siteSeeGuideId} / ${count} - ${activeGuideData.title}` : `Tip - ${activeGuideData.title}`;
      setTitle( rawTitle );
      const rawBtnText = index + 1 === count ? 'Close' : 'Next Tip';
      setBtnText( rawBtnText );
      const rawContent = activeGuideData.component
        || activeGuideData.markup || activeGuideData.text;
      setContent( rawContent );
      setStyling( activeGuideData.styling || {} );
    }
  }, [
    siteSeeGuideId,
    siteSeeGuideMode,
    activeGuideData,
    count,
  ] );

  // If the step has a function, run it.
  useEffect( () => {
    if ( activeGuideData?.tourFunc && siteSeeGuideMode === 'tour' ) {
      activeGuideData.tourFunc();
    }
  }, [
    mapZoomToEnableLevels,
    siteSeeGuideMode,
    activeGuideData,
    siteSeeGuideId,
  ] );

  // These things happen immediately on close btn click and trigger the transition.
  // onExitFinishFunc is called after the transition is done.
  const closeBtnFunc = () => {
    // If a trigger has already been set, its not a close button.
    if ( trigger ) {
      return;
    }

    // Determine what the close btn is closing.
    let closeBtn = null;
    if ( Object.keys( activeModalContent ).length > 0 ) {
      closeBtn = `${activeModalContent.key}CloseBtn`;
    } else if ( siteSeeGuideMode ) {
      const prefix = siteSeeGuideMode === 'tour' ? 'tour' : 'nonTour';
      closeBtn = `${prefix}TipCloseBtn`;
    }

    setTrigger( closeBtn );
  };

  // These things happen after the transition is complete.
  const onExitFinishFunc = () => {
    switch ( trigger ) {
      case 'introCloseBtn':
        setSiteSeeGuideMode( 'tip' );
        break;
      case 'warningCloseBtn':
        setActiveModalContent( {} );
        setSiteSeeGuideId( onHoldSiteSeeGuideId );
        setOnHoldSiteSeeGuideId( null );
        setShowSiteSeeGuide( true );
        break;
      case 'tourTipCloseBtn':
        setOnHoldSiteSeeGuideId( siteSeeGuideId );
        setSiteSeeGuideId( null );
        setActiveModalContent( warning );
        setShowSiteSeeGuide( true );
        break;
      case 'nonTourTipCloseBtn':
        setSiteSeeGuideId( null );
        break;
      case 'startTour':
        setActiveModalContent( {} );
        setSiteSeeGuideId( 1 );
        setShowSiteSeeGuide( true );
        break;
      case 'endTour':
        setSiteSeeGuideMode( 'tip' );
        setSiteSeeGuideId( null );
        setActiveModalContent( {} );
        break;
      case 'cancelEndTour':
        setActiveModalContent( {} );
        setSiteSeeGuideId( onHoldSiteSeeGuideId );
        setOnHoldSiteSeeGuideId( null );
        setShowSiteSeeGuide( true );
        break;
      case 'nextTip':
        setSiteSeeGuideId( siteSeeGuideId + 1 );
        setShowSiteSeeGuide( true );
        break;
      default:
    }
    setTrigger( null );
  };

  const onNextTipClick = () => {
    const rawTrigger = siteSeeGuideId === count ? 'endTour' : 'nextTip';
    setTrigger( rawTrigger );
    setShowSiteSeeGuide( false );
  };

  const hasActiveModalContent = Object.keys( activeModalContent ).length > 0;

  const handleModalHide = () => {
    setShowSiteSeeGuide( false );
    closeBtnFunc();
  };

  return (
    <ModalLite
      isOpen={showSiteSeeGuide}
      modalClassName={classNames( 'modal--dialog', {
        'modal--positioned': siteSeeGuideId,
        'modal--floating': Object.keys( activeModalContent ).length > 0,
        'modal--tip': siteSeeGuideId,
      } )}
      modalStyle={modalStyle}
      showBlur={hasActiveModalContent}
      onExitFinish={onExitFinishFunc}
      handleModalHide={handleModalHide}
    >

      { siteSeeGuideMode === 'tour' && hasActiveModalContent && (
        <ModalDialog
          title={activeModalContent.title}
          markup={activeModalContent.markup}
          buttons={activeModalContent.buttons}
        />
      )}

      { siteSeeGuideId && (
      <ModalContentWrapper>
        <>
          {/* We make an actual element becasue we can't position
          a pseudo element with JS */}
          { Object.keys( arrowStyle ).length > 0 && (
          <div
            className="siteSeeGuide__modalArrow"
            style={arrowStyle}
          />
          )}

          <div className="siteSeeGuide">

            { content && (
            <div className="siteSeeGuide__text modalDescription">
              { content }
            </div>
            )}

            <div className="siteSeeGuide__titleWrapper">
              <div className={`siteSeeGuide__title siteSeeGuide__title--${siteSeeGuideMode}`}>
                { title }
              </div>

              { siteSeeGuideMode === 'tour' && (
              <button
                className="siteSeeGuide__btn"
                type="button"
                onClick={onNextTipClick}
              >
                { btnText }
              </button>
              )}
            </div>

            <div className="siteSeeGuide__closeBtn modal__inlineBtns">
              <button
                type="button"
                className="modal__minorBtn"
                onClick={handleModalHide}
              >
                Close
              </button>
            </div>

          </div>

        </>
      </ModalContentWrapper>
      )}

    </ModalLite>
  );
};

export default SiteSeeGuides;
