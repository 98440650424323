import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MapContext from '../../MapContext';

const LevelsOfCareListItemButton = ({ name, uuid, goToNextPanel }) => {
  const { setActiveLevelOfCareUuid } = useContext(MapContext);

  const handleClick = (event) => {
    // Tell map about new active LOC.
    // @TODO - Only tell map if we're on that page
    setActiveLevelOfCareUuid(uuid);

    // @TODO - Tell Floor Plans page about new active if we're on that page.

    // Open next menu panel
    goToNextPanel(event);
  };

  return (
    <button
      className="mapSelector-option mapFilter-option type-cta-alt-md mapFilter-option--more"
      onClick={handleClick}
    >
      <span className="mapFilter-optionName">{name}</span>
    </button>
  );
};

LevelsOfCareListItemButton.propTypes = {
  name: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  goToNextPanel: PropTypes.func.isRequired,
};

export default LevelsOfCareListItemButton;
