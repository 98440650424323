const labels = '#5b727e';
const GoogleMapStyle = [
  {
    featureType: 'administrative.land_parcel',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'landscape',
    stylers: [ {
      color: '#f2f3ee',
    } ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'labels',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.attraction',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.business',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.government',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.medical',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.park',
    stylers: [ {
      visibility: 'on',
    } ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [ {
      color: '#e1e2d9',
    } ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.place_of_worship',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.school',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.sports_complex',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [ {
      color: '#979b9b',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [ {
      lightness: 15,
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [ {
      color: '#D6D6D6',
    } ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [ {
      color: '#D6D6D6',
    } ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [ {
      color: '#dadce0',
    },
    {
      visibility: 'on',
    } ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [ {
      color: '#D6D6D6',
    } ],
  },
  {
    featureType: 'transit',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'water',
    stylers: [ {
      color: '#b1bebf',
    } ],
  },
];

export default GoogleMapStyle;
