import { getAuthClient } from './auth';

const auth = getAuthClient();
/**
 * Helper function to fetch data from the API
 *
 * Fetches data as logged in Drupal user
 *
 * @param {Object} request | Info about URL to fetch data from:
 *  {
 *    entityType: {string}, // ex: 'node', 'taxonomy_term'
 *    contentType: {string}, // ex: 'photo_gallery'
 *    filter: {string}, // ex: '[field_display_on_gallery_page]=1'
 *    sort: {string}, // ex: 'field_display_order,title'
 *    include: {string}, // ex: 'field_cover_photo'
 *  }
 *
 * @returns {Promise} | Resolves to API data object
 */
export async function getAuthApiData( request ) {
  return new Promise( async ( resolve, reject ) => {
    let {
      entityType,
      contentType,
      sort,
      include,
      config,
      filter,
    } = request;
    const baseUrl = `/${entityType}/${contentType}`;
    const community = config.communityNid
      ? `filter[og_audience.nid]=${config.communityNid}`
      : '';
    // drupal includes all nodes the user has accesss to, including unpublished ones
    const published = 'filter[status]=1';
    sort = sort ? `sort=${sort}` : '';
    include = include ? `include=${include}` : '';
    const page = 'page[limit]=500';
    const queries = [
      community,
      filter,
      published,
      sort,
      include,
      page,
    ].filter(
      ( q ) => q !== '',
    );

    let url = baseUrl;

    if ( queries.length > 0 ) {
      const queryString = queries.join( '&' );
      url = `?${queryString}`;
    }

    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: new Headers( {
        'X-Consumer-ID': process.env.REACT_APP_DRUPAL_CONSUMER_UUID,
      } ),
    };

    // Fetch data
    try {
      const response = await auth.fetchWithAuthentication( url, options, null );
      combinedData = await response.json();
      let hasMore = combinedData?.links?.next;
      let moreLink = combinedData?.links?.next?.href;

      while ( hasMore ) {
        const responseMore = await auth.fetchWithAuthentication( moreLink, options, null );
        const dataMore = await responseMore.json();
        combinedData.data = [ ...combinedData.data, ...dataMore.data ];
        combinedData.included = [ ...combinedData.included,
          ...dataMore.included ];
        hasMore = dataMore?.links?.next;
        moreLink = dataMore?.links?.next?.href;
      }

      resolve( combinedData );
    } catch ( err ) {
      console.error( `Error fetching data from ${url}`, err );
      // alert( 'An error has occured while trying to fetch data. Please reload this page and try again.' );
      reject( err );
    }
  } );
}

export async function getApiData( request, communityNid ) {
  if ( !communityNid ) {
    return;
  }
  const {
    entityType,
    contentType,
    params,
    filter,
  } = request;
  const paramsString = new URLSearchParams( params ).toString();
  const filterArray = [ `&filter[og_audience.nid]=${communityNid}` ];
  // eslint-disable-next-line no-restricted-syntax
  if ( filter && Object.keys( filter ).length ) {
    Object.entries( filter ).forEach( ( [ key, value ] ) => {
      filterArray.push( `filter[${key}]=${value}` );
    } );
  }
  const filterString = filterArray.join( '&' );
  const url = `/jsonapi/${entityType}/${contentType}?${paramsString}${filterString}`;
  const data = await auth.fetchWithAuthentication( url, 'GET', null );
  return data;
}

export async function getNormalizedApiData( request, communityNid, normalizeFunction ) {
  const data = await getApiData( request, communityNid );
  if(!data) return;
  const normalizedData = normalizeFunction( data );
  return normalizedData;
}

/**
 * @param {Object} request | Info about URL to fetch data from:
 * {
 *    entityType: {string}, // ex: 'node', 'taxonomy_term'
 *    contentType: {string}, // ex: 'photo_gallery', 'level_of_care'
 *    params: {object}, // ex: {
 *     include: {string}, // ex: 'field_cover_photo, field_gallery_photos'
 *    }
 *  }
 * @param {function} normalizeFunction | Function name that normalizes the data
 * @returns {Promise} | Resolves to normalized API data object
 */
export async function getNormalizedApiDataByUuid( request, normalizeFunction ) {
  const {
    entityType,
    contentType,
    uuid,
    params,
    fields,
  } = request;
  const paramsString = new URLSearchParams( params ).toString();
  const fieldsArray = [];
  // eslint-disable-next-line no-restricted-syntax
  if ( fields && Object.keys( fields ).length ) {
    Object.entries( fields ).forEach( ( [ key, value ] ) => {
      fieldsArray.push( `fields[${key}]=${value}` );
    } );
  }
  const fieldsRawString = fieldsArray.join( '&' );
  const fieldsString = fieldsRawString ? `&${fieldsRawString}` : '';
  const url = `/jsonapi/${entityType}/${contentType}/${uuid}/?${paramsString}${fieldsString}`;
  const data = await auth.fetchWithAuthentication( url, 'GET', null );
  const normalizedData = normalizeFunction( data );
  return normalizedData;
}

/**
 * Helper function to fetch data from the API
 *
 * @param {Object} request | Info about URL to fetch data from:
 *  {
 *    entityType: {string}, // ex: 'node', 'taxonomy_term'
 *    include: {string}, // ex: 'field_cover_photo'
 *  }
 *
 */
export async function getNormalizedApiDataByDrupalId( request, normalizeFunction ) {
  const {
    entityType,
    contentType,
    drupalId,
    include,
    fields,
  } = request;
  const fieldsArray = [];
  const baseUrl = `/jsonapi/${entityType}/${contentType}/`;
  const filterString = `filter[nid]=${drupalId}`;
  const includeString = include ? `&include=${include}` : '';
  if ( fields && Object.keys( fields ).length ) {
    Object.entries( fields ).forEach( ( [ key, value ] ) => {
      fieldsArray.push( `fields[${key}]=${value}` );
    } );
  }
  const fieldsRawString = fieldsArray.join( '&' );
  const fieldsString = fieldsRawString ? `&${fieldsRawString}` : '';
  const url = `${baseUrl}?${filterString}${includeString}${fieldsString}`;
  const data = await auth.fetchWithAuthentication( url, 'GET', null );
  const normalizedData = normalizeFunction( data );
  return normalizedData;
}