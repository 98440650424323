import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import deepmerge from 'deepmerge';

import MapContext from '../../MapContext';

const DetailsButtons = ( {
  detailItemLabel, detailItemUuid, goToPrevPanel, detailItemMeta, isActive,
} ) => {
  const mapContextStuff = useContext( MapContext );
  const {
    activeCategoryFilter,
    activeFilterSelections,
    setActiveFilterSelections,
  } = mapContextStuff;

  // When a selection is made on the detail panel, we will add that selection
  // to the list of all selections.
  const handleClick = ( event ) => {
    const detailSelection = {};
    detailSelection[activeCategoryFilter] = { [detailItemUuid]: detailItemLabel };

    // Merging the selection that the user just made with what we already have
    // stored in state (context).
    setActiveFilterSelections( deepmerge( activeFilterSelections, detailSelection ) );
    goToPrevPanel();
  };

  const displayMode = isActive ? 'active' : 'disabled';

  const getOptionStyles = () => {
    if ( activeCategoryFilter && activeCategoryFilter === 'floor_plan' ) {
      const floorPlanKeyColor = detailItemMeta.key_color ?? 'gray';
      return {
       // backgroundColor: isActive ? floorPlanKeyColor : 'transparent',
        backgroundColor: floorPlanKeyColor,
        borderColor: floorPlanKeyColor,
      };
    }

    return null;
  };

  return (
    <button
      className="mapSelector-option mapFilter-option mapFilter-option--floorPlan type-cta-alt-md"
      onClick={handleClick}
      data-id={detailItemUuid}
      type='button'
    >
      <span
        className={`mapFilter-option--floorPlanColor mapFilter-option--${displayMode}`}
        style={getOptionStyles()}
      >
        {isActive && (
          <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 8.5L7.75251 15.5L19.0144 2.18506" stroke="#ffffff" strokeWidth="2" strokeLinecap="square" />
          </svg>
        )}
      </span>
      {detailItemLabel}
    </button>
  );
};

DetailsButtons.propTypes = {
  detailItemLabel: PropTypes.string.isRequired,
  detailItemUuid: PropTypes.string.isRequired,
  goToPrevPanel: PropTypes.func.isRequired,
  detailItemMeta: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default DetailsButtons;
