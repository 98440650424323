import React from 'react';

const MapControlsZoomMoreIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
  >
    <g>
      <path
        d="M19,37.86C8.6,37.86,0.14,29.4,0.14,19S8.6,0.14,19,0.14S37.86,8.6,37.86,19S29.4,37.86,19,37.86z M19,3.14
        C10.25,3.14,3.14,10.25,3.14,19S10.25,34.86,19,34.86S34.86,27.75,34.86,19S27.75,3.14,19,3.14z"
      />
    </g>
    <path
      d="M26.06,17.5H20.5v-5.56c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.5v5.56h-5.56c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5
      h5.56v5.56c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5V20.5h5.56c0.83,0,1.5-0.67,1.5-1.5S26.89,17.5,26.06,17.5z"
    />
  </svg>
);

export default MapControlsZoomMoreIcon;
