/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const ModalDialog = ( {
  title,
  markup,
  buttons,
} ) => {
  const content = { __html: markup };

  return (
    <>
      <div className="modalSubtitle">{title}</div>
      <div className="modalDescription" dangerouslySetInnerHTML={content} />

      { buttons?.length > 0 && (
        <div className="modal__inlineBtns">
          { buttons.map( ( button ) => (
            <button
              key={button.key}
              className={button.class || 'modalBtn'}
              type="button"
              onClick={button.func}
            >
              {button.text}
            </button>
          ) )}
        </div>
      )}
    </>
  );
};

ModalDialog.propTypes = {
  title: PropTypes.string,
  markup: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape( {
      class: PropTypes.string,
      func: PropTypes.func,
      text: PropTypes.string,
    } ),
  ),
};

ModalDialog.defaultProps = {
  title: '',
  markup: '',
  buttons: [],
};

export default ModalDialog;
