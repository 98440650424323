import Color from 'color';
import {
  useLocation,
} from 'react-router-dom';

export function getUrlData( window ) {
  const hostArray = window.location.host.split( '.' );
  const { pathname } = window.location || null;

  // Determine if YT is in kiosk mode.
  const urlParams = new URLSearchParams( window.location.search );
  const mode = urlParams.get( 'mode' ) || false;
  const isKiosk = mode === 'kiosk';

  // Most pages have the format urlslug.viewyourtour.com/blah and will use the
  // normal navbar and whatnot. The PIB does not.
  let urlSlug = hostArray.length > 1 ? hostArray[0] : null;
  if ( urlSlug === 'www' ) {
    urlSlug = null;
  }

  // The PIB is viewyourtour.com/urlslug/vanity-plate/drupalNID, so if there is
  // no subdomain, we know were working with the PIB and need to get data a
  // different way.
  let isPIB = false;
  let drupalResidentId = null;
  let pibPath = null;
  if ( !urlSlug ) {
    const pathParts = pathname.split( '/' );
    // pathParts[0] is always empty
    urlSlug = pathParts[1];
    drupalResidentId = pathParts[3];
    isPIB = true;
    pibPath = pathname;
  }

  return {
    isKiosk,
    isPIB,
    pathname,
    urlSlug,
    drupalResidentId,
    pibPath,
  };
}

export function getLocationData() {
  const location = useLocation();
  return {
    isSiteSee: location.pathname.includes( 'map-embed' ),
    isMapTiles: location.pathname.includes( 'map-tiles' ),
  };
}

export function getEnvironmentData() {
  const apiUrl = process.env.REACT_APP_YOUR_TOUR_CMS_URL || 'https://clients.thevectre.com';
  return {
    apiUrl,
    jsonApiUrl: `${apiUrl}/jsonapi`,
  };
}

export function getSettings( window ) {
  const urlData = getUrlData( window );
  const locationData = getLocationData();
  const envData = getEnvironmentData();
  const data = {
    ...locationData, ...urlData, ...envData,
  };
  return data;
}

export function makeColors( primaryColor, secondaryColor ) {
  const primaryColorFactory = Color( primaryColor );
  const secondaryColorFactory = Color( secondaryColor );
  const colors = {
    primaryColor,
    secondaryColor,
    primaryLight10: primaryColorFactory.fade( 0.9 ),
    primaryDarkened: primaryColorFactory.darken( 0.2 ),
    secondaryColorLightened: secondaryColorFactory.isDark
      ? secondaryColorFactory.lightness( 90 )
      : secondaryColorFactory.lighten( 0.61 ),
    secondaryDarkened: secondaryColorFactory.darken( 0.2 ),
    secondaryLight80: secondaryColorFactory.fade( 0.8 ),
  };

  return colors;
}

export function createContainerStyleMarkup( primaryColor, secondaryColor ) {
  const colors = makeColors( primaryColor, secondaryColor );
  return {
    '--primary': primaryColor,
    '--primary--light--10': colors.primaryLight10,
    '--primary--darkened': colors.primaryDarkened,
    '--secondary': secondaryColor,
    '--secondary--lightened': colors.secondaryColorLightened,
    '--secondary--light--80': colors.secondaryLight80,
    '--secondary--darkened': colors.secondaryDarkened,
  };
}

// Add css variables to window root so they can be used in Portals that
// exist outside of app root.
export function setDocumentStyleProperties( window, primaryColor, secondaryColor ) {
  const colors = makeColors( primaryColor, secondaryColor );
  window.document.documentElement.style.setProperty(
    '--primary',
    colors.primaryColor,
  );
  window.document.documentElement.style.setProperty(
    '--primary--light--10',
    colors.primaryLight10,
  );
  window.document.documentElement.style.setProperty(
    '--primary--darkened',
    colors.primaryDarkened,
  );
  window.document.documentElement.style.setProperty(
    '--secondary',
    colors.secondaryColor,
  );
  window.document.documentElement.style.setProperty(
    '--secondary--lightened',
    colors.secondaryColorLightened,
  );
  window.document.documentElement.style.setProperty(
    '--secondary--light--80',
    colors.secondaryLight80,
  );
  window.document.documentElement.style.setProperty(
    '--secondary--darkened',
    colors.secondaryDarkened,
  );
}

export function checkIfPageEnabled( settings, ytConfig ) {
  const {
    enableKioskMode,
    enableSiteSee,
  } = ytConfig;
  const {
    isKiosk,
    isSiteSee,
  } = settings;

  let allowAccess = true;
  if ( isKiosk && !enableKioskMode ) {
    allowAccess = false;
  } else if ( isSiteSee && !enableSiteSee ) {
    allowAccess = false;
  }

  return allowAccess;
}

export function checkIfLoginNeeded( settings, featureFlags) {
  const {
    isPIB,
    isSiteSee,
    isKiosk,
    isMapTiles,
  } = settings;
  const hasAdminLogin = featureFlags?.includes( 'admin_login' ) || false;
  const canLogin = !isPIB && !isSiteSee && hasAdminLogin && !isKiosk && !isMapTiles;
  return canLogin;
}
