import React from 'react';
import PropTypes from 'prop-types';

const ModalTableInfo = ( { id, sectionClassName, tableInfo, tableDescription } ) => (
  <div id={id} className={`modalSection modalTable${sectionClassName ? ' ' + sectionClassName : '' }`}>
    {tableInfo && tableInfo.length > 0 && (
      <div className="modalList">
        {tableInfo.map(( stat, index ) => (
          <div className="modalList__item" key={ index }>
            <div>
              <span className="modalList__label">{stat.label}</span>:{' '}
              {stat.data}
            </div>
            {stat.note && (
              <div className="modalList__note">
                {stat.note}
              </div>
            )}
          </div>
        ) )}
      </div>
    )}

    {tableDescription && (
      <p className="modalDescription">{tableDescription}</p>
    )}
  </div>
);

ModalTableInfo.propTypes = {
  id: PropTypes.string,
  tableInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.string,
      note: PropTypes.string,
    })
  ),
  sectionClassName: PropTypes.string,
  tableDescription: PropTypes.string,
};

export default ModalTableInfo;
