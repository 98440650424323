import React from 'react';

import ZoomZoneIcon from '../../Map/ZoomZones/ZoomZoneIcon';

const SiteSeeZoomsGuide = () => (
  <>
    Click any label on the map with a

    <span className="siteSeeGuide__icon siteSeeGuide__icon--inline">
      <ZoomZoneIcon />
    </span>

    symbol to zoom in on a specific building or area and reveal more details.
  </>
);

export default SiteSeeZoomsGuide;
