import React, { useEffect, useContext } from 'react';
import classNames from 'classnames';
import FormItem from '../FormComponents/FormItem';
import ConfigContext from '../../../../utils/ConfigContext/ConfigContext';

const AccountFoundStep = ( {
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  isSubmitting,
  crmDataState,
  setCurrentStep,
  dataIsSent,
} ) => {
  const { crmConfig } = useContext( ConfigContext );
  const {
    humanName,
    machineName,
    loginUrl,
  } = crmConfig;

  // CRMs that don't allow editing can still make it to this step if the person
  // doens't have an email. Otherwise they are sent directly to the session.
  const NonEditableCrmInstructions = () => `Please enter an email for this ${humanName} prospect. All other information is not editable. This email will not be reflected in ${humanName}. Click the START PERSONALIZATION button to proceed.`;

  // Form instructions for Welcome Home or other CRMs that allow changes.
  const EditableCrmInstructions = () => `We found a match in ${humanName}. Review and/or update the information below. Any updates will automatically be sent to ${humanName}. Click the START PERSONALIZATION button to proceed.`;

  // Form instructions for people that are just using Drupal as their CRM.
  const DrupalInstructions = () => 'Review and/or update the information below and click the START PERSONALIZATION button to proceed.';

  // Select instructions based on CRM.
  const Instructions = () => {
    let instructions = null;
    switch ( machineName ) {
      case 'welcomeHome':
        instructions = <EditableCrmInstructions />;
        break;
      case 'sherpa':
      case 'enquire':
        instructions = <NonEditableCrmInstructions />;
        break;
      default:
        instructions = <DrupalInstructions />;
    }
    return instructions;
  };

  // Disable editing fields, except for email, for non editiable CRMS.
  const disabled = machineName === 'enquire' || machineName === 'sherpa';

  return (
    <div className="personalization__form-crm-step">

      <h3 className="personalization__form-header">
        Personalize
        {' '}
        <span>Your</span>
        Tour
      </h3>

      <div className="personalization__instructions"><Instructions /></div>

      <div className="form__items">

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.first_name}
          touched={touched}
          errors={errors}
          type="text"
          name="first_name"
          label="First Name"
          disabled={disabled}
        />

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.last_name}
          touched={touched}
          errors={errors}
          type="text"
          name="last_name"
          label="Last Name"
          disabled={disabled}
        />

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.email}
          touched={touched}
          errors={errors}
          type="email"
          name="email"
          label="Email"
        />

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.person2_first_name}
          touched={touched}
          errors={errors}
          type="text"
          name="person2_first_name"
          label="Person 2 First Name"
          disabled={disabled}
        />

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.person2_last_name}
          touched={touched}
          errors={errors}
          type="text"
          name="person2_last_name"
          label="Person 2 Last Name"
          disabled={disabled}
        />

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.person2_email}
          touched={touched}
          errors={errors}
          type="email"
          name="person2_email"
          label="Person 2 Email"
          disabled={disabled}
        />

      </div>

      <div className="personalization__inline-buttons">
        <button
          disabled={dataIsSent || isSubmitting}
          type="submit"
          className={classNames( 'personalization__cta personalization__cta--secondary', {
            'personalization__cta--working': dataIsSent || isSubmitting,
          } )}
        >
          { dataIsSent || isSubmitting ? 'Updating Prospect . . .' : 'Start Personalization'}
        </button>

        { ( machineName !== 'drupal' ) && (
          <a
            href={loginUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="personalization__cta-minor"
            onClick={() => {
              setCurrentStep( 1 );
            }}
          >
            Or Edit in
            {' '}
            {humanName}
          </a>
        )}

      </div>
    </div>
  );
};

export default AccountFoundStep;
