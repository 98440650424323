import { createContext } from 'react';

const MapContext = createContext({
  unitsData: [],
  activeLevelOfCareUuid: '',
  setActiveLevelOfCareUuid: null,
  activeLevelSvgId: '',
  activeLevelUuid: '',
  activeFloorPlanUuids: [],
  setActiveFloorPlanUuids: null,
  activeCategoryFilter: [],
  setActiveCategoryFilter: null,
  activeFilterSelections: {},
  setActiveFilterSelections: null,
  // Result Floor Plans - Captures the total floor plans returned in search.
  resultFloorPlans: [],
  setResultFloorPlans: null
});

export default MapContext;
