import React from 'react';

const FormattedStickyNoFavoritesBlurb = ( phone, email ) => {
  const markup = (
    <div className="pibPage-favorites__stickyContent">
      <div className="pibPage-favorites__stickyContent__wysiwyg pibPage-favorites__stickyContent__wysiwyg--centered">
        <p>
          You don’t have any favorites yet. If you think this is an error,&nbsp;
          { email
            && (
            <>
              email us at
              {' '}
              <a href={`mailto:${email}`}>{email}</a>
            </>
            )}

          { phone && (
            <>
              { email
                ? <> or call </>
                : <> call </>}
              us at
              {' '}
              {phone}
              .
            </>
          )}

          { !email && !phone
            && (
            <>
              contact your sales person.
            </>
            )}
        </p>
      </div>
    </div>
  );
  return markup;
};

export default FormattedStickyNoFavoritesBlurb;
