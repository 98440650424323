import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import FadingContainer from '../FadingContainer/FadingContainer';
import OnImageDropdown from '../OnImageDropdown/OnImageDropdown';

import './FinishCollection.scss';
import logoUrl from './blue-fingerprint.png';

const FinishCollection = ( {
  id,
  finishCollection,
  className,
  isSiteSee,
} ) => {
  const [ spaces, setSpaces ] = useState( [] );
  const [ palettes, setPalettes ] = useState( [] );
  const [ backgroundImage, setBackgroundImage ] = useState( null );
  const [ activeImage, setActiveImage ] = useState( null );
  const [ activeSpace, setActiveSpace ] = useState( null );
  const [ activePalette, setActivePalette ] = useState( null );

  const { config } = useContext( ConfigContext );

  // Loop through finish options to build space button data. Data is already
  // weighted correctly.
  const rawSpaces = finishCollection?.spaces;
  const defaultSpaceId = finishCollection?.defaultSpaceId;
  useEffect( () => {
    const spaceOptions = [];
    rawSpaces?.forEach( ( space ) => {
      const spaceOption = {
        id: space.spaceId,
        text: space.spaceName,
        isDefault: defaultSpaceId === space.spaceId,
      };
      spaceOptions.push( spaceOption );
    } );
    setSpaces( spaceOptions );
  }, [ rawSpaces, defaultSpaceId ] );

  // Set initial space, i.e. Master Bedroom, Lobby, etc.
  useEffect( () => {
    spaces.forEach( ( space ) => {
      if ( space.isDefault ) {
        setActiveSpace( space.id );
      }
    } );
  }, [ spaces ] );

  // Set background image of palette container. We're showing the image of
  // selected space/palette combo by updating the a background image in a persistent div
  // rather than hiding/showing divs like a tradtional carousel.
  const activeBackgroundImage = finishCollection?.images?.length
    ? finishCollection.images[0]?.imageUrl
    : null;
  useEffect( () => {
    setBackgroundImage( activeBackgroundImage );
  }, [ activeBackgroundImage ] );

  // Loop through space palettes to build palette button data. Data is already
  // weighted correctly.
  useEffect( () => {
    if ( activeSpace ) {
      const paletteOptions = [];
      const activeSpaceData = rawSpaces?.find(
        ( { spaceId } ) => spaceId === activeSpace,
      );
      activeSpaceData?.palettes.paletteOptions.forEach( ( palette ) => {
        const paletteOption = {
          id: palette.paletteId,
          text: palette.paletteName,
          isDefault:
            activeSpaceData.palettes.defaultPaletteId === palette.paletteId,
        };
        paletteOptions.push( paletteOption );
      } );
      setPalettes( paletteOptions );
    }
  }, [ activeSpace, rawSpaces ] );

  // Set initial palette, i.e. Palette A, Palette One, etc. When a space is
  // initially loaded or chosen, we want to show it in the default palette for that
  // space, which is a taxonomy field info that comes over from Drupal.
  useEffect( () => {
    palettes.forEach( ( palette ) => {
      if ( palette.isDefault ) {
        setActivePalette( palette.id );
      }
    } );
  }, [ palettes ] );

  // Set active image.
  const rawImages = finishCollection?.images;
  useEffect( () => {
    if ( activeSpace && activePalette && rawImages ) {
      const filter = {
        spaceId: activeSpace,
        paletteId: activePalette,
      };

      const image = rawImages.filter( ( item ) => {
        // eslint-disable-next-line no-restricted-syntax
        for ( const key in filter ) {
          if ( item[key] === undefined || item[key] !== filter[key] ) {
            return false;
          }
        }
        return true;
      } );

      const newActiveImage = image && image[0] ? encodeURI( image[0].imageUrl ) : null;
      setActiveImage( newActiveImage );
    }
  }, [
    activeSpace, activePalette, rawImages,
  ] );

  const handleSpaceOnClick = ( space ) => {
    setActiveSpace( space.id );
  };

  const handlePaletteOnClick = ( palette ) => {
    setActivePalette( palette.id );
  };

  return finishCollection && Object.keys( finishCollection ).length ? (
    <FadingContainer>
      <div id={id} className={`modalSection ${className}`}>
        <div className="finishCollection modalSection--sixteen9">
          <LoadingSpinner />

          <div
            className="finishCollection__background"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          />

          <div
            className="finishCollection__image"
            style={{ backgroundImage: `url(${activeImage})` }}
          >
            {spaces.length > 1 && (
              <OnImageDropdown
                className="finishCollection__spaces"
                optionsLocation="left"
                closeDropdownOnClick
                options={spaces}
                handleOptionOnClick={( space ) => handleSpaceOnClick( space )}
              />
            )}

            {palettes.length > 1 && (
              <OnImageDropdown
                className="finishCollection__palettes"
                optionsLocation="altRight"
                closeDropdownOnClick
                options={palettes}
                handleOptionOnClick={( palette ) => handlePaletteOnClick( palette )}
              />
            )}
          </div>
        </div>

        { !config?.isPIB && !isSiteSee && (
          <div className="finishCollection__blueFingerprint">
            <a
              className="finishCollection__blueFingerprintLink"
              target="_blank"
              rel="noreferrer noopener"
              href="https://app.bluefingerprint.com/"
            >
              <img
                src={logoUrl}
                alt="BlueFingerprint"
                className="finishCollection__blueFingerprintLogo"
              />
            </a>
          </div>
        )}
      </div>
    </FadingContainer>
  ) : null;
};

FinishCollection.propTypes = {
  id: PropTypes.string,
  finishCollection: PropTypes.shape( {} ),
  className: PropTypes.string,
  isSiteSee: PropTypes.bool,
};

FinishCollection.defaultProps = {
  id: '',
  finishCollection: {},
  className: '',
  isSiteSee: false,
};

export default FinishCollection;
