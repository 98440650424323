import React from 'react';

import {ReactComponent as ErrorCircleIcon} from '../../../../SVGAssets/error-circle.svg';

const FormFieldError = ({errorMsg}) => {
  return (
    <div className="form-error-msg">
      <ErrorCircleIcon /><span className="form-error-msg__text">{errorMsg}</span>
    </div>
  );
}

export default FormFieldError;