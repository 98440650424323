import React, { useState, useEffect, useContext } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import VirtualTourItem from './VirtualTourItem';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const VirtualTourList = ( { data } ) => {
  const configContextStuff = useContext( ConfigContext );
  const { config } = configContextStuff;
  const [ tours, setTours ] = useState( [] );

  useEffect( () => {
    if ( config && data ) {
      const nodes = normalizeData( data, config.apiUrl );
      setTours( nodes );
    }
  }, [ config, data ] );

  const normalizeData = ( responseData, apiUrl ) => {
    const nodes = [];
    const drupalData = responseData.data;
    const drupalIncluded = responseData.included;

    const drupalIncludedById = {};
    if ( drupalIncluded !== undefined ) {
      drupalIncluded.forEach( ( item, index ) => {
        const { id } = item;
        drupalIncludedById[id] = item;
      } );
    }

    if ( drupalData.length ) {
      drupalData.forEach( ( item, index ) => {
        const node = {
          uuid: item.id,
          title: {
            title: item.attributes.title,
          },
        };

        const coverImageFieldData = item.relationships.field_cover_photo.data;
        if ( coverImageFieldData ) {
          node.coverImage = coverImageFieldData.meta.imageDerivatives?.links.one_third_screen.href;
        }

        nodes.push( node );
      } );
    }

    return nodes;
  };

  return (
    <>
      {!tours.length
        && <LoadingSpinner />}

      {tours.map( ( node ) => (
        <VirtualTourItem
          {...node}
          key={node.uuid}
        />
      ) )}
    </>
  );
};

export default VirtualTourList;
