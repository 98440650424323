/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import MapContext from '../../MapContext';
import Accordion from '../../Accordion/Accordion';
import DetailsButtons from './DetailsButtons';

const DetailsButtonsList = ( { mapFilterData, goToPrevPanel } ) => {
  const [ detailsData, setDetailsData ] = useState( [] );
  const [ hasActiveFilters, setHasActiveFilters ] = useState( false );
  const mapContextStuff = useContext( MapContext );
  const {
    activeLevelOfCareUuid,
    activeCategoryFilter,
    activeFilterSelections,
    setActiveFilterSelections,
  } = mapContextStuff;

  useEffect( () => {
    if ( Object.keys( mapFilterData ).length && activeLevelOfCareUuid && activeCategoryFilter ) {
      const locData = mapFilterData[activeLevelOfCareUuid]?.children;
      const rawDetailsData = locData?.[activeCategoryFilter]?.children;

      if ( rawDetailsData ) {
        // Sort Items by Weight, then Alphabetically.
        const sorted = Object.values( rawDetailsData ).sort( ( a, b ) => ( a.weight > b.weight
          ? 1
          : a.weight === b.weight
            ? a.label > b.label
              ? 1
              : -1
            : -1 ) );

        setDetailsData( sorted );
      } else {
        setDetailsData( [] );
      }
    }
  }, [
    activeCategoryFilter, mapFilterData, activeLevelOfCareUuid,
  ] );

  // Set status when there are any active filters.
  useEffect( () => {
    setHasActiveFilters( Object.keys( activeFilterSelections ).length > 0 );
  }, [ activeFilterSelections ] );

  const handleClearAllFilters = () => {
    // Set active selections to an empty object.
    setActiveFilterSelections( {} );
  };

  // If we have no data, show the empty message.
  if ( !detailsData ) {
    const emptyMessage = 'There are no filters available based on your previous selections. Please refine your search by removing filters or by clearing them all.';
    return (
      <Accordion
        title="Back to Categories"
        createBackButton
        goToPrevPanel={goToPrevPanel}
      >
        <div className="mapFilter--no-results">
          <div className="mapFilter--no-results-text type-cta-md">
            {emptyMessage}
          </div>

          { hasActiveFilters && (
          <button className="mapFilter--clear-filters type-cta-md" onClick={() => handleClearAllFilters()}>Clear all filters</button>
          )}
        </div>
      </Accordion>
    );
  }

  return (
    <Accordion
      title="Back to Categories"
      createBackButton
      goToPrevPanel={goToPrevPanel}
    >
      { detailsData && detailsData.map( ( detailData ) => (
        <DetailsButtons
          detailItemLabel={detailData.label}
          detailItemUuid={detailData.uuid}
          key={detailData.uuid}
          goToPrevPanel={goToPrevPanel}
          detailItemMeta={detailData.meta}
          isActive={activeFilterSelections[activeCategoryFilter]?.[detailData.uuid] !== undefined}
        />
      ) )}
    </Accordion>
  );
};

DetailsButtonsList.propTypes = {
  goToPrevPanel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mapFilterData: PropTypes.object.isRequired,
};

export default DetailsButtonsList;
