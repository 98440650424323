import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import ModalTrigger from '../Modal/ModalTrigger';
import Favorite from '../Personalization/Favorite/Favorite';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

const PhotoGalleryItem = ( {
  uuid,
  modalInfo,
  makeButton,
  coverImage,
  imageClass,
  galleryClass,
} ) => {
  const { personalizationActive } = useContext( ConfigContext );

  const imgBg = {
    backgroundImage: `url(${coverImage})`,
  };

  const favorite = personalizationActive ? (
    <Favorite
      type="photo_gallery"
      uuid={uuid}
      hasShadow={false}
    />
  ) : null;

  const innerMarkup = (
    <div className="imgContainer">
      <div className="imgHolder" style={{ ...imgBg }} />
      <h2 className="gridItemTitle type-display-xl">
        {modalInfo?.title?.title}
      </h2>
    </div>
  );

  let markup = '';
  if ( makeButton ) {
    markup = (
      <ModalTrigger
        uuid={uuid}
        nodeType="gallery"
        triggerType="div"
        markup={innerMarkup}
        imageClass={imageClass}
        galleryClass={galleryClass}
        modalInfo={modalInfo}
      />
    );
  }

  return (
    <div className="gridItem">
      { markup }
      { favorite }
    </div>
  );
};

PhotoGalleryItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  modalInfo: PropTypes.shape( {
    title: PropTypes.shape( {
      title: PropTypes.string,
    } ),
  } ),
  makeButton: PropTypes.bool,
  coverImage: PropTypes.string,
  imageClass: PropTypes.string,
  galleryClass: PropTypes.string,
};

PhotoGalleryItem.defaultProps = {
  modalInfo: null,
  makeButton: false,
  coverImage: null,
  imageClass: null,
  galleryClass: null,
};

export default PhotoGalleryItem;
