import React, { useState, useRef } from 'react';

const Accordion = ({ title, children, goToPrevPanel, createBackButton}) => {
  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(true);

  let contentStyle = {};
  contentStyle.maxHeight = '100%';


  return (
    <div className="mapFilter-panel-options--category">
      { createBackButton &&
        <button
          className="mapFilter-accordion-trigger type-cta-sm"
          onClick={goToPrevPanel}
        >
          {title}
        </button>
      }

      <div
        ref={contentRef}
        style={contentStyle}
        className={`mapFilter-accordion`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
