import React from 'react';

import './HomePage.scss';

import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';

const HomePage = ( { galleryUuid } ) => (
  <div className="homescreen">
    {galleryUuid
        && (
        <PhotoGalleryDetail
          id={null}
          uuid={galleryUuid}
          images={null}
          galleryClass="homescreenSlideshow"
          imageClass="homescreenSlideshowImage"
        />
        )}
  </div>
);

export default HomePage;
