/* eslint-disable import/prefer-default-export */
export function PhotoGalleryDetailNormalizeData( responseData ) {
  const node = {};
  const images = [];

  const drupalData = responseData.data;
  const drupalIncluded = responseData.included;

  drupalIncluded?.forEach( ( item, index ) => {
    const image = {
      id: item.id,
      image: {
        modal: item.links.modal?.href,
        fullScreen: item.links.full_screen?.href,
      },
      caption:
        drupalData.relationships.field_gallery_photos.data[index].meta.alt,
    };
    images.push( image );
  } );

  // If they somehow don't make a photo gallery, show the cover photo as the gallery.
  const coverPhoto = drupalData?.relationships?.field_cover_photo?.data;
  if ( !images.length && coverPhoto ) {
    const image = {
      id: coverPhoto.id,
      image: {
        modal: coverPhoto?.meta?.imageDerivatives?.links?.one_third_screen?.href,
        fullScreen: coverPhoto?.meta?.imageDerivatives?.links?.one_third_screen?.href,
      },
      caption: null,
    };
    images.push( image );
  }

  node.images = images;
  return node;
}
