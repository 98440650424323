import React, {
  useEffect, useRef, useState,
} from 'react';
import $ from 'jquery';

import { preloadPhoto, ConditionalWrapper, cleanUrl } from '../../../utils/common';
import { PhotoGalleryDetailNormalizeData } from './PhotoGalleryDetailNormalizeData';
import { getNormalizedApiDataByUuid } from '../../../utils/ApiHelpers';

import FadingContainer from '../../FadingContainer/FadingContainer';
import PhotoGalleryImage from '../PhotoGalleryImage/PhotoGalleryImage';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import './PhotoGalleryDetail.scss';

const PhotoGalleryDetail = ( {
  id,
  uuid,
  images,
  galleryClass,
  imageClass,
} ) => {
  const [ photoGalleryData, setPhotoGalleryData ] = useState( null );
  const [ firstPicIsLoaded, setFirstPicIsLoaded ] = useState( false );
  const [ backgroundImage, setBackgroundImage ] = useState( null );
  const [ showControls, setShowControls ] = useState( false );
  const [ isHomescreenSlideshow, setIsHomescreenSlideshow ] = useState( false );
  const [ photos, setPhotos ] = useState( [] );
  const [ formattedPhotos, setFormattedPhotos ] = useState( [] );

  // Is this a homescreen slideshow?
  useEffect( () => {
    const rawIsHomescreenSlideshow = galleryClass === 'homescreenSlideshow';
    setIsHomescreenSlideshow( rawIsHomescreenSlideshow );
  }, [ galleryClass ] );

  // Should we show the gallery controls?
  useEffect( () => {
    const rawShowControls = photos?.length > 1 && !isHomescreenSlideshow;
    setShowControls( rawShowControls );
  }, [ photos, isHomescreenSlideshow ] );

  // Get and set gallery data from uuid.
  useEffect( () => {
    let isMounted = true;
    if ( uuid ) {
      const fetchData = async () => {
        const request = {
          entityType: 'node',
          contentType: 'photo_gallery',
          uuid,
          params: {
            include: 'field_gallery_photos',
          },
        };
        const data = await getNormalizedApiDataByUuid( request, PhotoGalleryDetailNormalizeData );
        if ( isMounted ) {
          setPhotoGalleryData( data );
        }
      };
      fetchData();
    }
    return () => { isMounted = false; };
  }, [ uuid ] );

  // Set photos when gallery data exists.
  useEffect( () => {
    setPhotos( photoGalleryData?.images || [] );
  }, [ photoGalleryData ] );

  // Set photos directly from props.
  useEffect( () => {
    setPhotos( images || [] );
  }, [ images ] );

  // Use the correct photo style depending on whether or not this is a
  // homescreen slideshow and clean up the url.
  useEffect( () => {
    if ( photos?.length > 0 ) {
      const rawFormattedPhotos = [];
      // eslint-disable-next-line no-restricted-syntax
      for ( const photo of photos ) {
        const image = isHomescreenSlideshow ? photo.image.fullScreen : photo.image.modal;
        const formattedPhoto = {
          uuid: photo.id || photo.uuid,
          caption: photo.caption,
          formattedImage: cleanUrl( image ),
        };
        rawFormattedPhotos.push( formattedPhoto );
      }
      setFormattedPhotos( rawFormattedPhotos );
    }
  }, [ photos, isHomescreenSlideshow ] );

  // Preload the first gallery photo so that
  // once it's loaded, we can fade in the gallery and set it as the background image.
  useEffect( () => {
    const handlePreloadingPhotos = async ( firstImage ) => {
      try {
        const result = await preloadPhoto( firstImage );
        if ( result === 'Success' ) {
          setFirstPicIsLoaded( true );
        }
      } catch ( err ) {
        console.error( 'Preload for photo failed', err );
      }
    };
    if ( formattedPhotos?.length > 0 ) {
      const firstImage = formattedPhotos[0].formattedImage;
      setBackgroundImage( firstImage );
      handlePreloadingPhotos( firstImage );
    }
  }, [ formattedPhotos ] );

  // Initialize the gallery once the first photo is loaded.
  // We have to include formatted photos in the dependency array to reset the
  // slideshow on PIB when a user toggles between assets.
  useEffect( () => {
    if ( firstPicIsLoaded ) {
      if ( isHomescreenSlideshow ) {
        initializeHomescreenGallery();
      } else {
        initializeGallery();
      }
    }
    return () => {
      resetGallery();
    };
  }, [
    firstPicIsLoaded,
    isHomescreenSlideshow,
    formattedPhotos,
  ] );

  // Add automatic slideshow functionality to homescreen slideshow.
  useEffect( () => {
    if ( isHomescreenSlideshow ) {
      const myInterval = setInterval( () => {
        $( '.homescreenSlideshow > div:first' )
          .fadeOut( 1500 )
          .next()
          .fadeIn( 1500 )
          .end()
          .appendTo( '.homescreenSlideshow' );
      }, 6000 );
      return () => {
        clearInterval( myInterval );
      };
    }
  }, [ isHomescreenSlideshow ] );

  const slideshowElement = useRef( null );

  // initialize gallery
  const initializeGallery = () => {
    const parent = slideshowElement.current;
    $( parent ).find( '.slideshowPhoto' ).first().addClass( 'newActive' );
    $( parent ).find( '.slideshowPhoto' ).hide();
    $( parent ).find( '.newActive' ).show();
  };

  // initialize homescreen gallery
  const initializeHomescreenGallery = () => {
    $( '.homescreenSlideshow > div:gt(0)' ).hide();
  };

  // reset gallery
  const resetGallery = () => {
    const parent = slideshowElement.current;
    $( parent ).find( '.newActive' ).hide();
    $( parent ).find( '.oldActive' ).removeClass( 'oldActive' );
    $( parent ).find( '.newActive' ).removeClass( 'newActive' );
  };

  // right button click handler
  const handleRightClick = () => {
    const parent = slideshowElement.current;
    $( parent ).find( '.newActive' ).removeClass( 'newActive' ).addClass( 'oldActive' );
    const oldActive = $( parent ).find( '.oldActive' );
    if ( $( oldActive ).is( ':last-child' ) ) {
      $( parent ).find( '.slideshowPhoto' ).first().addClass( 'newActive' );
    } else {
      $( oldActive ).next().addClass( 'newActive' );
    }
    $( oldActive ).removeClass( 'oldActive' );
    $( parent ).find( '.slideshowPhoto' ).fadeOut();
    $( parent ).find( '.newActive' ).fadeIn();
  };

  // left button click handler
  const handleLeftClick = ( ) => {
    const parent = slideshowElement.current;
    $( parent ).find( '.newActive' ).removeClass( 'newActive' ).addClass( 'oldActive' );
    const oldActive = $( parent ).find( '.oldActive' );
    if ( $( oldActive ).is( ':first-child' ) ) {
      $( parent ).find( '.slideshowPhoto' ).last().addClass( 'newActive' );
    } else {
      $( oldActive ).prev().addClass( 'newActive' );
    }
    $( oldActive ).removeClass( 'oldActive' );
    $( parent ).find( '.slideshowPhoto' ).fadeOut();
    $( parent ).find( '.newActive' ).fadeIn();
  };

  return firstPicIsLoaded
    ? (
      <FadingContainer>
        <div id={id || ''} className={galleryClass} ref={slideshowElement}>

          { showControls && (
            <>
              <button
                type="button"
                className="slideshowControls slideshowControls-left"
                onClick={handleLeftClick}
              >
                <span className="arrow">
                  <span />
                  <span />
                </span>
              </button>

              <button
                type="button"
                className="slideshowControls slideshowControls-right"
                onClick={handleRightClick}
              >
                <span className="arrow">
                  <span />
                  <span />
                </span>
              </button>
            </>
          )}

          <ConditionalWrapper
            condition={!isHomescreenSlideshow}
            wrapper={( children ) => <div className="slideshowContainer">{children}</div>}
          >

            { !isHomescreenSlideshow && (
              <div
                className="slideshowBackground"
                style={{ backgroundImage: `url(${backgroundImage})` }}
              />
            )}

            <ConditionalWrapper
              condition={!isHomescreenSlideshow}
              wrapper={( children ) => <div>{children}</div>}
            >
              { formattedPhotos?.length > 0 && formattedPhotos.map( ( photo, i ) => (
                <PhotoGalleryImage
                  key={photo.uuid}
                  uuid={photo.uuid}
                  image={photo.formattedImage}
                  caption={photo.caption}
                  className={imageClass}
                  count={formattedPhotos.length}
                  index={i}
                />
              ) )}
            </ConditionalWrapper>

          </ConditionalWrapper>

        </div>
      </FadingContainer>
    )
    : (
      <div id={id || ''} className={galleryClass}>
        <ConditionalWrapper
          condition={!isHomescreenSlideshow}
          wrapper={( children ) => <div className="slideshowContainer">{children}</div>}
        >
          <LoadingSpinner />
        </ConditionalWrapper>
      </div>
    );
};

export default PhotoGalleryDetail;
