import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import './NavItem.scss';

const NavItem = ({
  destination, 
  label, 
  Icon}
) => {

  // maintain url params
  const { search } = useLocation();

  return (
    <NavLink
      to={`${destination}${search}`}
      className='main-nav__item'
    >
      <div className="main-nav__icon">
        {Icon && (<Icon />)}
      </div>
      <div className="main-nav__label">{label}</div>
    </NavLink>
  );
};

export default NavItem;
