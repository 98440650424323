import React, { useContext } from 'react';

import MapContext from '../../../MapPage/MapContext';
import ConfigContext from '../../../../utils/ConfigContext/ConfigContext';
import MapControlsZoomMoreIcon from './MapControlsZoomMoreIcon';

const MapControlsZoomMore = ( {
  maxZoom,
  siteSeeGuideId,
  setSiteSeeGuideId,
  siteSeeGuideMode,
} ) => {
  const {
    activeZoom,
    setActiveZoom,
  } = useContext( MapContext );

  const { isSiteSee } = useContext( ConfigContext );

  const zoomMore = () => {
    const newZoom = activeZoom + 1;
    setActiveZoom( newZoom );
    // While in SiteSee guide tour, if the user decides to interact with the map by
    // using the zoom in button in the Map Controls toolbar or clicking a Zoom
    // Zone button triggering the detailed view, Tip 1 would fade out, Tips 2
    // and 3 would automatically be skipped, and Tip 4 would fade in.
    if ( isSiteSee && siteSeeGuideMode === 'tour' && siteSeeGuideId < 4 ) {
      setSiteSeeGuideId( 4 );
    }
  };

  let buttonClass = '';
  if ( activeZoom && maxZoom ) {
    if ( activeZoom >= maxZoom ) {
      buttonClass = 'mapControl--disabled';
    }
  }

  return (
    <button
      type="button"
      className={`mapControl mapControls-zoom--more ${buttonClass}`}
      onClick={zoomMore}
    >
      <MapControlsZoomMoreIcon />
    </button>
  );
};

export default MapControlsZoomMore;
