import React from 'react';
import { Link } from 'react-scroll';

import FadingContainer from '../../FadingContainer/FadingContainer';

import './DetailNav.scss';

const DetailNav = ( { navBtns, navClass, offset } ) => {
  const containerId = navClass === 'modal__detailNav' ? 'modalScrollContainer' : null;

  return navBtns?.length > 1 && (
    <FadingContainer>
      <div className={`detailNav ${navClass}`}>
        {navBtns.map( ( btn, i ) => (
          <Link
            key={i}
            to={btn.section}
            smooth="easeInOutCubic"
            duration={500}
            className="detailNavItem"
            containerId={containerId}
            offset={-offset}
          >
            {btn.text}
          </Link>
        ) )}
      </div>
    </FadingContainer>
  );
};

export default DetailNav;
