import React, { useContext } from 'react';

import classNames from 'classnames';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import Portal from '../../utils/Portal';

import './Modal.scss';
import './FloatingModal.scss';

const FloatingModal = ({
  modalIsOpen,
  setModalIsOpen,
  modalSize,
  additionalCloseFunctions,
  children,
}) => {
  const { config } = useContext(ConfigContext);

  let containerStyle = {};
  if (config) {
    containerStyle = {
      '--primary': config.primaryColor,
      '--secondary': config.secondaryColor,
    };
  }

  const closeModal = () => {
    setModalIsOpen(false);
    additionalCloseFunctions();
  };

  return (
    <>
      {modalIsOpen && (
        <Portal portalId="modal-root">
          <div className="modalContainer" style={containerStyle}>
            <div className="blur closeMe" onClick={closeModal}></div>

            <div
              className={classNames('floatingModal', {
                'floatingModal--small': modalSize === 'small',
                'floatingModal--small-medium': modalSize === 'small-medium',
                'floatingModal--medium': modalSize === 'medium',
              })}
            >
              {children}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default FloatingModal;
