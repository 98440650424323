const labels = '#5b727e';
const GoogleMapWithPOIStyle = [
  {
    featureType: 'administrative.land_parcel',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'landscape',
    stylers: [ {
      color: '#f2f3ee',
    } ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'labels',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi',
    stylers: [ {
      visibility: 'on',
    } ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#799db0',
    } ],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#799db0',
    } ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'poi.government',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#799db0',
    } ],
  },
  {
    featureType: 'poi.government',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'poi.medical',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#d4684b',
    } ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [ {
      color: '#e1e2d9',
    },
    {
      visibility: 'on',
    } ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#7bb775',
    } ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#799db0',
    } ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'poi.school',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#799db0',
    } ],
  },
  {
    featureType: 'poi.school',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'labels.icon',
    stylers: [ {
      color: '#799db0',
    } ],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [ {
      color: '#d6d6d6',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [ {
      color: '#979b9b',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [ {
      lightness: 15,
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'transit',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'water',
    stylers: [ {
      color: '#b1bebf',
    } ],
  },
];

export default GoogleMapWithPOIStyle;
