import React, { useContext } from 'react';
import classnames from 'classnames';

import MapContext from '../../../MapPage/MapContext';
import MarkersIcon from './MarkersIcon';
import HideMarkersIcon from './HideMarkersIcon';

import '../MapControls.scss';

const MapControlsMarkersToggle = ( {
  setMarkersVisible,
  markersToggleVisible,
} ) => {
  const { markersVisible, POIVisible, activeMapType } = useContext( MapContext );

  return (
    <div
      className={
        classnames( 'mapControlToggle', {
          'mapControlToggle--disabled': !markersToggleVisible || POIVisible || activeMapType === 'hybrid',
        } )
      }
    >
      <button
        type="button"
        className={
          classnames( 'mapControlToggle-toggleOption', {
            'mapControlToggle-toggleOption--show': !markersVisible,
          } )
        }
        onClick={() => {
          setMarkersVisible( true );
        }}
      >
        <MarkersIcon />
      </button>

      <button
        type="button"
        className={
          classnames( 'mapControlToggle-toggleOption', {
            'mapControlToggle-toggleOption--show': markersVisible,
          } )
        }
        onClick={() => {
          setMarkersVisible( false );
        }}
      >
        <HideMarkersIcon />
      </button>

    </div>
  );
};

export default MapControlsMarkersToggle;
