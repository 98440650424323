import React, { useEffect, useState } from 'react';

import DetailHeader from '../Detail/DetailHeader';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import VideoDetail from '../Video/VideoDetail';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import FadingContainer from '../FadingContainer/FadingContainer';

import '../Detail/Detail.scss';

const MediaDetail = ( {
  data,
  coverPhotoGalleryFallback,
  internalHeader,
  navClass,
} ) => {
  const [ photoGalleryImages, setPhotoGalleryImages ] = useState( null );

  const Header = () => {
    let header = null;
    if ( internalHeader && data && navClass ) {
      const headerText = {
        title: data?.title,
        subtitle: null,
      };

      header = (
        <DetailHeader
          text={headerText}
          navClass={navClass}
        />
      );
    }

    return header;
  };

  return (
    <FadingContainer>
      <div id="mediaDetail">

        <Header />

        { data?.photoGalleryImages?.length > 0
          && (
          <PhotoGalleryDetail
            id={data?.id}
            uuid={null}
            images={data?.photoGalleryImages}
            galleryClass="slideshow detailSection detailSection--gallery"
            imageClass="slideshowPhoto"
          />
          )}

        { data?.video?.service
          && data?.video?.videoId
          && (
          <VideoDetail
            videoId={data.video.videoId}
            service={data.video.service}
            autoplay={false}
            className="detailSection"
          />
          )}

        { data?.threeSixties?.length > 0
          && (
          <VirtualTourDetail
            uuid={null}
            threeSixtiesData={data.threeSixties}
            className="detailSection modalSection--sixteen9"
          />
          )}

      </div>
    </FadingContainer>
  );
};

export default MediaDetail;
