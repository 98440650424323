/**
 * Generate a secure random string using the browser crypto functions.
 *
 * @returns {string}
 */
export function generateRandomString() {
  const array = new Uint32Array( 28 );
  window.crypto.getRandomValues( array );
  return Array.from( array, ( dec ) => ( `0${dec.toString( 16 )}` ).substr( -2 ) ).join( '' );
}

/**
 * Base64-urlencodes the input string.
 *
 * @param {string} str
 * @returns {string}
 */
function base64urlencode( str ) {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa( String.fromCharCode.apply( null, new Uint8Array( str ) ) )
    .replace( /\+/g, '-' ).replace( /\//g, '_' ).replace( /=+$/, '' );
}

/**
 * Calculate the SHA256 hash of the input text.
 *
 * Returns a promise that resolves to an ArrayBuffer
 *
 * @param {*} plain
 * @returns {Promise}
 */
function sha256( plain ) {
  const encoder = new TextEncoder();
  const data = encoder.encode( plain );
  return window.crypto.subtle.digest( 'SHA-256', data );
}

/**
 * Return the base64-urlencoded sha256 hash for the PKCE challenge.
 *
 * @param {*} v
 * @returns {string}
 */
export async function pkceChallengeFromVerifier( v ) {
  const hashed = await sha256( v );
  return base64urlencode( hashed );
}

/**
 * Parse a query string into an object.
 *
 * @param {string} string
 * @returns {object}
 */
export function parseQueryString( string ) {
  if ( string === '' ) { return {}; }

  const segments = string.split( '&' ).map( ( s ) => s.split( '=' ) );
  const queryString = {};

  segments.forEach( ( s ) => queryString[s[0]] = s[1] );

  return queryString;
}