import React, { useContext } from 'react';
// import PropTypes from 'prop-types';

import MapContext from '../../../MapPage/MapContext';

import './MapPlaceShape.scss';

const MapPlaceShape = ( { svgData } ) => {
  const context = useContext( MapContext );
  const { mapData } = context;
  const svgAttr = svgData.attributes;

  const isInvisible = svgData?.pin?.pin_type === 'Invisible SVG';
  const { id } = svgAttr;
  const className = !isInvisible ? 'place' : null;
  const preFill = mapData?.baseColors?.placeFill || 'white';
  const fill = isInvisible ? 'transparent' : preFill;
  const preStroke = mapData?.baseColors?.placeStroke || 'black';
  const stroke = isInvisible ? 'transparent' : preStroke;
  const transform = svgAttr?.transform || null;

  let placeShape = null;
  switch ( svgData.type ) {
    case 'polygon':
      placeShape = (
        <polygon
          data-svg-id={id}
          className={className}
          points={svgAttr.points}
          fill={fill}
          stroke={stroke}
          transform={transform}
        />
      );
      break;
    case 'polyline':
      placeShape = (
        <polyline
          data-svg-id={id}
          className={className}
          points={svgData.attributes.points}
          fill={fill}
          stroke={stroke}
          transform={transform}
        />
      );
      break;
    case 'path':
      placeShape = (
        <path
          data-svg-id={id}
          className={className}
          d={svgData.attributes.d}
          fill={fill}
          stroke={stroke}
          transform={transform}
        />
      );
      break;
    case 'rect':
      placeShape = (
        <rect
          data-svg-id={id}
          className={className}
          x={svgAttr.x}
          y={svgAttr.y}
          width={svgAttr.width}
          height={svgAttr.height}
          fill={fill}
          stroke={stroke}
          transform={transform}
        />
      );
      break;
    case 'circle':
      placeShape = (
        <circle
          data-svg-id={id}
          className={className}
          cx={svgAttr.cx}
          cy={svgAttr.cy}
          r={svgAttr.r}
          fill={fill}
          stroke={stroke}
          transform={transform}
        />
      );
      break;
    default:
      break;
  }
  return placeShape;
};

// PlaceShape.propTypes = {
//   svgData: PropTypes.object.isRequired,
// };

export default MapPlaceShape;
