import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './FadingContainer.scss';

const FadingContainer = ({ handleFadeOutComplete, children }) => {
  const [isFadingContainerVisible, setIsFadingContainerVisible] = useState(
    true
  );

  return (
    <CSSTransition
      in={isFadingContainerVisible}
      appear={true}
      timeout={500}
      classNames="fade"
      onExited={handleFadeOutComplete}
    >
      {typeof children === 'function'
        ? children(setIsFadingContainerVisible)
        : children}
    </CSSTransition>
  );
};

FadingContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  handleFadeOutComplete: PropTypes.func,
  key: PropTypes.string,
};

FadingContainer.defaultProps = {
  handleFadeOutComplete: null,
  key: '',
};

export default FadingContainer;
