/* eslint-disable react/no-danger */
import React, { useContext, useState } from 'react';
import classnames from 'classnames';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import Portal from '../../utils/Portal';
import FadingContainer from '../FadingContainer/FadingContainer';
import ModalHeader from './ModalHeader';
import PlaceDetail from '../Place/PlaceDetail';
import ResidenceDetail from '../Residence/ResidenceDetail';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import VideoDetail from '../Video/VideoDetail';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';

import './Modal.scss';

const Modal = ( {
  nodeType,
  uuid,
  showDescription,
  modalInfo,
  handleModalHide,
} ) => {
  const context = useContext( ConfigContext );
  const { config } = context;
  const { primaryColor, secondaryColor } = config;

  const [ navBtns, setNavBtns ] = useState( [] );

  const [ modalScrollActive, setModalScrollActive ] = useState( false );

  let title = modalInfo?.title;

  let detail = '';
  switch ( nodeType ) {
    case 'place':
      detail = (
        <PlaceDetail
          uuid={uuid}
          showDescription={showDescription}
          setNavBtns={setNavBtns}
        />
      );
      break;
    case 'residence':
      title = modalInfo;
      detail = (
        <ResidenceDetail
          uuid={uuid}
          setNavBtns={setNavBtns}
        />
      );
      break;
    case 'gallery':
      detail = (
        <PhotoGalleryDetail
          id="slideshow-scroll"
          uuid={uuid}
          images={null}
          galleryClass="slideshow modalSection--gallery"
          imageClass="slideshowPhoto"
        />
      );
      break;
    case 'virtual-tour':
      detail = (
        <VirtualTourDetail
          uuid={uuid}
          className="modalSection modalSection--sixteen9"
        />
      );
      break;
    case 'video':
      detail = (
        <VideoDetail
          videoId={modalInfo.videoId}
          service={modalInfo.service}
          autoplay
        />
      );
      break;
    default:
      break;
  }

  const handleModalScroll = ( event ) => {
    if ( event.currentTarget.scrollTop > 20 ) {
      setModalScrollActive( true );
    } else {
      setModalScrollActive( false );
    }
  };

  return (
    <Portal portalId="modal-root">
      <FadingContainer handleFadeOutComplete={handleModalHide}>
        {( setIsFadingContainerVisible ) => {
          const fadeOutModal = () => {
            setIsFadingContainerVisible( false );
          };

          return (
            <div
              className={
                classnames( 'modalContainer', { 'modal-scroll-active': modalScrollActive } )
              }
              style={{
                '--primary': primaryColor,
                '--secondary': secondaryColor,
              }}
            >
              <div className="blur" onClick={fadeOutModal} />

              { title && (
                <FadingContainer>
                  <ModalHeader
                    title={title}
                    navBtns={navBtns}
                    handleModalHide={fadeOutModal}
                  />
                </FadingContainer>
              )}

              <div className={`modal modal--${nodeType}`} onScroll={handleModalScroll}>
                <div className="modalContent">
                  {detail}
                </div>
              </div>

            </div>
          );
        }}
      </FadingContainer>
    </Portal>
  );
};

export default Modal;
