import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './ResourcesDetail.scss';

const ResourcesDetail = ( { myResourcesData } ) => {
  const { links, files } = myResourcesData;
  return (
    <div className="resourcesDetail">

      { links && links.length > 0 && (

        <div className="resourcesDetail-row">
          <h2>Links</h2>
          <ul>
            {links.map( ( link, index ) => (
              <li key={index}>
                <Link className="resourcesDetail-row__item" to={{ pathname: link.uri }} target="_blank">
                  {link.title}
                </Link>
              </li>
            ) )}
          </ul>
        </div>

      )}

      { files && files.length > 0 && (

        <div className="resourcesDetail-row">
          <h2>Documents</h2>
          <ul>
            {files.map( ( file, index ) => (
              <li key={index}>
                <Link className="resourcesDetail-row__item" to={{ pathname: file.path }} target="_blank">
                  {file.name}
                </Link>
              </li>
            ) )}
          </ul>
        </div>
      )}

    </div>
  );
};

ResourcesDetail.propTypes = {
  myResourcesData: PropTypes.object.isRequired,
};

export default ResourcesDetail;
