import React, { useState } from 'react';
import classNames from 'classnames';

import './MyLifeSiteLink.scss';

const MyLifeSiteLink = ({url}) => {

  const [ toggleMyLifeSiteLink, setToggleMyLifeSiteLink ] = useState(false);

  return (
    <>
      <button
        className={classNames('myLifeSiteLink__icon', { 'myLifeSiteLink__icon--active': toggleMyLifeSiteLink })}
        onClick={() => setToggleMyLifeSiteLink(true)}
      >
      </button>

      <div 
        className={classNames('myLifeSiteLink__banner', { 'myLifeSiteLink__banner--active': toggleMyLifeSiteLink })}
      >

        <a className="myLifeSiteLink__link" href={url} target="_new">
          <div className="myLifeSiteLink__cta">Find Your Financial Fit</div>
          <div className="myLifeSiteLink__attribution">powered by myLifeSite.net</div>
        </a>
        
        <button 
          className="myLifeSiteLink__close"
          onClick={() => setToggleMyLifeSiteLink(false)}
        >
        </button>

      </div>
    
    </>
  );
};

export default MyLifeSiteLink;