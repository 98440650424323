import React, { useContext, useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { getNormalizedApiDataByUuid } from '../../../utils/ApiHelpers';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';
import { createSalespersonName } from '../../../utils/User/UserHelpers';
import { userNormalizeData } from '../../../utils/User/UserNormalizeData';
import { getAuthClient } from '../../../utils/auth';
import NavItem from './NavItem/NavItem';
import { ReactComponent as AmenitiesNavIcon } from '../../../SVGAssets/nav-amenities.svg';
import { ReactComponent as GalleryNavIcon } from '../../../SVGAssets/nav-gallery.svg';
import { ReactComponent as MapNavIcon } from '../../../SVGAssets/nav-map.svg';
import { ReactComponent as FloorplanNavIcon } from '../../../SVGAssets/nav-floorplans.svg';
import Personalization from '../../Personalization/Personalization';

import './NavBar.scss';

const auth = new getAuthClient();

const NavBar = () => {
  const [ toggleSidebar, setToggleSidebar ] = useState( false );
  const [ showNavBar, setShowNavBar ] = useState( false );
  const [ salespersonCrmId, setSalespersonCrmId ] = useState( null );

  const {
    config,
    settings,
    appStatus,
    setIsLoggedIn,
    isLoggedIn,
    salespersonId,
    setSalespersonName,
    salespersonName,
  } = useContext( ConfigContext );

  const { communityLogo } = config || {};
  const {
    isKiosk, isSiteSee, isMapTiles, isPIB,
  } = settings || {};

  // Capture search params from current url location.
  const { search } = useLocation();

  const sideNavToggle = () => {
    setToggleSidebar( !toggleSidebar );
  };

  // Get and set user's sales person info.
  useEffect( () => {
    const fetchData = async () => {
      // Superadmin comes over as true instead of 1, so we need to convert it.
      const request = {
        entityType: 'user',
        contentType: 'user',
        uuid: salespersonId,
      };
      const data = await getNormalizedApiDataByUuid( request, userNormalizeData );
      if ( data ) {
        const rawSalesPersonName = createSalespersonName( data );
        setSalespersonName( rawSalesPersonName );
        const rawSalespersonCrmId = data.fieldCrmSalesCounselorId;
        setSalespersonCrmId( rawSalespersonCrmId );
      }
    };
    if ( salespersonId ) {
      fetchData();
    }
  }, [ salespersonId ] );

  // Should we render the nav?
  useEffect( () => {
    const noChrome = isSiteSee || isMapTiles || isPIB;
    if ( !noChrome ) {
      setShowNavBar( true );
    } else {
      setShowNavBar( false );
    }
  }, [ appStatus, isSiteSee, isMapTiles, isPIB ] );

  const handleLogoutClick = () => {
    setIsLoggedIn( false );

    // Revert pricing to toggled off.
    const displayedFees = JSON.parse( localStorage.getItem( 'userDisplayedFees' ) );
    if ( displayedFees ) {
      localStorage.removeItem( 'userDisplayedFees' );
    }

    // Remove classes from the body dom element.
    document.body.classList.remove( 'toggleDisplayFees', 'toggleDisplayFees__finished' );

    auth.logout();
  };

  return showNavBar && (
    <div
      className={classNames( 'main-nav__wrapper', {
        'main-nav__wrapper--active': toggleSidebar,
      } )}
    >
      <Link
        className="logo"
        style={{ backgroundImage: `url(${communityLogo})` }}
        to={`/${search}`}
      />

      <div className="main-nav">
        <NavItem
          destination="/map"
          label="Interactive Map"
          Icon={MapNavIcon}
        />
        <NavItem
          destination="/amenities"
          label="Amenities"
          Icon={AmenitiesNavIcon}
        />
        <NavItem
          destination="/floorplans"
          label="Floor Plans"
          Icon={FloorplanNavIcon}
        />
        <NavItem
          destination="/media"
          label="Media Gallery"
          Icon={GalleryNavIcon}
        />
      </div>

      <button
        className="main-nav__toggle"
        onClick={sideNavToggle}
        type="button"
      >
        <span
          className={classNames( 'main-nav__toggleArrow', {
            'main-nav__toggleArrow--active': toggleSidebar,
          } )}
        >
          <span />
          <span />
        </span>
      </button>

      <Personalization salespersonCrmId={salespersonCrmId} />

      { !isKiosk
        && isLoggedIn
        && (
          <div className="sidebar__message">
            You are logged in as
            {' '}
            {salespersonName}
            .
            {' '}
            <button
              onClick={handleLogoutClick}
              className="sidebar__messageCta"
              type="button"
            >
              Log out.
            </button>
          </div>
        )}

      { !isKiosk && (
        <div className="sidebar__message">
          Need help?
          {' '}
          <a
            href="https://yourtour.groovehq.com/help"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar__messageCta"
          >
            View our documentation.
          </a>
        </div>
      )}
    </div>
  );
};

export default NavBar;
