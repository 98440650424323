import React from 'react';

import Cookies from 'universal-cookie';
import { joinNames } from '../../utils/common';

const cookies = new Cookies();

/**
 * Returns formatted personalization string based on the cookie values.
 *
 * @returns string
 */
const PersonalizationStatusMessage = ( { personalizationActive } ) => {
  const resident = cookies.get( 'resident' );
  const names = joinNames(
    resident?.first_name,
    resident?.last_name,
    resident?.person2_first_name,
    resident?.person2_last_name,
    null,
    ' and ',
  );

  return personalizationActive && resident ? (
    <div className="personalization__status">
      Personalized for
      {' '}
      <span>{names}</span>
    </div>
  ) : null;
};

export default PersonalizationStatusMessage;
