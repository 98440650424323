import React, { useState, useContext, useEffect } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import { getNormalizedApiData } from '../../utils/ApiHelpers';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import placeListNormalizeData from './PlaceListNormalizeData';
import PlaceListItem from './PlaceListItem';

const PlaceList = ( { personalizationActive } ) => {
  const { config } = useContext( ConfigContext );
  const { communityNid } = config;
  const [ placeListNodes, setPlaceListNodes ] = useState( [] );

  // Get normalized place data.
  useEffect( () => {
    const fetchData = async () => {
      const request = {
        entityType: 'node',
        contentType: 'place',
        params: {
          sort: 'field_display_order,title',
          include: 'field_cover_photo',
        },
        filter: {
          field_display_on_amenities_page: 1,
        },
      };
      const data = await getNormalizedApiData( request, communityNid, placeListNormalizeData );
      setPlaceListNodes( data );
    };
    if ( communityNid ) {
      fetchData();
    }
  }, [ communityNid ] );

  return (
    <div className="gridContainer">
      { ( !placeListNodes
        || placeListNodes.length === 0 ) && (
          <LoadingSpinner />
      )}

      {placeListNodes?.map( ( node ) => (
        <PlaceListItem
          {...node}
          key={node.uuid}
          uuid={node.uuid}
          personalizationActive={personalizationActive}
        />
      ) )}

    </div>
  );
};

export default PlaceList;
