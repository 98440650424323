import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import FadingContainer from '../FadingContainer/FadingContainer';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ComponentsWrapper from '../ComponentsWrapper/ComponentsWrapper';

import {
  checkIfPageEnabled,
} from '../../AppHelpers';

const AppSwitchboard = ( {
  isAuthed,
  allowLoginAccess,
  isLoggedIn,
  isLoggingIn,
} ) => {
  const [ allowPageAccess, setAllowPageAccess ] = useState( false );
  const [ isWidthError, setIsWidthError ] = useState( false );
  const [ widthCheckAttempted, setWidthCheckAttempted ] = useState( false );
  const [ isAccessError, setIsAccessError ] = useState( false );
  const [ isAuthError, setIsAuthError ] = useState( false );
  const [ accessCheckAttempted, setAccessCheckAttempted ] = useState( false );
  const [ authCheckAttempted, setAuthCheckAttempted ] = useState( false );

  const { config, settings, appStatus, setAppStatus, windowWidth } = useContext( ConfigContext );

  const {
    isPIB,
    isSiteSee,
    isMapTiles,
  } = settings;

  // Is the screen wide enough for the experience?
  useEffect( () => {
    if ( windowWidth && windowWidth <= 786 ) {
      if ( !isPIB && !isMapTiles ) {
        setIsWidthError( true );
      }
    } else {
      setIsWidthError( false );
    }
    setWidthCheckAttempted( true );
  }, [ windowWidth ] );

  // Is this page enabled in the config?
  useEffect( () => {
    // Check that config allows this page to be shown.
    // YT config always has at least one value, so our check to see if it's
    // loaded has to be > 1.
    if ( Object.keys( config ).length > 1 ) {
      const pageAccess = checkIfPageEnabled(
        settings,
        config,
      );
      setAllowPageAccess( pageAccess );
      setAccessCheckAttempted( true );
    } else {
      setAllowPageAccess( false );
    }
  }, [ settings, config ] );

  // If the no login is needed and the experience is authed, set status to
  // operational.
  useEffect( () => {
    if ( !allowLoginAccess && isAuthed ) {
      setIsAuthError( false );
    } else if ( allowLoginAccess && isLoggedIn ) {
      setIsAuthError( false );
    } else if ( allowLoginAccess && !isLoggedIn && isLoggingIn ) {
      setIsAuthError( false );
    } else {
      setIsAuthError( true );
    }
    setAuthCheckAttempted( true );
  }, [
    allowLoginAccess, isAuthed, isLoggedIn, isLoggingIn,
  ] );

  const thing = [
    widthCheckAttempted,
    authCheckAttempted,
    accessCheckAttempted,
  ];

  // Do we need to show an access error?
  useEffect( () => {
    setIsAccessError( !allowPageAccess );
  }, [ allowPageAccess ] );

  // What status is the app in?
  useEffect( () => {
    if (
      widthCheckAttempted
      && authCheckAttempted
      && accessCheckAttempted
    ) {
      if ( isWidthError
        || isAuthError
        || isAccessError
      ) {
        setAppStatus( 'error' );
      } else if ( isLoggingIn ) {
        setAppStatus( 'loading' );
      } else {
        setAppStatus( 'operational' );
      }
    }
  }, [
    widthCheckAttempted,
    isWidthError,
    authCheckAttempted,
    isAuthError,
    accessCheckAttempted,
    isAccessError,
    isLoggingIn,
  ] );

  let component = <Loading />;
  if ( widthCheckAttempted && authCheckAttempted && accessCheckAttempted ) {
    switch ( appStatus ) {
      case 'loading': component = <Loading />; break;
      case 'error': component = (
        <Error
          isWidthError={isWidthError}
          isAuthError={isAuthError}
          isAccessError={isAccessError}
        />
      );
        break;
      case 'operational': component = <ComponentsWrapper />; break;
      default: component = <Loading />; break;
    }
  }

  return (
    <FadingContainer>
      {component}
    </FadingContainer>
  );
};

const Error = ( {
  isWidthError,
  isAuthError,
  isAccessError,
} ) => {
  let message = 'Error';
  if ( isWidthError ) {
    message = (
      <p>
        This experience requires a window width of 768px or greater.
        Please increase the size of your window or view on a larger
        device.
      </p>
    );
  } else if ( isAuthError ) {
    message = (
      <p>
        You are not authorized to use YourTour.
        {' '}
        If you feel this is in error, please contact The Vectre at
        {' '}
        <a href="mailto:support@thevectre.com">support@thevectre.com</a>
        .
      </p>
    );
  } else if ( isAccessError ) {
    message = (
      <p>
        You do not have access to this page.
        {' '}
        If you feel this is in error, please contact The Vectre at
        {' '}
        <a href="mailto:support@thevectre.com">support@thevectre.com</a>
        .
      </p>
    );
  }
  return (
    <div className="content content--center">
      <div className="noAppMessage">
        { message }
      </div>
    </div>
  );
};

const Loading = () => (
  <div className="content content--center">
    <div className="noAppMessage">
      <LoadingSpinner />
    </div>
  </div>
);

export default AppSwitchboard;
