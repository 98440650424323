import React, {
  useContext, useEffect, useRef, useState,
} from 'react';

import MapContext from '../../MapPage/MapContext';

import MapControlsZoom from './MapControlsZoom/MapControlsZoom';
import MapControlsMapTypeToggle from './MapControlsMapTypeToggle/MapControlsMapTypeToggle';
import MapControlsPOIToggle from './MapControlsPOIToggle/MapControlsPOIToggle';
import MapControlsMarkersToggle from './MapControlsMarkersToggle/MapControlsMarkersToggle';
import SiteSeeTipTrigger from '../../SiteSee/SiteSeeGuides/SiteSeeTipTrigger';

import './MapControls.scss';

const MapControls = ( {
  setActiveZoom,
  activeZoom,
  zoomSettings,
  setMarkersVisible,
  markersToggleVisible,
  siteSeeGuideId,
  setSiteSeeGuideId,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
  setSiteSeeModalDims
} ) => {
  const [ enablePOIToggle, setEnablePOIToggle ] = useState( false );

  const {
    mapData,
  } = useContext( MapContext );

  const {
    googleMapsConfig,
  } = mapData;

  useEffect( () => {
    if ( googleMapsConfig ) {
      const { enablePOI } = googleMapsConfig;
      if ( enablePOI ) {
        setEnablePOIToggle( true );
      }
    }
  }, [ googleMapsConfig ] );

  // Pass dims back to sitesee guide.
  const mapControlsRef = useRef( null );
  const currentMapControlsRef = mapControlsRef.current;
  useEffect( () => {
    if ( currentMapControlsRef
      && ( siteSeeGuideId === 2 || siteSeeGuideId === 'controls' )
    ) {
      setSiteSeeModalDims( currentMapControlsRef.getBoundingClientRect() );
    }
  }, [ siteSeeGuideId, currentMapControlsRef ] );

  return (
    <div className="mapControls__wrapper">

      <div className="mapControls" ref={mapControlsRef}>

        <MapControlsZoom
          setActiveZoom={setActiveZoom}
          activeZoom={activeZoom}
          zoomSettings={zoomSettings}
          siteSeeGuideId={siteSeeGuideId}
          setSiteSeeGuideId={setSiteSeeGuideId}
          siteSeeGuideMode={siteSeeGuideMode}
        />

        <MapControlsMapTypeToggle />

        { enablePOIToggle
          && <MapControlsPOIToggle /> }

        <MapControlsMarkersToggle
          setMarkersVisible={setMarkersVisible}
          markersToggleVisible={markersToggleVisible}
        />

      </div>

      <SiteSeeTipTrigger
        siteSeeGuideId={siteSeeGuideId}
        setSiteSeeGuideId={setSiteSeeGuideId}
        setShowSiteSeeGuide={setShowSiteSeeGuide}
        siteSeeGuideMode={siteSeeGuideMode}
        siteSeeTipId="controls"
      />

    </div>
  );
};

export default MapControls;
