import React, { useEffect, useState } from 'react';

import PaneButton from './PaneButton';
import Pane from './Pane';

import './PanesWidget.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const PanesWidget = ( { panesData } ) => {
  const [ activePaneId, setActivePaneId ] = useState( null );
  const [ activePaneData, setActivePaneData ] = useState( null );

  // set initial active pane id onload
  useEffect( () => {
    if ( panesData && panesData.length ) {
      const initialPaneData = panesData[0];
      const rawActivePaneId = initialPaneData?.id;
      setActivePaneId( rawActivePaneId );
    }
  }, [ panesData ] );

  // when active pane id changes, change active pane data
  useEffect( () => {
    if ( activePaneId ) {
      const paneDataWithSameId = panesData.find( ( paneData ) => paneData.id === activePaneId );
      setActivePaneData( paneDataWithSameId );
    }
  }, [ activePaneId, panesData ] );

  const paneButtonsClass = panesData?.length <= 1 ? 'paneButtons--hidden' : '';

  return (
    <div className="panesWidget">
      { panesData && panesData.length
        ? (
          <>
            <div className={`paneButtons ${paneButtonsClass}`}>
              {panesData && panesData.map( ( rawPaneData ) => (
                <PaneButton
                  key={rawPaneData.id}
                  paneDataId={rawPaneData.id}
                  btnText={rawPaneData.btnText}
                  activePaneId={activePaneId}
                  setActivePaneId={setActivePaneId}
                />
              ) )}
            </div>

            <div className="panesWidget-frame">
              { activePaneData
              && (
              <Pane
                paneData={activePaneData.paneData}
                subpaneComponentName={activePaneData.subpaneComponentName}
                stickyMarkup={activePaneData.stickyMarkup}
              />
              )}
            </div>
          </>
        )
        : (
          <div className="panesWidget-loading">
            <LoadingSpinner />
          </div>
        )}
    </div>
  );
};

export default PanesWidget;
