import React, {
  useState, useRef, useEffect, useLayoutEffect, useContext,
} from 'react';

import MapContext from '../../MapPage/MapContext';
import ZoomZooneIcon from './ZoomZoneIcon';

const ZoomZone = ( {
  zoomZone,
  siteSeeGuideId,
  setSiteSeeGuideId,
  siteSeeGuideMode,
} ) => {
  const [ zoomZoneX, setZoomZoneX ] = useState( 0 );
  const [ zoomZoneY, setZoomZoneY ] = useState( 0 );
  const [ textX, setTextX ] = useState( 0 );
  const [ textY, setTextY ] = useState( 0 );
  const [ finalTextX, setFinalTextX ] = useState( 0 );
  const [ finalTextY, setFinalTextY ] = useState( 0 );
  const [ textWidth, setTextWidth ] = useState( 0 );
  const [ textHeight, setTextHeight ] = useState( 0 );
  const [ bgX, setBgX ] = useState( 0 );
  const [ bgY, setBgY ] = useState( 0 );
  const [ bgWidth, setBgWidth ] = useState( 0 );
  const [ bgHeight, setBgHeight ] = useState( 0 );
  const [ labelScale, setLabelScale ] = useState( 1 );

  const labelTextRef = useRef();
  const labelBgRef = useRef();
  // We intentionally had to use Ref instead of states.
  const clickListenerEnabledRef = useRef( false );

  const {
    initialOverlayWidth,
    currentOverlayWidth,
    mapZoomToEnableLevels,
    setActiveZoom,
    mapInstance,
  } = useContext( MapContext );

  const {
    center,
    name,
    orientation,
  } = zoomZone;

  const {
    x, y,
  } = center;

  const offset = 10;
  const horiPadding = 15;
  const vertPadding = 7.5;
  const iconDiameter = 43; // From zoom icon svg.
  const circleRadius = iconDiameter / 2;

  const handleZoomZoneClicked = () => {
    clickListenerEnabledRef.current = true;
  };

  // how much do we need to scale the label?
  useLayoutEffect( () => {
    if ( initialOverlayWidth && currentOverlayWidth ) {
      const ratio = initialOverlayWidth / currentOverlayWidth;
      setLabelScale( ratio );
    }
  }, [ initialOverlayWidth, currentOverlayWidth ] );

  // Get calculated cords for pin position.
  useLayoutEffect( () => {
    if ( x && y ) {
      // Make zoom center account for icon size so the center of the icon is at
      // the center x, y coords.
      const scaledCircleRadius = circleRadius;
      const scaledX = x / labelScale;
      const scaledY = y / labelScale;
      const rawX = scaledX - scaledCircleRadius;
      const rawY = scaledY - scaledCircleRadius;
      setZoomZoneX( rawX );
      setZoomZoneY( rawY );
    }
  }, [
    x,
    y,
    labelScale,
  ] );

  // Calculate text x y, taking in to account pin orientation and padding for
  // offset.
  // These are things we can calculate without knowing the true size of the text.
  useLayoutEffect( () => {
    let rawTextX = x / labelScale;
    let rawTextY = y / labelScale;

    switch ( orientation ) {
      case 'left':
        rawTextX = rawTextX - horiPadding - offset - circleRadius;
        break;
      case 'right':
        rawTextX = circleRadius + offset + horiPadding + rawTextX;
        break;
      case 'top':
        rawTextY = rawTextY - offset - circleRadius - vertPadding;
        break;
      case 'bottom':
        rawTextY = circleRadius + offset + vertPadding + rawTextY;
        break;
      default:
        break;
    }
    setTextX( rawTextX );
    setTextY( rawTextY );
  }, [
    x,
    y,
    zoomZoneX,
    zoomZoneY,
    labelScale,
    orientation,
  ] );

  // Get live text box width and height.
  useEffect( () => {
    if ( labelTextRef.current ) {
      const { width, height } = labelTextRef.current.getBBox();
      setTextWidth( width );
      setTextHeight( height );
    }
  }, [ labelTextRef ] );

  // Calculate text position once we know text size.
  useLayoutEffect( () => {
    let rawFinalTextX = textX;
    let rawFinalTextY = textY;

    switch ( orientation ) {
      case 'left':
        rawFinalTextX -= textWidth;
        rawFinalTextY -= ( textHeight / 2 );
        break;
      case 'right':
        rawFinalTextY -= ( textHeight / 2 );
        break;
      case 'top':
        rawFinalTextX -= ( textWidth / 2 );
        rawFinalTextY -= textHeight;
        break;
      case 'bottom':
        rawFinalTextX -= ( textWidth / 2 );
        break;
      default:
        break;
    }
    setFinalTextX( rawFinalTextX );
    setFinalTextY( rawFinalTextY );
  }, [
    textX,
    textY,
    textWidth,
    textHeight,
    orientation,
  ] );

  // Calculate label position and label bg once we know text label size.
  useLayoutEffect( () => {
    const rawBgHeight = textHeight + ( vertPadding * 2 );
    const rawBgWidth = textWidth + ( horiPadding * 2 );

    let rawBgX = finalTextX;
    let rawBgY = finalTextY;

    switch ( orientation ) {
      case 'left':
        rawBgY -= vertPadding;
        rawBgX -= horiPadding;
        break;
      case 'right':
        rawBgY -= vertPadding;
        rawBgX -= horiPadding;
        break;
      case 'top':
        rawBgX -= horiPadding;
        rawBgY -= vertPadding;
        break;
      case 'bottom':
        rawBgX -= horiPadding;
        rawBgY -= vertPadding;
        break;
      default:
        break;
    }

    setBgX( rawBgX );
    setBgY( rawBgY );
    setBgHeight( rawBgHeight );
    setBgWidth( rawBgWidth );
  }, [
    labelScale,
    textWidth,
    textHeight,
    finalTextX,
    finalTextY,
    orientation,
  ] );

  useEffect( () => {
    if ( mapInstance ) {
      mapInstance.addListener( 'click', ( event ) => {
        setTimeout( () => {
          // The timeout is required because the click on the map is getting
          // called before zoomZoneClicked.
          if ( !clickListenerEnabledRef.current ) {
            return;
          }
          setActiveZoom( mapZoomToEnableLevels );
          const location = new window.google.maps.LatLng( event.latLng.lat(), event.latLng.lng() );
          mapInstance.setCenter( location );
          clickListenerEnabledRef.current = false;

          // While in SiteSee guide tour, if the user decides to interact with the map by
          // using the zoom in button in the Map Controls toolbar or clicking a Zoom
          // Zone button triggering the detailed view, Tip 1 would fade out, Tips 2
          // and 3 would automatically be skipped, and Tip 4 would fade in.
          if ( siteSeeGuideMode === 'tour' && siteSeeGuideId < 4 ) {
            setSiteSeeGuideId( 4 );
          }
        }, 0 );
      } );
    }
  }, [ mapInstance, clickListenerEnabledRef ] );

  return (
    <g
      className="zMarker individual-zoom-zone"
      onClick={handleZoomZoneClicked}
      style={{
        transform: `scale(${labelScale})`,
      }}
    >
      <ZoomZooneIcon
        x={zoomZoneX}
        y={zoomZoneY}
      />

      <rect
        rx="8"
        ry="8"
        x={bgX}
        y={bgY}
        width={bgWidth}
        height={bgHeight}
        className="zMarker__bg zMarker__visible"
        ref={labelBgRef}
      />

      <text
        x={finalTextX || textX}
        y={finalTextY || textY}
        className={`zMarker__text zMarker__text--${orientation} zMarker__visible`}
        ref={labelTextRef}
      >
        {/*
          baseline isn't supported across all browers
          so we have to fake it with an empty starting tspan and
          dy in following tspan
        */}
        <tspan />
        <tspan dy="1em">
          {name}
        </tspan>
      </text>
    </g>
  );
};

export default ZoomZone;
