const labels = '#5b727e';
const GoogleMapHybridStyle = [
  {
    featureType: 'administrative.land_parcel',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'landscape',
    stylers: [ {
      color: '#f2f3ee',
    } ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.fill',
    stylers: [ {
      color: labels,
    } ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'labels',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [ {
      color: '#d6d6d6',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [ {
      color: '#979b9b',
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [ {
      lightness: 15,
    } ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'transit',
    stylers: [ {
      visibility: 'off',
    } ],
  },
  {
    featureType: 'water',
    stylers: [ {
      color: '#b1bebf',
    } ],
  },
];

export default GoogleMapHybridStyle;
