import React, { useEffect, useRef, useState } from 'react';

import SubpaneButton from './SubpaneButton';
import Subpane from './Subpane';

import { ReactComponent as DropdownCaret } from '../../SVGAssets/DropdownCaret.svg';
import { ReactComponent as LeftRightArrow } from '../../SVGAssets/LeftRightArrow.svg';

const Pane = ( {
  paneData,
  subpaneComponentName,
  stickyMarkup,
} ) => {
  const [ activeSubpaneId, setActiveSubpaneId ] = useState( null );
  const [ activeSubpaneData, setActiveSubpaneData ] = useState( null );
  const [ showSubpaneNavBtns, setShowSubpaneNavBtns ] = useState( false );
  const [ scrollerPaneWidth, setScrollerPaneWidth ] = useState( null );
  const [ scrollerWindowWidth, setScrollerWindowWidth ] = useState( null );
  const [ showSubpaneBtnsScroller, setShowSubpaneBtnsScroller ] = useState( false );
  const [ scrollerPaneTranslateX, setScrollerPaneTranslateX ] = useState( 0 );
  const [ leftArrowIsActive, setLeftArrowIsActive ] = useState( false );
  const [ rightArrowIsActive, setRightArrowIsActive ] = useState( false );

  const windowWidth = window.innerWidth || 0;

  // set initial active subpane id onload
  useEffect( () => {
    if ( paneData?.length > 0 ) {
      const initialSubpaneData = paneData[0];
      const activeSubpaneId = initialSubpaneData.id;
      setActiveSubpaneId( activeSubpaneId );
    } else {
      setActiveSubpaneId( null );
    }
  }, [ paneData ] );

  // when active subpane id changes, change active subpane data
  useEffect( () => {
    if ( activeSubpaneId && paneData) {
      const subpaneDataWithSameId = paneData.find(
        ( subpaneData ) => subpaneData.id === activeSubpaneId
      );
      setActiveSubpaneData( subpaneDataWithSameId );
      setScrollerPaneTranslateX( 0 );
    } else {
      setActiveSubpaneData( null );
    }
  }, [ activeSubpaneId, paneData ] );

  // when window width changes, hide/show subpaneNavButtons
  useEffect( () => {
    if ( windowWidth >= 768 ) {
      setShowSubpaneNavBtns( true );
    } else {
      setShowSubpaneNavBtns( false );
    }
  }, [ windowWidth ] );

  // when window width changes, get width of scrollerPane
  const scrollerPaneRef = useRef();
  useEffect( () => {
    if ( scrollerPaneRef.current && windowWidth >= 768 ) {
      // the divider is made with a 2px after element
      let rawScrollerPaneWidth = 2;
      const btns = scrollerPaneRef.current.children;

      // add up width of buttons to get width of scrollerPane
      if ( btns.length > 0 ) {
        for ( const btn of btns ) {
          rawScrollerPaneWidth += btn.scrollWidth;
        }
      }
      setScrollerPaneWidth( rawScrollerPaneWidth );
    } else {
      setScrollerPaneWidth( null );
    }
  }, [
    windowWidth, scrollerPaneRef, scrollerPaneRef.current, activeSubpaneId,
  ] );

  // when window width changes, get width of scrollerWindow
  // scrollerTriggerRef is the width of the parent container minus the next and previous buttons
  const scrollerWindowRef = useRef();
  useEffect( () => {
    if ( scrollerWindowRef.current && windowWidth >= 768 ) {
      const scrollerWindowWidth = scrollerWindowRef.current.clientWidth;
      setScrollerWindowWidth( scrollerWindowWidth );
    }
  }, [
    windowWidth,
    scrollerWindowRef,
    scrollerWindowRef.current,
    showSubpaneBtnsScroller,
    activeSubpaneId,
  ] );

  // use scroller if scrollerPane is wider than scrollerWindow
  // scrollerTriggerRef is the width of the parent container
  const scrollerTriggerRef = useRef();
  useEffect( () => {
    if ( scrollerPaneWidth > scrollerTriggerRef.current.clientWidth ) {
      setShowSubpaneBtnsScroller( true );
    } else {
      setShowSubpaneBtnsScroller( false );
    }
  }, [
    scrollerPaneWidth, scrollerTriggerRef.current, activeSubpaneId,
  ] );

  // make right arrow active on load or pane change if the scroller is visible
  // reset scroller pane
  useEffect( () => {
    if ( showSubpaneBtnsScroller ) {
      setScrollerPaneTranslateX( 0 );
      setRightArrowIsActive( true );
      setLeftArrowIsActive( false );
    }
  }, [ showSubpaneBtnsScroller, activeSubpaneId ] );

  const handleToggleClick = () => {
    // change to the opposite of whatever it currently is
    setShowSubpaneNavBtns( !showSubpaneNavBtns );
  };

  const handlePreviousBtnClick = () => {
    // get current scrollerPane tranformX
    const rawTransform = scrollerPaneRef.current.style.transform;
    const transformValue = rawTransform.replace( /\D/g, '' );
    const translateX = ( parseInt( transformValue, 10 ) );

    // how much is overflowing to the left?
    const leftOverflow = translateX;

    let rawScrollerPaneTranslateX = 0;

    if ( leftOverflow >= scrollerWindowWidth ) {
      // rewind scrollPane same width as scrollerWindow
      rawScrollerPaneTranslateX = scrollerWindowWidth - translateX;
    } else {
      // rewind scrollPane to start
      rawScrollerPaneTranslateX = 0;
      setLeftArrowIsActive( false );
    }
    setScrollerPaneTranslateX( rawScrollerPaneTranslateX );

    // since we've shifted the content, the previous button is now legit
    setRightArrowIsActive( true );
  };

  const handleNextBtnClick = () => {
    // get current scrollerPane tranformX
    const rawTransform = scrollerPaneRef.current.style.transform;
    const transformValue = rawTransform.replace( /\D/g, '' );
    const translateX = -( parseInt( transformValue, 10 ) );

    // how much is overflowing to the right?
    // there's an after 2px after element we want to ignore
    const rightOverflow = ( scrollerPaneWidth + translateX ) - scrollerWindowWidth - 2;

    let rawScrollerPaneTranslateX = 0;
    if ( rightOverflow >= scrollerWindowWidth ) {
      // advance scrollPane same width as scrollerWindow
      rawScrollerPaneTranslateX  = translateX - scrollerWindowWidth;
    } else {
      // advance scrollPane width of remaining overflow
      rawScrollerPaneTranslateX = translateX - rightOverflow;
      setRightArrowIsActive( false );
    }
    setScrollerPaneTranslateX( rawScrollerPaneTranslateX );

    // since we've shifted the content, the previous button is now legit
    setLeftArrowIsActive( true );
  };

  const toggleClass = showSubpaneNavBtns ? 'subpaneButtons-toggle__button subpaneButtons-toggle__button--active' : 'subpaneButtons-toggle__button';
  const subpaneButtonsClass = showSubpaneBtnsScroller ? 'subpaneButtons subpaneButtons--scroller' : 'subpaneButtons';
  const subpaneButtonsNavClass = showSubpaneBtnsScroller ? 'subpaneButtons-nav subpaneButtons-nav--scroller' : 'subpaneButtons-nav';
  const subpaneButtonsPreviousClass = leftArrowIsActive ? 'subpaneButtons-previous subpaneButtons-previous--active' : 'subpaneButtons-previous';
  const subpaneButtonsNextClass = rightArrowIsActive ? 'subpaneButtons-next subpaneButtons-next--active' : 'subpaneButtons-next';

  return (
    <div className="pane">

      <div
        ref={scrollerTriggerRef}
        className={subpaneButtonsClass}
      >

        { activeSubpaneData && paneData?.length > 1
          && (
          <div className="subpaneButtons-toggle">
            <div className="subpaneButtons-toggle__currentSubpaneTitle">{activeSubpaneData.title}</div>
            <button
              type="button"
              className={toggleClass}
              onClick={handleToggleClick}>
              <DropdownCaret className="subpaneButtons-toggle__button__caret" />
            </button>
          </div>
          )}

        { showSubpaneBtnsScroller && paneData?.length > 1
          && (
          <button
            type="button"
            onClick={handlePreviousBtnClick}
            className={subpaneButtonsPreviousClass}
          >
            <LeftRightArrow className="subpaneButtons-previous__arrow" />
          </button>
          )}

        <div
          ref={scrollerWindowRef}
          className={subpaneButtonsNavClass}
        >

          <div
            ref={scrollerPaneRef}
            className="subpaneButtons-nav__scrollerPane"
            style={{
              width: scrollerPaneWidth,
              transform: `translateX(${scrollerPaneTranslateX}px)`,
            }}
          >

            { showSubpaneNavBtns && paneData?.length > 1 && paneData.map( ( subpaneData ) => (
              <SubpaneButton
                key={subpaneData.id}
                subpaneDataId={subpaneData.id}
                btnText={subpaneData.title}
                activeSubpaneId={activeSubpaneId}
                setActiveSubpaneId={setActiveSubpaneId}
                setShowSubpaneNavBtns={setShowSubpaneNavBtns}
              />
            ) )}

          </div>
        </div>

        { showSubpaneBtnsScroller
          && (
          <button
            type="button"
            onClick={handleNextBtnClick}
            className={subpaneButtonsNextClass}
          >
            <LeftRightArrow className="subpaneButtons-next__arrow" />
          </button>
          )}

      </div>

      {activeSubpaneData
        && (
        <Subpane
          subpaneData={activeSubpaneData}
          subpaneComponentName={subpaneComponentName}
        />
        )}

      { stickyMarkup }

    </div>
  );
};

export default Pane;
