import React, { useState } from 'react';

import Modal from './Modal';

const ModalTriggerButton = ( {
  uuid,
  nodeType,
  triggerType,
  markup,
  modalInfo,
} ) => {
  const [ showModal, setShowModal ] = useState( false );

  const handleModalShow = () => setShowModal( true );
  const handleModalHide = () => setShowModal( false );

  let triggerMarkup = (
    <button
      type="button"
      onClick={handleModalShow}
    >
      {markup}
    </button>
  );

  if ( triggerType && triggerType === 'div' ) {
    triggerMarkup = (
      <a
        className="gridItemTrigger"
        onClick={handleModalShow}>
        {markup}
      </a>
    );
  }

  return (
    <>
      {triggerMarkup}

      { showModal && (
        <Modal
          nodeType={nodeType}
          uuid={uuid}
          showDescription={false}
          modalInfo={modalInfo}
          handleModalHide={handleModalHide}
        />
      )}
    </>
  );
};

export default ModalTriggerButton;
