import React, { useContext, useEffect, useState } from 'react';

import FadingContainer from '../FadingContainer/FadingContainer';
import { assembleFloorPlanNavBtnsData, createBedBathSqFtPricingTableData } from './FloorPlanHelpers';

import DetailTableInfo from '../Detail/DetailTable';
import DetailHeader from '../Detail/DetailHeader';
import FloorPlanImagesWidget from './FloorPlanImagesWidget';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import VideoDetail from '../Video/VideoDetail';
import FinishCollection from '../FinishCollection/FinishCollection';
import FurnitureConfigurator from '../FurnitureConfigurator/FurnitureConfigurator';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import '../Detail/Detail.scss';
import './FloorPlan.scss';

const FloorPlan = ( { data } ) => {
  const {
    title,
    levelsOfCareName,
    floorPlanCategoryName,
    bed,
    bath,
    studio,
    sqft,
    roomFees,
    description,
    floorplan2d,
    floorplan3d,
    downloadablePdf,
    roomData,
    threeSixties,
    finishCollection,
    furnitureConfiguratorUrl,
    video,
    photoGalleryImages,
  } = data;

  const [ showPricing, setShowPricing ] = useState( null );
  const { featureFlags, isKiosk, config, setUnitHasDisplayableFees } = useContext( ConfigContext );
  const { isPIB } = config;

  // If a unit has pricing data and can show that data, set context to show
  // that the unit has fees.
  if ( roomFees.length && showPricing ) {
    setUnitHasDisplayableFees( true );
  } else {
    setUnitHasDisplayableFees( false );
  }

  // Should we show pricing? This won't be on /map-embeds so we don't need to
  // check for it.
  useEffect( () => {
    let show = false;
    if ( isKiosk ) {
      show = featureFlags?.includes( 'kiosk_show_pricing' );
    } else if ( isPIB ) {
      show = featureFlags?.includes( 'pib_show_pricing' );
    } else {
      show = featureFlags?.includes( 'yt_show_pricing' );
    }
    setShowPricing( show );
  }, [
    isKiosk, isPIB, featureFlags,
  ] );

  const Header = () => {
    let header = null;

    // Assemble location and category info.
    let subtitleString = '';
    if ( levelsOfCareName ) {
      subtitleString = levelsOfCareName;
    }
    if ( levelsOfCareName && floorPlanCategoryName ) {
      subtitleString += ' ';
    }
    if ( floorPlanCategoryName ) {
      subtitleString += floorPlanCategoryName;
    }
    const headerText = {
      title,
      subtitle: subtitleString,
    };

    const navBtns = assembleFloorPlanNavBtnsData( data );

    header = (
      <DetailHeader
        text={headerText}
        navBtns={navBtns}
        navClass="detailNav--centered"
      />
    );

    return header;
  };

  const BedBathSqFtPricingTable = () => {
    let details = null;
    const tableInfo = createBedBathSqFtPricingTableData( data, showPricing );
    if ( ( tableInfo && tableInfo.length ) || description ) {
      details = (
        <DetailTableInfo
          id="info-scroll"
          tableInfo={tableInfo}
          tableDescription={description}
          className="detailSection"
        />
      );
    }

    return details;
  };

  return (
    <FadingContainer>
      <div>
        <Header />

        <BedBathSqFtPricingTable />

        <FloorPlanImagesWidget
          className="detailSection"
          threeD={floorplan3d?.modal}
          twoD={floorplan2d?.modal}
          name={title}
          downloadablePdf={downloadablePdf}
        />

        {roomData && roomData.length > 0 && (
          <DetailTableInfo
            id="dimensions-scroll"
            className="detailSection"
            tableInfo={roomData}
          />
        )}

        {photoGalleryImages && photoGalleryImages.length > 0 && (
          <PhotoGalleryDetail
            id="slideshow-scroll"
            uuid={null}
            images={photoGalleryImages}
            galleryClass="slideshow detailSection detailSection--gallery"
            imageClass="slideshowPhoto"
          />
        )}

        {video && video.service && video.videoId && (
          <VideoDetail
            hasId
            videoId={video.videoId}
            service={video.service}
            autoplay={false}
            className="detailSection"
          />
        )}

        {threeSixties && threeSixties.length > 0 && (
          <VirtualTourDetail
            id="tour-scroll"
            uuid={null}
            threeSixtiesData={threeSixties}
            className="detailSection modalSection--sixteen9"
          />
        )}

        {furnitureConfiguratorUrl && (
          <FurnitureConfigurator
            id="configurator-scroll"
            url={furnitureConfiguratorUrl}
            className="detailSection modalSection--sixteen9 modalSection--border"
          />
        )}

        <FinishCollection
          id="finishes-scroll"
          finishCollection={finishCollection}
          className="detailSection"
        />

      </div>
    </FadingContainer>
  );
};

export default FloorPlan;
