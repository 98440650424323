export function processUnserializedThreeSixties( threeSixtiesData, drupalIncludedById ) {
  const threeSixties = [];

  threeSixtiesData.forEach( ( item ) => {
    const vtType = drupalIncludedById[item.id]?.attributes?.field_vt_service;
    const threeSixty = {
      type: vtType,
      uuid: item.id,
      buttonText: drupalIncludedById[item.id]?.attributes?.field_button_text,
    };

    const nid = item?.meta?.drupal_internal__target_id;
    const nidUrl = `${process.env.REACT_APP_YOUR_TOUR_CMS_URL}/pano/${nid}`;
    const otherUrl = drupalIncludedById[item.id]?.attributes?.field_url;
    threeSixty.url = otherUrl && otherUrl !== '' ? otherUrl : nidUrl;
    threeSixties.push( threeSixty );
  } );

  return threeSixties;
}

export default function virtualTourDetailNormalizeData( responseData, apiUrl ) {
  const drupalData = responseData.data;
  const drupalIncluded = responseData.included;
  let threeSixties = [];

  const drupalIncludedById = {};
  if ( drupalIncluded ) {
    // change included array into object with id as property name
    // so we can retrieve them by their id later
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  if ( drupalData ) {
    const threeSixtiesData = drupalData.relationships.field_three_sixties.data;
    threeSixties = processUnserializedThreeSixties( threeSixtiesData, drupalIncludedById, apiUrl );
  }

  return threeSixties;
}

export function processSerializedThreeSixties( threeSixtiesData ) {
  const threeSixties = [];

  if ( !threeSixtiesData ) {
    return threeSixties;
  }

  threeSixtiesData.forEach( ( item ) => {
    const threeSixty = {
      type: item.fieldVtService,
      uuid: item.id,
      buttonText: item.fieldButtonText,
    };
    const nid = item.drupalInternalNid;
    const nidUrl = `${process.env.REACT_APP_YOUR_TOUR_CMS_URL}/pano/${nid}`;
    const otherUrl = item.fieldUrl;
    threeSixty.url = otherUrl && otherUrl !== '' ? otherUrl : nidUrl;
    threeSixties.push( threeSixty );
  } );

  return threeSixties;
}
