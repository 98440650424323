/* eslint-disable import/prefer-default-export */
import { calcPathCenterCoords } from '../MapPage/GoogleMap/helpers';
import { findValueByPrefix } from '../../utils/common';

export function mapSvgNormalizeData( rawData ) {
  const data = rawData?.map_data;

  // Loop through levels.
  const levels = data?.levels;
  if ( levels && Object.keys( levels ).length > 0 ) {
    Object.keys( levels ).forEach( ( levelKey ) => {
      const rawLevel = levels[levelKey];
      const level = {};

      // Loop through units.
      const rawUnits = findValueByPrefix( rawLevel, 'units' );
      level.units = {};
      if ( rawUnits && Object.keys( rawUnits ).length > 0 ) {
        Object.keys( rawUnits ).forEach( ( unitKey ) => {
          const rawUnit = rawUnits[unitKey];
          const unit = rawUnit;

          // Calculate unit label info.

          // Get center of shape for label position.
          let textAttr = {};
          if ( unit ) {
            if ( unit.type === 'path' ) {
              // I want to move this to the PHP side, but the calcs are
              // complicated and replicating the functionality in PHP will take
              // a while.
              textAttr = calcPathCenterCoords( unit.attributes.d );
            } else if ( unit.center ) {
              textAttr = unit.center;
            }
          }

          // Set text size.
          textAttr.style = {};
          textAttr.style.fontSize = unit.info?.building_grouping?.text_size;
          unit.label = textAttr;

          // Start the unit as disabled.
          unit.displayMode = 'disabled';

          level.units[unitKey] = unit;
        } );
      }

      // Loop through places.
      const rawPlaces = findValueByPrefix( rawLevel, 'places' );
      level.places = rawPlaces;
      if ( rawPlaces && Object.keys( rawPlaces ).length > 0 ) {
        Object.keys( rawPlaces ).forEach( ( placeKey ) => {
          const rawPlace = rawPlaces[placeKey];
          const place = rawPlace;

          // Get center of shape for pin position.
          let center = {};
          if ( place ) {
            if ( place.type === 'path' ) {
              // I want to move this to the PHP side, but the calcs are
              // complicated and replicating the functionality in PHP will take
              // a while.
              center = calcPathCenterCoords( place.attributes.d );
            } else if ( place.center ) {
              center = place.center;
            }
          }
          place.center = center;

          level.places[placeKey] = place;
        } );
      }

      // Loop throug other.
      const rawOther = findValueByPrefix( rawLevel, 'other' );
      level.other = rawOther;

      // Replace existing level with new normalized level.
      levels[levelKey] = level;
    } );
  }

  return data;
}
