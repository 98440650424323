export default function placeListNormalizeData( responseData ) {
  const nodes = [];
  const drupalData = responseData.data;
  const drupalIncluded = responseData.included;

  // Change included array into object with id as property name
  // so we can retrieve them by their id later.
  const drupalIncludedById = {};
  if ( drupalIncluded && drupalIncluded.length ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  if ( drupalData && drupalData.length ) {
    drupalData.forEach( ( item, index ) => {
      const node = {
        uuid: item.id,
        title: item.attributes.title,
        description: item.attributes.field_description,
      };

      const coverImageFieldData = drupalData[index].relationships.field_cover_photo.data;
      if ( coverImageFieldData ) {
        node.coverImage = coverImageFieldData.meta.imageDerivatives?.links.one_third_screen.href;
      }

      nodes.push( node );
    } );
  }

  return nodes;
}
