import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MapContext from '../../MapContext';
import ConfigContext from '../../../../utils/ConfigContext/ConfigContext';

import Accordion from '../../Accordion/Accordion';
import CategoryButtons from './CategoryButtons';

const CategoryButtonsList = ( {
  mapFilterData, goToNextPanel, goToPrevPanel, hasActiveFilters,
} ) => {
  const {
    isKiosk,
    featureFlags,
  } = useContext( ConfigContext );

  const {
    activeLevelOfCareUuid,
    setActiveFilterSelections,
    isSiteSee,
  } = useContext( MapContext );

  const categoryLabels = {
    type: 'Types',
    bedrooms: 'Bedrooms',
    location: 'Locations',
    floor_plan: 'Floor Plans',
    occupancy: 'Occupancy',
  };

  // eslint-disable-next-line space-in-parens
  if ( isSiteSee || ( isKiosk && !featureFlags?.includes( 'kiosk_show_occupancy' ))) {
    delete categoryLabels.occupancy;
  }

  const handleClearAllFilters = () => {
    // Set active selections to an empty object.
    setActiveFilterSelections( {} );
  };

  if ( !mapFilterData || !activeLevelOfCareUuid ) {
    return false;
  }

  return (
    <Accordion
      title="Back to Levels of Care"
      createBackButton
      goToPrevPanel={goToPrevPanel}
    >

      {mapFilterData.hasOwnProperty( activeLevelOfCareUuid ) && Object.keys( mapFilterData[activeLevelOfCareUuid].children ).map( ( categoryKey ) => {
        if ( categoryLabels[categoryKey] ) {
          return (
            <CategoryButtons
              categoryLabel={categoryLabels[categoryKey]}
              category={categoryKey}
              key={categoryKey}
              goToNextPanel={goToNextPanel}
            />
          );
        }
      } )}

      { hasActiveFilters && (
        <button className="mapFilter--clear-filters type-cta-md" onClick={() => handleClearAllFilters()}>Clear all filters</button>
      )}

    </Accordion>
  );
};

CategoryButtonsList.propTypes = {
  mapFilterData: PropTypes.object.isRequired,
  goToNextPanel: PropTypes.func.isRequired,
  goToPrevPanel: PropTypes.func.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
};

export default CategoryButtonsList;
