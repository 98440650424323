import React, {
  useCallback, useEffect, useState,
} from 'react';

import { getNormalizedApiDataByUuid } from '../../utils/ApiHelpers';
import PlaceDetailNormalizeData from './PlaceDetailNormalizeData';

import FadingContainer from '../FadingContainer/FadingContainer';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import VideoDetail from '../Video/VideoDetail';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import { assemblePlaceDetailNavBtnsData } from './PlaceHelpers';

const PlaceDetail = ( {
  uuid,
  showDescription,
  setNavBtns,
} ) => {
  const [ placeData, setPlaceData ] = useState( null );

  // Get place data.
  useEffect( () => {
    if ( uuid ) {
      const fetchData = async () => {
        const include = [
          'field_cover_photo',
          'field_virtual_tour.field_three_sixties',
          'field_video',
          'field_photo_gallery.field_gallery_photos',
        ];
        const request = {
          entityType: 'node',
          contentType: 'place',
          uuid,
          params: {
            include: include.join( ',' ),
          },
        };
        const data = await getNormalizedApiDataByUuid( request, PlaceDetailNormalizeData );
        setPlaceData( data );
      };
      fetchData();
    }
  }, [ uuid ] );

  // Set navigation buttons.
  const setNavBtnsCallback = useCallback( () => {
    const navBtnsArray = assemblePlaceDetailNavBtnsData( placeData, showDescription );
    setNavBtns( navBtnsArray );
  }, [
    placeData, setNavBtns, showDescription,
  ] );

  useEffect( () => {
    setNavBtnsCallback();
  }, [ setNavBtnsCallback ] );

  return placeData && (
    <FadingContainer>
      <div>

        { placeData?.description
          && placeData.description !== ''
          && showDescription && (
          <div id="about-scroll" className="modalSection modalTable">
            <div className="modalDescription type-body-md type-body--para">
              {placeData.description}
            </div>
          </div>
        )}

        { placeData.photoGalleryImages && (
          <PhotoGalleryDetail
            id="slideshow-scroll"
            uuid={null}
            images={placeData.photoGalleryImages}
            galleryClass="slideshow modalSection modalSection--gallery"
            imageClass="slideshowPhoto"
          />
        )}

        { placeData.video && (
          <VideoDetail
            hasId
            videoId={placeData.video.serviceVideoId}
            service={placeData.video.service}
            autoplay={false}
          />
        )}

        { placeData.threeSixties && (
          <VirtualTourDetail
            id="tour-scroll"
            uuid={null}
            threeSixtiesData={placeData.threeSixties}
            className="modalSection modalSection--sixteen9"
          />
        )}

      </div>
    </FadingContainer>
  );
};

export default PlaceDetail;
