import React from 'react';
import PropTypes from 'prop-types';

const ZoomZoneIcon = ( {
  x,
  y,
} ) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x={x || 0}
    y={y || 0}
    width="43"
    height="43"
    viewBox="0 0 43 43"
    className="zMarker__icon"
  >
    <circle className="zMarker__icon__bg" cx="21.5" cy="21.5" r="21.5" />
    <path
      className="zMarker__icon__doughnut"
      d="M21.5,8.5c-7.2,0-13,5.8-13,13s5.8,13,13,13s13-5.8,13-13S28.7,8.5,21.5,8.5 M21.5,37
      C13,37,6,30,6,21.5S13,6,21.5,6S37,13,37,21.5S30,37,21.5,37"
    />
    <path
      className="zMarker__icon__plus"
      d="M22.6,20.4v-4.2c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1v4.2h-4.2c-0.6,0-1.1,0.5-1.1,1.1
      s0.5,1.1,1.1,1.1h4.2v4.2c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1v-4.2h4.2c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1
      C26.8,20.4,22.6,20.4,22.6,20.4z"
    />
  </svg>
);

ZoomZoneIcon.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
};

ZoomZoneIcon.defaultProps = {
  x: 0,
  y: 0,
};

export default ZoomZoneIcon;
