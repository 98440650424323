import React from 'react';

import './PhotoGalleryImage.scss';

const PhotoGalleryImage = ({ className, image, caption }) => {
  const backgroundImageProperty = `url(${image})`;
  const style = {
    backgroundImage: backgroundImageProperty,
  };

  return (
    <div className={className} style={style}>
      { caption && (
        <div className="slideshowPhoto__captionWrapper">
          <div className="slideshowPhoto__caption">{caption}</div>
        </div>
      )}
    </div>
  );
};

export default PhotoGalleryImage;
