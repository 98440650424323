import React, { useContext } from 'react';

import MapContext from '../../MapPage/MapContext';

const SiteSeeTipTrigger = ( {
  siteSeeGuideId,
  setSiteSeeGuideId,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
  siteSeeTipId,
} ) => {
  const {
    isSiteSee,
  } = useContext( MapContext );

  return isSiteSee && siteSeeGuideMode === 'tip' && ( siteSeeGuideId !== siteSeeTipId ) ? (
    <button
      className="siteSeeGuide__tipTrigger"
      type="button"
      onClick={
        () => {
          setSiteSeeGuideId( siteSeeTipId );
          setShowSiteSeeGuide( true );
        }
      }
    >
      ?
    </button>
  )
    : (<></>);
};

export default SiteSeeTipTrigger;
