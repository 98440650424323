import React from 'react';
import classNames from 'classnames';

import DetailNav from './DetailNav/DetailNav';

import './DetailHeader.scss';

const DetailHeader = ( {
  text,
  navBtns,
  navClass,
} ) => {

  return (
    <>
      <div className={classNames( 'detailHeader', {
        'detailHeader--hasSubtitle': text.subtitle,
      } )}
      >
        <h2 className="detailHeader__title">{text.title}</h2>
        { text.subtitle && (
          <h3 className="detailHeader__subtitle">{text.subtitle}</h3>
        )}
      </div>

      <DetailNav
        navBtns={navBtns}
        navClass={navClass}
      />
    </>
  );
};

export default DetailHeader;
