import React from 'react';

import MapControlsZoomMoreIcon from '../../Map/MapControls/MapControlsZoom/MapControlsZoomMoreIcon';
import MapControlsZoomLessIcon from '../../Map/MapControls/MapControlsZoom/MapControlsZoomLessIcon';
import MapControlsZoomResetIcon from '../../Map/MapControls/MapControlsZoom/MapControlsZoomResetIcon';
import HybridIcon from '../../Map/MapControls/MapControlsMapTypeToggle/HybridIcon';
import POIIcon from '../../Map/MapControls/MapControlsPOIToggle/POIIcon';
import HideMarkersIcon from '../../Map/MapControls/MapControlsMarkersToggle/HideMarkersIcon';

const SiteSeeControlsGuide = () => (
  <ul>
    <li>
      <span className="siteSeeGuide__icon siteSeeGuide__icon--left"><MapControlsZoomMoreIcon /></span>
      Zoom in and reveal building details
    </li>
    <li>
      <span className="siteSeeGuide__icon siteSeeGuide__icon--left"><MapControlsZoomLessIcon /></span>
      Zoom out and reveal surroundings
    </li>
    <li>
      <span className="siteSeeGuide__icon siteSeeGuide__icon--left"><MapControlsZoomResetIcon /></span>
      Recenter map and fit on screen
    </li>
    <li>
      <span className="siteSeeGuide__icon siteSeeGuide__icon--left"><HybridIcon /></span>
      See satellite view
    </li>
    <li>
      <span className="siteSeeGuide__icon siteSeeGuide__icon--left"><POIIcon /></span>
      Toggle nearby points of interest
    </li>
    <li>
      <span className="siteSeeGuide__icon siteSeeGuide__icon--left"><HideMarkersIcon /></span>
      Toggle community labels
    </li>
  </ul>
);

export default SiteSeeControlsGuide;
