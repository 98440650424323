import React, { useState, useContext } from 'react';

import MapTilesGoogleMap from './MapTilesGoogleMap';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import '../MapPage/MapPage.scss';
import './MapTilesPage.scss';

const MapTilesPage = () => {
  const [ showInstructions, setShowInstructions ] = useState( false );
  const [ tileZoom, setTileZoom ] = useState( 18 );

  const { mapConfig } = useContext( ConfigContext );
  const { googleMapsConfig } = mapConfig || {};
  const { address, campusCenter, pinLabel } = googleMapsConfig || {};
  const { lat, lng } = campusCenter || {};

  const handleShowInstructions = () => setShowInstructions( true );
  const handleHideInstructions = () => setShowInstructions( false );

  const instructionsClass = showInstructions
    ? 'mapToolsInstructions--open'
    : 'mapToolsInstructions--closed';

  return (
    <div className="map">

      <MapTilesGoogleMap
        address={address}
        lat={lat}
        lng={lng}
        commName={pinLabel}
        tileZoom={tileZoom}
        setTileZoom={setTileZoom}
      />

      <div className={`mapToolsInstructions ${instructionsClass}`}>
        <div>
          { !address && !lat && !lng ? (
            <p>You must first enter an address or a lat lng in the CMS</p>
          )
            : (
              <p>
                Current Tile Zoom:
                {' '}
                {tileZoom}
              </p>
            )}
        </div>
        {showInstructions ? (
          <>
            <button onClick={handleHideInstructions}>Hide Instructions</button>
            <h1>Building the Map</h1>

            <h2>Stage 1 - Getting the Map to Show the Correct Area</h2>
            <ol>
              <li>
                <h3>
                  Enter community address in CMS using "Street Address", "City",
                  "State", and "Zip Code" fields.
                </h3>
                <p>You won't be able to go continue without doing this step.</p>
              </li>
            </ol>

            <h2>Stage 2 - Selecting the Map Tiles</h2>
            <ol>
              <li>
                <h3>
                  Select a zoom were you can see the entire community (around
                  17).
                </h3>
                <p>
                  Ideally, the community will fit on one tile, but we usually
                  aren't so lucky and have use multiple tiles.
                </p>
              </li>
              <li>
                <h3>Record selected zoom in CMS in "Source Tile Zoom" field</h3>
                <p>
                  <i>Example: 17</i>
                </p>
              </li>
            </ol>

            <h2>Stage 3 - Recording the Map Tiles</h2>
            <ol>
              <li>
                <h3>Find all the tiles the community is on.</h3>
                <p>
                  The final tiles have to form a rectangle, no tetris piece
                  looking selections.
                </p>
              </li>
              <li>
                <h3>
                  Record the "shape" of the tile section, each tile's position,
                  and the coordinates displayed on each tile.
                </h3>
                <p>
                  <i>
                    Example:
                    <br />
                    tile "shape" - 3 high, 2 wide
                    <br />
                    top left tile - 36021, 52364
                    <br />
                    top right tile - 36022, 52364
                    <br />
                    center left tile - 36021, 52365
                    <br />
                    center right tile - 36022, 52365
                    <br />
                    bottom left tile - 36021, 52366
                    <br />
                    bottom right tile - 36022, 52366
                  </i>
                </p>
                <p>
                  <i>
                    In this example, there's no part of the commmunity in the
                    bottom right tile, but I have to record it anyway so my
                    final coordinates are right.
                  </i>
                </p>
              </li>
              <li>
                <h3>
                  Enter top right tile X (first) value in CMS in "Source Tile
                  Top Right X" field
                </h3>
              </li>
              <li>
                <h3>
                  Enter top right tile Y (second) value in CMS in "Source Tile
                  Top Right Y" field
                </h3>
              </li>
              <li>
                <h3>
                  Enter bottom left tile X (first) value in CMS in "Source
                  Bottom Left X" field
                </h3>
              </li>
              <li>
                <h3>
                  Enter bottom left tile Y (second) value in CMS in "Source
                  Bottom Left Y" field
                </h3>
              </li>
            </ol>

            <h2>Stage 4 - Downloading the Map Tiles</h2>
            <ol>
              <li>
                <h3>
                  For each tile, go to the following URL:
                  http://mt0.google.com/vt/lyrs=m&x=ENTER_FIRST_CORDINATE_HERE&y=ENTER_SECOND_COORDINATE_HERE&z=ENTER_ZOOM_HERE
                </h3>
                <p>
                  Download the image it provides. Replace each placeholder value
                  with the value you recorded in step 3
                </p>
                <p>
                  <i>
                    Example:
                    <br />
                    top left tile - 36021, 52364 from zoom 17 would be
                    http://mt0.google.com/vt/lyrs=m&x=36021&y=52364&z=17
                  </i>
                </p>
              </li>
            </ol>

            <h2>Stage 5 - Assemble Tile in Illustrator</h2>
            <ol>
              <li>
                <h3>
                  Assemble your tiles in Illustrator and fit your artboard to
                  the tiles.
                </h3>
                <p>The tile images are 256px x 256px.</p>
                <p>
                  The easiest way to assemble them is make an artboard that is
                  (256px * # of left to right tiles) X (256px * # of top to
                  bottom tiles). DON'T HAND DRAW THE ARTBOARD. You'll end up
                  with a wonky map measurements that aren't quite full scale,
                  like 768.4px
                </p>
                <p>
                  <i>
                    Example:
                    <br />
                    tile "shape" - 3 high, 2 wide
                    <br />
                    artboard size - (256 x 3) X (256 X 2) = 768 X 512
                  </i>
                </p>
              </li>
              <li>
                <h3>Make sure the artboard origin is 0 x 0</h3>
              </li>
              <li>
                <h3>
                  Paste images into artboard and set top left corners to
                  appropriate coords using Transform Tool
                </h3>
                <p>
                  DON'T DO THIS BY HAND. You'll end up with wonky map
                  dimensions.
                </p>
                <p>
                  <i>
                    Example:
                    <br />
                    tile "shape" - 3 high, 2 wide
                    <br />
                    top left tile - 0,0
                    <br />
                    top right tile - 256,0
                    <br />
                    center left tile - 0, 256
                    <br />
                    center right tile - 256, 256
                    <br />
                    bottom left tile - 0, 512
                    <br />
                    bottom right tile - 256, 512
                  </i>
                </p>
              </li>
            </ol>
          </>
        ) : (
          <button onClick={handleShowInstructions}>Show Instructions</button>
        )}
      </div>
    </div>
  );
};

export default MapTilesPage;

//  'comValues' - google map with tools that let you return lat lng and zoom info when you click
//      #1 - Determine community pin latlng and record it
//              At this stage, the community pin is located at the latlng generated by the community address
//              This usualy isn't where we want to put the pin, but its worth a shot
//              Click where you'd like for the pin to go and record the latlng
//              If you get overly clicky, you can delete a pin by just clicking on it.
//              Example:
//                  lat - 33.98550381692573
//                  lng - -81.06235876840059

//      #2 - Determine the community center lat lng. You dont have to set this if you want to use the community pin lat lng
//              Otherwise, click the visual center of the community and record the latlng
//              Example:
//                  lat - 33.98550381692573
//                  lng - -81.06235876840059

//      #3 - Place pins at the top right and bottom left of campus and to form a rectangle that contains the entire campus.
//              Record lat lngs of each pin. They will translate to the north, east, south, and west bounds that Google maps needs
//              This values will be overrride initial zoom to fit campus in viewport on load or recenter
//              Example:
//                  top right lat - 33.987176272125204 (north)
//                  top right lng - -81.06107667249147 (east)
//                  bottom left lat - 33.98361781732553 (south)
//                  bottom left lng - -81.06499269765321 (west)

//      #4 - If you would like max zoom and pan bounds, change zoom to desired max zoom and place pins at the top right and bottom left of max zoom out area.
//              This might be the state or the city.
//              Record lat lngs of each pin. They will translate to the north, east, south, and west bounds that Google maps needs
//              This values will be overrride min zoom.
//              Example:
//                  top right lat - 34.9807125181132 (north)
//                  top right lng - -78.60476817111494 (east)
//                  bottom left lat - 32.07813742022117 (south)
//                  bottom left lng - -81.06499269765321 (west)

//      #5 - If you aren't planning to use campus bounds, determine your initial zoom and record it. Default is 17.
//              Example:
//                  initial zoom - 17

//      #6 - If you aren't planning to use max zoom bounds, determine your min zoom and record it. Default is 3.
//              Example:
//                  min zoom - 5

//      #7 - Determine your max zoom, Google usually stops at 22, but sometimes goes to 23. Default is 23.
//              Example:
//                  max zoom - 21

//      #8 - Move on to the buildOverlay stage!
