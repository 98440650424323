import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import ModalTrigger from '../Modal/ModalTrigger';
import Favorite from '../Personalization/Favorite/Favorite';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

const VideoListItem = ( {
  uuid,
  coverImage,
  title,
  videoId,
  service,
  makeButton,
} ) => {
  const { personalizationActive } = useContext( ConfigContext );

  const imgBg = {
    backgroundImage: `url(${coverImage})`,
  };

  const favorite = personalizationActive ? (
    <Favorite
      type="video"
      uuid={uuid}
      hasShadow={false}
    />
  ) : null;

  const innerMarkup = (
    <div className="imgContainer">
      <div className="imgHolder" style={{ ...imgBg }} />
      <h2 className="gridItemTitle type-display-xl">{title}</h2>
    </div>
  );

  const modalInfo = {
    title: {
      title,
    },
    videoId,
    service,
  };

  let markup = '';
  if ( makeButton ) {
    markup = (
      <ModalTrigger
        uuid={null}
        nodeType="video"
        triggerType="div"
        markup={innerMarkup}
        modalInfo={modalInfo}
      />
    );
  }

  return (
    <div className="gridItem">
      { markup }
      { favorite }
    </div>
  );
};

VideoListItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  coverImage: PropTypes.string,
  title: PropTypes.string,
  videoId: PropTypes.string,
  service: PropTypes.string,
  makeButton: PropTypes.bool,
};

VideoListItem.defaultProps = {
  coverImage: null,
  title: null,
  videoId: null,
  service: null,
  makeButton: false,
};

export default VideoListItem;
