import React from 'react';

const MapControlsZoomLessIcon = () => (
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
  >
    <g>
      <path
        d="M19,37.86C8.6,37.86,0.14,29.4,0.14,19S8.6,0.14,19,0.14S37.86,8.6,37.86,19S29.4,37.86,19,37.86z M19,3.14
          C10.25,3.14,3.14,10.25,3.14,19S10.25,34.86,19,34.86S34.86,27.75,34.86,19S27.75,3.14,19,3.14z"
      />
    </g>
    <g>
      <path d="M26.06,20.5H11.94c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5h14.11c0.83,0,1.5,0.67,1.5,1.5S26.89,20.5,26.06,20.5z" />
    </g>
  </svg>
);

export default MapControlsZoomLessIcon;
