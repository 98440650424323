/* eslint-disable import/prefer-default-export */
export function finishCollectionNormalizeData( data, included ) {
  const finishOptions = [];

  if ( data && data.length ) {
    data.forEach( ( rawFinishOption ) => {
      const spaceId = rawFinishOption?.fieldFinishSpace?.id;
      const paletteId = rawFinishOption?.fieldFinishPalette?.id;
      const imageId = rawFinishOption?.fieldMediaImage?.id;
      const image = included?.find( ( { id } ) => id === imageId );
      const imageUrl = image?.links?.modal?.href;
      const spaceWeight = rawFinishOption?.fieldFinishSpace?.weight || 0;
      const paletteWeight = rawFinishOption?.fieldFinishPalette?.weight || 0;

      // Add processed option to options array.
      const finishOption = {
        spaceId,
        spaceName: rawFinishOption?.fieldFinishSpace?.name,
        spaceWeight,
        paletteId,
        paletteName: rawFinishOption?.fieldFinishPalette?.name,
        paletteWeight,
        imageUrl,
      };
      finishOptions.push( finishOption );
    } );
  }

  // Sort by room weight, then palette weight.
  finishOptions.sort( ( a, b ) => ( a.spaceWeight > b.spaceWeight
    ? 1
    : a.spaceWeight === b.spaceWeight
      ? a.paletteWeight > b.paletteWeight
        ? 1
        : -1
      : -1 ) );

  // Restructure raw finish options array into an object that has a property for
  // each unique spaceId. Set each of those properties' values with an array
  // created from the finish options using that spaceId.
  const spaces = finishOptions.reduce( ( spaces, option ) => {
    const space = spaces[option.spaceId]?.palettes?.paletteOptions || [];
    space.push( option );

    spaces[option.spaceId] = {
      spaceWeight: option.spaceWeight,
      spaceId: option.spaceId,
      spaceName: option.spaceName,
      palettes: {
        defaultPaletteId: space[0].paletteId,
        paletteOptions: space,
      },
    };
    return spaces;
  }, {} );

  const finishCollection = {};
  if ( finishOptions.length > 0 ) {
    finishCollection.defaultSpaceId = finishOptions[0].spaceId;
    finishCollection.spaces = Object.values( spaces );
    finishCollection.images = finishOptions;
  }

  return finishCollection;
}
