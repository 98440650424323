import React from 'react';

import PlaceList from '../Place/PlaceList';

import '../../GlobalScss/grid.scss';

const AmenitiesPage = () => {
  return (
    <div className="content">
      <PlaceList />
    </div>
  );
};

export default AmenitiesPage;
