import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import ModalTrigger from '../Modal/ModalTrigger';
import Favorite from '../Personalization/Favorite/Favorite';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

const VirtualTourItem = ( {
  uuid,
  title,
  coverImage,
} ) => {
  const { personalizationActive } = useContext( ConfigContext );

  const imgBg = {
    backgroundImage: `url(${coverImage})`,
  };

  const favorite = personalizationActive ? (
    <Favorite
      type="virtual_tour"
      uuid={uuid}
      hasShadow={false}
    />
  ) : null;

  const innerMarkup = (
    <div className="imgContainer">
      <div className="imgHolder" style={{ ...imgBg }} />
      <h2 className="gridItemTitle type-display-xl">{title.title}</h2>
    </div>
  );

  const modalInfo = {
    title,
  };

  return (
    <div className="gridItem">
      <ModalTrigger
        uuid={uuid}
        nodeType="virtual-tour"
        triggerType="div"
        markup={innerMarkup}
        modalInfo={modalInfo}
      />
      { favorite }
    </div>
  );
};

VirtualTourItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.shape( {
    title: PropTypes.string,
  } ),
  coverImage: PropTypes.string,
};

VirtualTourItem.defaultProps = {
  title: null,
  coverImage: null,
};

export default VirtualTourItem;
