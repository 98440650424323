/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import DayJS from 'react-dayjs';
import { animateScroll as scroll } from 'react-scroll';

import PanesWidget from '../PanesWidget/PanesWidget';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import FadingContainer from '../FadingContainer/FadingContainer';
import { getNormalizedApiDataByDrupalId } from '../../utils/ApiHelpers';
import {
  residentCommunityNormalizeData,
  residentFloorPlansNormalizeData,
  residentAmenitiesNormalizeData,
  residentMediaNormalizeData,
  residentMyResourcesNormalizeData,
} from '../Resident/ResidentNormalizeData';
import FormattedStickyNoFavoritesBlurb from './FormattedStickyNoFavoritesBlurb';

import './PIBPage.scss';
import ResourceDetail from '../MyResources/ResourcesDetail';

// get current year
const date = new Date();
const year = date.getFullYear();

const PIBPage = () => {
  const [ residentCommunityData, setResidentCommunityData ] = useState( {} );
  const [ floorPlansData, setFloorPlansData ] = useState( null );
  const [ amenitiesData, setAmenitiesData ] = useState( null );
  const [ mediaData, setMediaData ] = useState( null );
  const [ panesData, setPanesData ] = useState( null );
  const [ myResourcesData, setMyResourcesData ] = useState( null );
  const [ logoLoaded, setLogoLoaded ] = useState( false );

  const { isAuthed, settings } = useContext( ConfigContext );
  const { drupalResidentId } = settings;

  // get person and community info
  useEffect( () => {
    const fetchData = async () => {
      const includes = [
        'og_audience',
        'og_audience.field_logo',
        'field_spouse',
      ];
      const request = {
        entityType: 'node',
        contentType: 'resident',
        drupalId: drupalResidentId,
        include: includes.join( ',' ),
      };
      const data = await getNormalizedApiDataByDrupalId( request, residentCommunityNormalizeData );
      setResidentCommunityData( data );
    };
    if ( isAuthed && drupalResidentId ) {
      fetchData();
    }
  }, [ isAuthed, drupalResidentId ] );

  const LogoWithOptionalLink = () => {
    const image = <img className="pibPage-branding__logoLink__logoImg" src={residentCommunityData?.community?.logoUrl} alt={residentCommunityData?.community?.website} />;
    const pseudoImage = new Image();
    pseudoImage.src = residentCommunityData?.community?.logoUrl;
    pseudoImage.onload = () => {
      setLogoLoaded( true );
    };
    const markup = residentCommunityData?.community?.website
      ? (
        <Link
          className="pibPage-branding__logoLink"
          to={residentCommunityData.community.website}
        >
          {image}
        </Link>
      )
      : image;
    return markup;
  };

  const FormattedStickyAmenitiesBlurb = ( rawAmenitiesBlurb ) => {
    const markup = rawAmenitiesBlurb
      ? (
        <div className="pibPage-favorites__stickyContent">
          <div className="pibPage-favorites__stickyContent__title">Amenities & Services</div>
          <div className="pibPage-favorites__stickyContent__wysiwyg" dangerouslySetInnerHTML={{ __html: rawAmenitiesBlurb }} />
        </div>
      )
      : null;
    return markup;
  };

  // Load logo image.
  const logoUrl = residentCommunityData?.community?.logoUrl;
  useEffect( () => {
    const logoImg = new Image();
    logoImg.src = logoUrl;
    logoImg.onload = () => {
      setLogoLoaded( true );
    };
  }, [ logoUrl ] );

  // Get favorite floor plan data.
  useEffect( () => {
    const fetchData = async () => {
      const includes = [
        'field_favorite_floor_plans',
        'field_favorite_floor_plans.field_2d_floor_plan',
        'field_favorite_floor_plans.field_3d_floor_plan',
        'field_favorite_floor_plans.field_level_of_care',
        'field_favorite_floor_plans.field_floor_plan_category',
        'field_favorite_floor_plans.field_finish_collection',
        'field_favorite_floor_plans.field_finish_collection.field_finish_option',
        'field_favorite_floor_plans.field_finish_collection.field_finish_option.field_finish_space',
        'field_favorite_floor_plans.field_finish_collection.field_finish_option.field_finish_palette',
        'field_favorite_floor_plans.field_finish_collection.field_finish_option.field_media_image',
        'field_favorite_floor_plans.field_virtual_tour',
        'field_favorite_floor_plans.field_virtual_tour.field_three_sixties',
        'field_favorite_floor_plans.field_video',
        'field_favorite_floor_plans.field_photo_gallery',
        'field_favorite_floor_plans.field_photo_gallery.field_gallery_photos',
        'field_favorite_floor_plans.field_downloadable_pdf',
      ];
      const request = {
        entityType: 'node',
        contentType: 'resident',
        drupalId: drupalResidentId,
        include: includes.join( ',' ),
        params: {
          sort: 'title',
        },
      };
      const data = await getNormalizedApiDataByDrupalId( request, residentFloorPlansNormalizeData );
      setFloorPlansData( data );
    };
    if ( isAuthed && drupalResidentId ) {
      fetchData();
    }
  }, [ isAuthed, drupalResidentId ] );

  // Get favorite amenities data.
  useEffect( () => {
    const includes = [
      'field_favorite_amenities',
      'field_favorite_amenities.field_cover_photo',
      'field_favorite_amenities.field_virtual_tour',
      'field_favorite_amenities.field_virtual_tour.field_three_sixties',
      'field_favorite_amenities.field_video',
      'field_favorite_amenities.field_photo_gallery',
      'field_favorite_amenities.field_photo_gallery.field_gallery_photos',
    ];
    const request = {
      entityType: 'node',
      contentType: 'resident',
      drupalId: drupalResidentId,
      include: includes.join( ',' ),
      params: {
        sort: 'title',
      },
    };
    const fetchData = async () => {
      const data = await getNormalizedApiDataByDrupalId( request, residentAmenitiesNormalizeData );
      setAmenitiesData( data );
    };
    if ( isAuthed && drupalResidentId ) {
      fetchData();
    }
  }, [ isAuthed, drupalResidentId ] );

  // Get favorite media data. We can't get the full entities from here becaue they have
  // different fields.
  useEffect( () => {
    const fetchData = async () => {
      const includes = [
        'field_favorite_media',
        'field_favorite_media.field_cover_photo',
        'field_favorite_media.field_gallery_photos',
        'field_favorite_media.field_three_sixties',
      ];
      const request = {
        entityType: 'node',
        contentType: 'resident',
        drupalId: drupalResidentId,
        include: includes.join( ',' ),
        params: {
          sort: 'title',
        },
      };
      const data = await getNormalizedApiDataByDrupalId( request, residentMediaNormalizeData );
      setMediaData( data );
    };
    if ( isAuthed && drupalResidentId ) {
      fetchData();
    }
  }, [ isAuthed, drupalResidentId ] );

  // Get My Resources Data of the community.
  useEffect( () => {
    const fetchData = async () => {
      const includes = [
        'og_audience',
        'og_audience.field_community_documents',
        'og_audience.field_community_documents.field_media_file',
      ];
      const fields = {
        'node--community': [ 'field_community_external_links', 'field_community_documents' ],
        'media--document': [ 'name', 'field_media_file' ],
        'file--file': [ 'uri' ],
      };
      const request = {
        entityType: 'node',
        contentType: 'resident',
        drupalId: drupalResidentId,
        include: includes.join( ',' ),
        fields,
      };
      const data = await getNormalizedApiDataByDrupalId(
        request,
        residentMyResourcesNormalizeData
      );
      setMyResourcesData( data );
    };

    // If user is authenticated, Call fetchData().
    if ( isAuthed && drupalResidentId ) {
      fetchData();
    }
  }, [ isAuthed, drupalResidentId ] );

  // Get favorites data and format it for the panes widget.
  useEffect( () => {
    const rawPanesData = [];

    if ( floorPlansData?.length > 0 ) {
      const floorPlans = {
        id: 'floorPlans',
        btnText: 'My Favorite Floor Plans',
        paneData: floorPlansData,
        subpaneComponentName: 'FloorPlanDetail',
        stickyMarkup: null,
      };
      rawPanesData.push( floorPlans );
    }

    const rawAmenitiesBlurb = residentCommunityData?.community?.amenitiesServicesHTML;
    if ( rawAmenitiesBlurb || amenitiesData?.length > 0 ) {
      const stickyAmenitiesBlurb = FormattedStickyAmenitiesBlurb( rawAmenitiesBlurb );
      const amenities = {
        id: 'amenities',
        btnText: 'My Favorite Amenities',
        paneData: amenitiesData,
        subpaneComponentName: 'PlaceDetail',
        stickyMarkup: stickyAmenitiesBlurb,
      };
      rawPanesData.push( amenities );
    }

    if ( mediaData?.length > 0 ) {
      const media = {
        id: 'media',
        btnText: 'My Favorite Media',
        paneData: mediaData,
        subpaneComponentName: 'MediaDetail',
        stickyMarkup: null,
      };
      rawPanesData.push( media );
    }

      if ( myResourcesData?.links.length || myResourcesData?.files.length ) {
      const resources = {
        id: 'resources',
        btnText: 'My Resources',
        paneData: null,
        subpaneComponentName: 'ResourceDetail',
        stickyMarkup: <ResourceDetail myResourcesData={myResourcesData} />,
      };
      rawPanesData.push( resources );
    }

    // The person has no favorites yet
    // and the community has no amenities and services blurb.
    if ( floorPlansData?.length === 0
      && amenitiesData?.length === 0
      && mediaData?.length === 0
      && !rawAmenitiesBlurb
    ) {
      const phone = residentCommunityData?.community?.phone;
      const email = residentCommunityData?.community?.email;
      const stickyNoFavoriteBlurb = FormattedStickyNoFavoritesBlurb(
        phone,
        email,
      );
      const amenities = {
        id: 'amenities',
        btnText: 'My Favorite Amenities',
        paneData: [],
        subpaneComponentName: 'PlaceDetail',
        stickyMarkup: stickyNoFavoriteBlurb,
      };
      rawPanesData.push( amenities );
    }

    setPanesData( rawPanesData );
  }, [
    residentCommunityData, floorPlansData, amenitiesData, myResourcesData, mediaData,
  ] );

  const handleBackToTopClick = () => {
    scroll.scrollToTop( {
      duration: 500,
      smooth: 'easeInOutCubic',
    } );
  };

  return (
    <FadingContainer>
      <div className="pibPage">

        <div className="pibPage-branding">
          <LogoWithOptionalLink />
        </div>

        <div className="pibPage-welcome">

          <div className="pibPage-welcome__intro">
            {residentCommunityData?.names
              && (
              <>
                {residentCommunityData.names}
                , Your Personalized Interactive Brochure Is Ready.
              </>
              )}
          </div>

          { ( residentCommunityData?.community?.phone || residentCommunityData?.community?.email )
                && (
                <div className="pibPage-welcome__contact">
                  { residentCommunityData?.community?.email
                    && (
                    <>
                      Email us at
                      {' '}
                      <a href={`mailto:${residentCommunityData?.community?.email}`}>{residentCommunityData?.community?.email}</a>
                    </>
                    )}

                  { residentCommunityData?.community?.phone
                    && (
                    <>
                      { residentCommunityData?.community?.email
                        ? <> or call </>
                        : <> Call </>}
                      us at
                      {' '}
                      {residentCommunityData?.community?.phone}
                      {' '}
                      if you have questions.
                    </>
                    )}
                </div>
                )}

          <div className="pibPage-welcome__date">
            Personalized
            {' '}
            <DayJS element="span" format="MMMM D[,] YYYY">{residentCommunityData?.createdDate}</DayJS>
            {' '}
            — Last edited
            {' '}
            <DayJS element="span" format="MMMM D[,] YYYY">{residentCommunityData?.changedDate}</DayJS>
          </div>

        </div>

        { residentCommunityData?.message && (
          <div className="pibPage-message">
            <div className="pibPage-message__body" dangerouslySetInnerHTML={{ __html: residentCommunityData.message }} />
          </div>
        )}

        <div className="pibPage-favorites">
          <PanesWidget panesData={panesData} />
        </div>

        <div className="pibPage-backToTop">
          <button type="button" className="pibPage-backToTop__button" onClick={handleBackToTopClick}>
            <span className="pibPage-backToTop__button__arrow" />
            <span className="pibPage-backToTop__button__text">Back to Top</span>
          </button>
        </div>

        <div className="pibPage-vectre">
          <div className="pibPage-vectre__message">
            Your Personalized Interactive Brochure is brought to you by
            {' '}
            <span>YOUR</span>
            TOUR, a sales enablement tool by THE VECTRE.
          </div>
          <div className="pibPage-vectre__colophon">
            Copyright ©
            {' '}
            {year}
            {' '}
            The Vectre - All Rights Reserved
          </div>
        </div>

      </div>
    </FadingContainer>
  );
};

export default PIBPage;
