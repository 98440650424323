import {
  generateRandomString,
  pkceChallengeFromVerifier,
} from './AuthHelpers';

/* eslint-disable import/prefer-default-export */
/**
 * @file
 *
 * Wrapper around fetch(), and OAuth access token handling operations.
 *
 * To use import getAuthClient, and initialize a client:
 * const auth = getAuthClient();
 */
const refreshPromises = [];

/**
* OAuth client factory.
*
* @returns {object}
*   Returns an object of functions with $config injected.
*/
export function getAuthClient() {
  const config = {
    // Base URL of your Drupal site.
    base: process.env.REACT_APP_YOUR_TOUR_CMS_URL,
    // Name to use when storing the token in localStorage.
    token_name: 'yourtour-oauth-token',
    // OAuth client ID - get from Drupal.
    client_id: process.env.REACT_APP_DRUPAL_CONSUMER_UUID,
    // OAuth client secret - set in Drupal.
    client_secret: process.env.REACT_APP_DRUPAL_CONSUMER_CLIENT_SECRET,
    // Drupal user role related to this OAuth client.
    scope: 'yourtour_api',
    // Where the request will be redirect after grant is authorized.
    redirect_uri: window.location.origin,
    // Margin of time before the current token expires that we should force a
    // token refresh.
    expire_margin: 0,
  };

  /**
  * Delete the stored OAuth token, effectively ending the user's session.
  */
  function logout() {
    // Remove all localstorage data we stored.
    localStorage.removeItem( config.token_name );
    const redirectUrl = `${config.base}/user/logout?yourtour_redirect=${config.redirect_uri}`;
    window.location = redirectUrl;
  }

  /**
  * Store an OAuth token retrieved from Drupal in localStorage.
  *
  * @param {object} data
  * @param {string} tokenName
  * @returns {object}
  *
  * Returns the token with an additional expires_at property added.
  */
  function saveToken( data, tokenName ) {
    const currentToken = { ...data }; // Make a copy of data object.
    currentToken.date = Math.floor( Date.now() / 1000 );
    currentToken.expires_at = currentToken.date + currentToken.expires_in;
    localStorage.setItem( tokenName, JSON.stringify( currentToken ) );
    return currentToken;
  }

  /**
  * Request a new token using a refresh_token.
  *
  * This function is smart about reusing requests for a refresh token. So it is
  * safe to call it multiple times in succession without having to worry about
  * whether a previous request is still processing.
  */
  function refreshToken( refreshTokenObject ) {
    if ( refreshPromises[refreshTokenObject] ) {
      return refreshPromises[refreshTokenObject];
    }

    // Note that the data in the request is different when getting a new token
    // via a refresh_token. grant_type = refresh_token, and do NOT include the
    // scope parameter in the request as it'll cause issues if you do.
    const params = {
      grant_type: 'refresh_token',
      client_id: config.client_id,
      client_secret: config.client_secret,
      refresh_token: refreshTokenObject,
    };
    const postData = new URLSearchParams( params ).toString();

    const newToken = fetch( `${config.base}/oauth/token`, {
      method: 'post',
      headers: new Headers( {
        'Content-Type': 'application/x-www-form-urlencoded',
      } ),
      body: postData,
    } )
      .then( ( response ) => response.json() )
      .then( ( data ) => {
        delete refreshPromises[refreshTokenObject];
        if ( data.error ) {
          console.error( 'Error refreshing token', data );
          return false;
        }
        return saveToken( data, config.token_name );
      } )
      .catch( ( err ) => {
        delete refreshPromises[refreshTokenObject];
        console.error( 'API got an error', err );
        // return Promise.reject( err );
        return Promise.resolve( false );
      } );

    refreshPromises[refreshTokenObject] = newToken;

    return refreshPromises[refreshTokenObject];
  }

  /**
  * Get the current OAuth token if there is one.
  *
  * Get the OAuth token form localStorage, and refresh it if necessary using
  * the included refresh_token.
  *
  * @returns {Promise}
  *   Returns a Promise that resolves to the current token, or false.
  */
  async function getLocalStorageToken( tokenName ) {
    const currentToken = localStorage.getItem( tokenName ) !== null
      ? JSON.parse( localStorage.getItem( tokenName ) )
      : false;

    if ( !currentToken ) {
      return Promise.reject( 'empty token' );
    }

    // eslint-disable-next-line camelcase
    const { expires_at, refresh_token } = currentToken;
    // eslint-disable-next-line camelcase
    if ( expires_at - config.expire_margin < Date.now() / 1000 ) {
      return refreshToken( refresh_token );
    }
    return Promise.resolve( currentToken );
  }

  /**
  * Login to Drupal and get authorization code.
  */
  async function preLogin() {
    // Create and store a random "state" value.
    const state = generateRandomString();
    localStorage.setItem( 'pkce_state', state );

    // Create and store a new PKCE code_verifier (the plaintext random secret)
    const codeVerifier = generateRandomString();
    localStorage.setItem( 'pkce_code_verifier', codeVerifier );

    // Hash and base64-urlencode the secret to use as the challenge
    const codeChallenge = await pkceChallengeFromVerifier( codeVerifier );

    // Build the authorization URL
    const rawParams = {
      response_type: 'code',
      client_id: config.client_id,
      state,
      scope: config.scope,
      code_challenge: codeChallenge,
      redirect_uri: window.location.origin,
      code_challenge_method: 'S256',
    };
    const params = new URLSearchParams( rawParams ).toString();
    const url = `${config.base}/oauth/authorize?${params} `;

    // Redirect the user to Drupal to sign in.
    window.location = url;
  }

  /**
   * Get Oauth Token
   */
  async function login( code ) {
    const localState = localStorage.getItem( 'pkce_state' );
    const codeVerifier = localStorage.getItem( 'pkce_code_verifier' );

    const url = new URL( window.location );
    const params = new URLSearchParams( url.search );
    const error = params.get( 'error' );
    const state = params.get( 'state' );

    if ( error || !code ) {
      const message = error || 'No code parameter found in URL.';
      return Promise.reject( new Error( message ) );
    }

    if ( localState !== state ) {
      return Promise.reject( new Error( 'Invalid PCKE state.' ) );
    }

    const postDataParams = {
      grant_type: 'authorization_code',
      client_id: config.client_id,
      client_secret: config.client_secret,
      scope: config.scope,
      code,
      code_verifier: codeVerifier,
      redirect_uri: config.redirect_uri,
    };

    const postData = new URLSearchParams( postDataParams ).toString();
    try {
      const response = await fetch( `${config.base}/oauth/token`, {
        method: 'post',
        headers: new Headers( {
          'Content-Type': 'application/x-www-form-urlencoded',
        } ),
        body: postData,
      } );
      const data = await response.json();
      if ( data.error ) {
        return Promise.reject( new Error( `Error retrieving OAuth token: ${data.error} ` ) );
      }

      // Clean these up since we don't need them anymore.
      localStorage.removeItem( 'pkce_state' );
      localStorage.removeItem( 'pkce_code_verifier' );

      return saveToken( data, config.token_name );
    } catch ( err ) {
      return Promise.reject( new Error( `API error: ${err} ` ) );
    }
  }

  /**
   * Get Oauth Token without logging in.
   */
  async function noLoginAuth() {
    const postDataParams = {
      grant_type: 'client_credentials',
      client_id: config.client_id,
      client_secret: config.client_secret,
      scope: config.scope,
      redirect_uri: config.redirect_uri,
    };

    const postData = new URLSearchParams( postDataParams ).toString();

    try {
      const response = await fetch( `${config.base}/oauth/token`, {
        method: 'post',
        headers: new Headers( {
          'Content-Type': 'application/x-www-form-urlencoded',
        } ),
        body: postData,
      } );
      const data = await response.json();
      if ( data.error ) {
        return Promise.reject( new Error( `Error retrieving OAuth token: ${data.error} ` ) );
      }
      return saveToken( data, config.token_name );
    } catch ( err ) {
      return Promise.reject( new Error( `API error: ${err} ` ) );
    }
  }

  /**
  * Wrapper for fetch() that will attempt to add a Bearer token if present.
  *
  * If there's a valid token, or one can be obtained via a refresh token, then
  * add it to the request headers. If not, issue the request without adding an
  * Authorization header.
  *
  * @param {string} url URL to fetch.
  * @param {object} options Options for fetch().
  *
  * returns {object} Response as JSON or error
  */
  async function fetchWithAuthentication( url, method, body ) {
    const options = {
      method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: new Headers( {
        'X-Consumer-ID': process.env.REACT_APP_DRUPAL_CONSUMER_UUID,
      } ),
    };

    if ( body ) {
      options.body = body;
      options.headers.append( 'Content-Type', 'application/vnd.api+json' );
    }

    if ( !options.headers.get( 'Authorization' ) ) {
      const oauthToken = await getLocalStorageToken( config.token_name, refreshPromises, config );
      if ( oauthToken ) {
        options.headers.append( 'Authorization', `Bearer ${oauthToken.access_token}` );
      }
    }

    let combinedData = null;

    try {
      const fullUrl = `${config.base}${url}`;
      const res = await fetch( fullUrl, options );
      combinedData = await res.json();
      let hasMore = combinedData?.links?.next;
      let moreLink = combinedData?.links?.next?.href;
      while ( hasMore ) {
        const responseMore = await fetch( moreLink, options );
        const dataMore = await responseMore.json();
        combinedData.data = [ ...combinedData.data, ...dataMore.data ];
        combinedData.included = [ ...combinedData.included,
          ...dataMore.included ];
        hasMore = dataMore?.links?.next;
        moreLink = dataMore?.links?.next?.href;
      }
      return combinedData;
    } catch ( error ) {
      // safe to allow this error
      // eslint-disable-next-line no-console
      console.error( error );
    }
  }

  /**
  * Wrapper for fetch() that will attempt to add a Bearer token if present.
  *
  * If there's a valid token, or one can be obtained via a refresh token, then
  * add it to the request headers. If not, issue the request without adding an
  * Authorization header.
  *
  * @param {string} url URL to fetch.
  * @param {object} options Options for fetch().
  *
  * returns {object} Response as JSON or error
  */
  async function fetchXmlWithAuthentication( url ) {
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: new Headers( {
        'X-Consumer-ID': process.env.REACT_APP_DRUPAL_CONSUMER_UUID,
      } ),
    };

    if ( !options.headers.get( 'Authorization' ) ) {
      const oauthToken = await getLocalStorageToken( config.token_name, refreshPromises, config );
      if ( oauthToken ) {
        options.headers.append( 'Authorization', `Bearer ${oauthToken.access_token}` );
      }
    }

    try {
      const fullUrl = `${url}`;
      const res = await fetch( fullUrl, options );
      const text = await res.text();
      return text;
    } catch ( error ) {
      // safe to allow this error
      // eslint-disable-next-line no-console
      console.error( error );
    }
  }

  /**
  * Wrapper for fetch() that will attempt to add a Bearer token if present.
  *
  * If there's a valid token, or one can be obtained via a refresh token, then
  * add it to the request headers. If not, issue the request without adding an
  * Authorization header.
  *
  * @param {string} url URL to fetch.
  * @param {object} options Options for fetch().
  *
  * returns {object} Response as JSON or error
  */
  async function fetchImgWithAuthentication( url ) {
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: new Headers( {
        'X-Consumer-ID': process.env.REACT_APP_DRUPAL_CONSUMER_UUID,
      } ),
    };

    if ( !options.headers.get( 'Authorization' ) ) {
      const oauthToken = await getLocalStorageToken( config.token_name, refreshPromises, config );
      if ( oauthToken ) {
        options.headers.append( 'Authorization', `Bearer ${oauthToken.access_token}` );
      }
    }

    try {
      const fullUrl = `${config.base}${url}`;
      const res = await fetch( fullUrl, options );
      const blob = await res.blob();
      return blob;
    } catch ( error ) {
      // safe to allow this error
      // eslint-disable-next-line no-console
      console.error( error );
    }
  }

  /**
  * Check if the current user is logged in or not.
  * If they are logged in, return their username
  *
  * @returns {Promise}
  */
  async function isLoggedIn() {
    const currentToken = await getLocalStorageToken( config.token_name, refreshPromises, config );
    if ( currentToken ) {
      return Promise.resolve( currentToken );
    }
    return Promise.resolve( false );
  }

  return {
    preLogin,
    login,
    noLoginAuth,
    logout,
    isLoggedIn,
    fetchWithAuthentication,
    fetchXmlWithAuthentication,
    fetchImgWithAuthentication,
  };
}
