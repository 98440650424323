import React from 'react';

const POIIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
    xmlSpace="preserve"
  >
    <path d="M19,0.14C8.6,0.14,0.14,8.6,0.14,19S8.6,37.86,19,37.86S37.86,29.4,37.86,19S29.4,0.14,19,0.14z M3.14,19
    c0-8.75,7.12-15.86,15.86-15.86c3.8,0,7.29,1.35,10.02,3.58l-3.44,3.44C24.1,8.01,21.63,6.6,18.83,6.6c-4.54,0-8.23,3.69-8.23,8.23
    c0,1.84,1.26,4.79,2.71,7.6l-6.59,6.59C4.48,26.29,3.14,22.8,3.14,19z M20.65,15.09c-0.04-0.97-0.84-1.75-1.82-1.75
    c-1.01,0-1.83,0.82-1.83,1.83c0,0.98,0.78,1.78,1.75,1.82l-3.21,3.21c-1.13-2.25-1.95-4.28-1.95-5.36c0-2.88,2.35-5.23,5.23-5.23
    c1.98,0,3.68,1.12,4.57,2.74L20.65,15.09z M23.81,16.18c-0.74,2.49-3.01,6.65-4.98,9.87c-0.59-0.97-1.21-2.02-1.81-3.08L23.81,16.18
    z M19,34.86c-3.87,0-7.42-1.4-10.17-3.71l5.99-5.99c1.21,2.1,2.32,3.83,2.76,4.51l1.26,1.92l1.26-1.92
    c1.17-1.78,6.97-10.88,6.97-14.85c0-0.59-0.07-1.17-0.18-1.72l4.28-4.28c2.31,2.76,3.71,6.3,3.71,10.17
    C34.86,27.75,27.75,34.86,19,34.86z"
    />
  </svg>
);

export default POIIcon;
