import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import PhotoGalleryItem from './PhotoGalleryItem';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const PhotoGalleryList = ( { data } ) => {
  const configContextStuff = useContext( ConfigContext );
  const { config } = configContextStuff;
  const [ galleries, setGalleries ] = useState( [] );

  useEffect( () => {
    if ( data ) {
      const nodes = normalizeData( data );
      setGalleries( nodes );
    }
  }, [ data ] );

  const normalizeData = ( responseData ) => {
    const nodes = [];
    const drupalData = responseData.data;
    const drupalIncluded = responseData.included;

    const drupalIncludedById = {};
    if ( drupalIncluded ) {
      drupalIncluded.forEach( ( item, index ) => {
        const { id } = item;
        drupalIncludedById[id] = item;
      } );
    }

    if ( drupalData.length ) {
      drupalData.forEach( ( item, index ) => {
        const node = {
          uuid: item.id,
          modalInfo: {
            title: {
              title: item.attributes.title,
            },
          },
        };

        const coverImageFieldData = drupalData[index].relationships.field_cover_photo.data;
        if ( coverImageFieldData ) {
          node.coverImage = coverImageFieldData.meta.imageDerivatives?.links.one_third_screen.href;
        }

        nodes.push( node );
      } );
    }

    return nodes;
  };

  return (
    <>
      {!galleries.length
        && <LoadingSpinner />}

      {galleries.map( ( node ) => (
        <PhotoGalleryItem
          {...node}
          key={node.uuid}
          makeButton
          imageClass="slideshowPhoto"
          galleryClass="slideshow"
        />
      ) )}
    </>
  );
};

export default PhotoGalleryList;
