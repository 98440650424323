import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/no-danger
const MapChunk = ( { markupString } ) => <g dangerouslySetInnerHTML={{ __html: markupString }} />;

MapChunk.propTypes = {
  markupString: PropTypes.string,
};

MapChunk.defaultProps = {
  markupString: '',
};

export default MapChunk;
