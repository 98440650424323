import React from 'react';

import './FurnitureConfigurator.scss';
// import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const FurnitureConfigurator = ( { id, url, className } ) => {
  // Check that we have everything we need to proceed.
  if ( !url ) return null;

  return (
    <div id={id} className={`furnitureConfigurator ${className}`}>
      {/* <LoadingSpinner /> */}
      <iframe title="Furniture Configurator" className="furnitureConfigurator__iframe" src={url} />
    </div>
  );
};

export default FurnitureConfigurator;
