import React from 'react';

const SlidingPanel = ({
  index,
  activePanelIndex,
  setActivePanelIndex,
  children,
}) => {
  const isActive = index === activePanelIndex;
  // The current active panel and the previous panel should always be visible
  const isVisible =
    index === activePanelIndex || index === activePanelIndex - 1;

  const panelDisabledClass = !isActive ? 'mapFilter-panel--disabled' : 'mapFilter-panel--active';
  const panelOptionsVisibleClass = isVisible
    ? 'mapFilter-panel-options--show'
    : '';

  const goToPrevPanel = () => {
    const prevPanelIndex = activePanelIndex === 0 ? 0 : activePanelIndex - 1;
    setActivePanelIndex(prevPanelIndex);
  };

  return (
    <div className={`mapFilter-panel ${panelDisabledClass}`}>
      <button
        className="mapFilter-backBtn"
        style={{
          display: `${index === activePanelIndex - 1 ? 'block' : 'none'}`,
        }}
        onClick={goToPrevPanel}
      />
      <div className={`mapFilter-panel-options ${panelOptionsVisibleClass}`}>
        {children}
      </div>
    </div>
  );
};

export default SlidingPanel;
