import React, { useContext } from 'react';

import ConfigContext from '../../../../utils/ConfigContext/ConfigContext';

const AccountNotFoundStep = ( {
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  setCurrentStep,
} ) => {
  const { crmConfig } = useContext( ConfigContext );
  const {
    humanName,
    machineName,
    loginUrl,
  } = crmConfig;

  const instructions = 'THERE ARE NO RESULTS MATCHING YOUR SEARCH';

  return (
    <div className="personalization__not-found-step">

      <h3 className="personalization__form-header">
        Personalize
        {' '}
        <span>Your</span>
        Tour
      </h3>

      <div className="personalization__instructions">{instructions}</div>

      <div className="personalization__inline-buttons">

        { ( machineName === 'drupal' )
          && (
          <button
            disabled={isSubmitting}
            type="submit"
            className="personalization__cta personalization__cta--secondary"
            onClick={() => {
              setFieldValue( 'action', 'create new' );
            }}
          >
            Create New
          </button>
          )}

        { ( machineName !== 'drupal' )
          && (
          <a
            href={loginUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="personalization__cta personalization__cta--secondary"
            onClick={() => {
              setCurrentStep( 1 );
            }}
          >
            Create New in
            {' '}
            {humanName}
          </a>
          )}
      </div>

    </div>
  );
};

export default AccountNotFoundStep;
