import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MapContext from '../../MapContext';

const CategoryButtons = ({ categoryLabel, category, goToNextPanel }) => {

  const mapContextStuff = useContext(MapContext);
  const {
    setActiveCategoryFilter,
    activeFilterSelections,
    setActiveFilterSelections
  } = mapContextStuff;

  // @TODO: TBH, I don't remember what this was for. Need to follow up.
  // Display mode options:
  // 'active' --> floor plan is on and matches both LOC and filter
  // 'inactive' --> floor plan is on and matches LOC, but does not match filter
  // 'disabled' --> floor plan is off and does NOT match LOC

  const handleClick = (event) => {
    setActiveCategoryFilter(category);
    const clickTarget = event.target;

    // Check that the element clicked *isn't* the close button, then advance
    // to the next menu panel.
    if (!clickTarget.classList.contains('mapFilter-option-tag--remove')) {
      goToNextPanel(event);
    }
  };

  const handleRemoveFilter = (event) => {
    const tagItem = event.target;
    const tagUUID = tagItem.dataset.filterUuid;

    // Making a deep copy of the filter selection. We don't want
    // copy-by-reference here.
    const activeFilterSelectionsCopy = JSON.parse(JSON.stringify(activeFilterSelections));

    // If the item being removed is the last one in the category, also
    // remove the category.
    if (Object.keys(activeFilterSelectionsCopy[category]).length == 1) {
      delete activeFilterSelectionsCopy[category];
    }
    else {
      delete activeFilterSelectionsCopy[category][tagUUID];
    }

    setActiveFilterSelections(activeFilterSelectionsCopy);
  }

  // Generate 'tags' on the Category panel with the user's active selections.
  let activeSelectionTags = [];
  if (activeFilterSelections[category]) {
    activeSelectionTags = Object.keys(activeFilterSelections[category]).map((selectedOptionUUID, index) => {
      const selectionTag = activeFilterSelections[category][selectedOptionUUID];
      return (
        <span key={index} className="mapFilter-option-tag">
          {selectionTag}
          <span
            className="mapFilter-option-tag--remove"
            onClick={handleRemoveFilter}
            data-filter-uuid={selectedOptionUUID}
          >CLOSE</span>
        </span>
      );
    })
  }

  return (
    <button
      className="mapSelector-option mapFilter-option type-cta-alt-md mapFilter-option--more"
      onClick={handleClick}
    >
      <span className="mapFilter-optionName">{categoryLabel}</span>
      <div className="mapFilter-option-tags">
        {activeSelectionTags}
      </div>
    </button>
  );
};

CategoryButtons.propTypes = {
  categoryLabel: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  goToNextPanel: PropTypes.func.isRequired,
};

export default CategoryButtons;