import React, {
  useCallback, useRef, useEffect, useState,
} from 'react';

import { getCenter } from '../../utils/common';

import GoogleMapStyle from '../MapPage/GoogleMap/GoogleMapStyle';

const MapTilesGoogleMap = ( {
  commName,
  lat,
  lng,
  address,
  tileZoom,
  setTileZoom,
} ) => {
  const googleMapContainer = useRef();
  const [ tileMap, setTileMap ] = useState( null );
  const [ tileCenter, setTileCenter ] = useState( null );

  // get pin latlng
  useEffect( () => {
    const initializeCenter = async () => {
      const center = await getCenter(
        { lat, lng },
        address,
      );
      return new Promise( ( resolve, reject ) => {
        resolve( center );
      } );
    };
    if ( address || ( lat && lng ) ) {
      initializeCenter().then( ( center ) => {
        setTileCenter( center );
      } );
    }
  }, [
    address, lat, lng,
  ] );

  // Load Google Maps API and create map at center
  useEffect( () => {
    if ( tileCenter ) {
      setTileMap( () => {
        const mapOptions = {
          center: tileCenter,
          zoom: tileZoom,
          styles: GoogleMapStyle,
        };
        return new window.google.maps.Map(
          googleMapContainer.current,
          mapOptions,
        );
      } );
    }
  }, [ tileCenter, tileZoom ] );

  // add grid overlay
  useEffect( () => {
    if ( tileMap ) {
      function CoordMapType( tileSize ) {
        this.tileSize = tileSize;
      }

      CoordMapType.prototype.getTile = function ( coord, zoom, ownerDocument ) {
        const div = ownerDocument.createElement( 'div' );
        div.innerHTML = coord;
        div.style.width = `${this.tileSize.width}px`;
        div.style.height = `${this.tileSize.height}px`;
        div.style.fontSize = '10';
        div.style.borderStyle = 'solid';
        div.style.borderWidth = '1px';
        div.style.borderColor = '#AAAAAA';
        return div;
      };

      tileMap.overlayMapTypes.insertAt(
        5,
        new CoordMapType( new window.google.maps.Size( 256, 256 ) ),
      );
    }
  }, [ tileMap ] );

  // add community pin
  useEffect( () => {
    if ( tileMap && tileCenter ) {
      const marker = new window.google.maps.Marker( {
        position: tileCenter,
        label: commName || 'Community',
      } );

      marker.setMap( tileMap );
    }
  }, [
    tileMap, tileCenter, commName,
  ] );

  // get zoom info
  const setTileZoomCallback = useCallback( () => {
    const newZoom = tileMap.getZoom();
    setTileZoom( newZoom );
  }, [ tileMap, setTileZoom ] );

  useEffect( () => {
    if ( tileMap ) {
      tileMap.addListener( 'zoom_changed', ( event ) => {
        setTileZoomCallback();
      } );
    }
  }, [ tileMap, setTileZoomCallback ] );

  return <div className="mapShell" ref={googleMapContainer} />;
};

export default MapTilesGoogleMap;
