import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';

import FormItem from '../FormComponents/FormItem';

const AccountLookupStep = ( {
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  isSubmitting,
  setHasCRMResults,
} ) => {
  const [ formHasErrors, setFormHasErrors ] = useState( Object.keys( errors ).length && errors.email );

  const setHasCRMResultsCallback = useCallback( () => {
    setHasCRMResults( false );
  }, [ setHasCRMResults ] );

  useEffect( () => {
    setHasCRMResultsCallback();
  }, [ setHasCRMResultsCallback ] );

  useEffect( () => {
    if ( Object.keys( errors ).length && errors.email ) {
      setFormHasErrors( true );
    } else {
      setFormHasErrors( false );
    }
  }, [ errors ] );

  return (
    <div className={classNames( 'personalization__form-lookup-step', { hasWholeFormErrors: formHasErrors } )}>

      <h3 className="personalization__form-header">
        Personalize
        {' '}
        <span>Your</span>
        Tour
      </h3>

      <div className="personalization__instructions">Enter a first name and/or a last name and/or a valid email address and click the CONTINUE button.</div>

      <div className="form__items">

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.first_name}
          touched={touched}
          errors={errors}
          type="text"
          name="first_name"
          label="First Name"
        />

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.last_name}
          touched={touched}
          errors={errors}
          type="text"
          name="last_name"
          label="Last Name"
        />

        <FormItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values?.email}
          touched={touched}
          errors={errors}
          type="email"
          name="email"
          label="Email Address"
        />

      </div>

      <button
        disabled={isSubmitting}
        type="submit"
        className={classNames( 'personalization__cta personalization__cta--secondary', {
          'personalization__cta--working': isSubmitting,
        } )}
      >
        { isSubmitting ? 'Searching . . .' : 'Continue' }
      </button>

    </div>
  );
};

export default AccountLookupStep;
