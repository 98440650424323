import React from 'react';

import MapControlsZoomLessIcon from './MapControlsZoomLessIcon';

const MapControlsZoomLess = ( { setActiveZoom, activeZoom, minZoom } ) => {
  const zoomLess = () => {
    const newZoom = activeZoom - 1;
    setActiveZoom( newZoom );
  };

  let buttonClass = '';
  if ( activeZoom ) {
    if ( activeZoom <= minZoom ) {
      buttonClass = 'mapControl--disabled';
    }
  }

  return (
    <button
      type="button"
      className={`mapControl mapControls-zoom--less ${buttonClass}`}
      onClick={zoomLess}
    >
      <MapControlsZoomLessIcon />
    </button>
  );
};

export default MapControlsZoomLess;
