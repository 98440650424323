/* eslint-disable import/prefer-default-export */
import { processSerializedThreeSixties } from '../VirtualTour/VirtualTourDetailNormalizeData';

export function includedMediaNormalizedData( serializedIncludedMediaData, drupalIncluded ) {
  let media = [];

  if ( serializedIncludedMediaData ) {
    // Massage the data into the shape we care about for floorplans.
    media = serializedIncludedMediaData.map( ( medium ) => {
      // coverImage
      let coverImage = null;
      const coverImageFieldId = medium.fieldCoverPhoto?.id;
      if ( coverImageFieldId ) {
        const coverImageFieldData = drupalIncluded.find(
          ( include ) => include.id === coverImageFieldId,
        );
        coverImage = {
          oneThirdScreen: coverImageFieldData?.links?.one_third_screen?.href,
          modal: coverImageFieldData?.links?.modal?.href,
        };
      }

      // photo gallery
      const photoGalleryImages = [];
      if ( medium?.fieldGalleryPhotos ) {
        const serializedImages = medium.fieldGalleryPhotos;
        serializedImages.forEach( ( serializedImage, i ) => {
          const imageId = serializedImage.id;
          const includedImageData = drupalIncluded.find(
            ( include ) => include.id === imageId,
          );
          // i can't find the caption data right now
          const image = {
            id: imageId,
            image: {
              modal: includedImageData?.links?.modal?.href,
            },
            caption: null,
          };
          photoGalleryImages.push( image );
        } );
      }

      // virtual tour
      const threeSixtiesData = medium?.fieldThreeSixties;
      const threeSixties = processSerializedThreeSixties( threeSixtiesData );

      // video
      let video = null;
      if ( medium?.fieldVideoService && medium?.fieldVideoId ) {
        video = {
          videoId: medium.fieldVideoId,
          service: medium.fieldVideoService,
          autoplay: false,
        };
      }

      // get node type
      let type = null;
      if ( photoGalleryImages.length > 0 ) {
        type = 'photo_gallery';
      } else if ( threeSixties.length > 0 ) {
        type = 'virtual_tour';
      } else if ( video ) {
        type = 'video';
      }

      return {
        id: medium.id,
        type,
        title: medium.title,
        coverImage,
        photoGalleryImages,
        threeSixties,
        video,
      };
    } );
  }

  return media;
}
