import React from 'react';

const PaneButton = ({ 
  btnText, 
  paneDataId, 
  setActivePaneId, 
  activePaneId
}) => {

  let className = activePaneId === paneDataId ? 'paneButton paneButton--active' : 'paneButton';

  const handleOnClick = (paneDataId) => {
    setActivePaneId(paneDataId);
  }

  return (
    <button className={className} onClick={ () => handleOnClick(paneDataId) }>
      { btnText } 
    </button>
  );
}

export default PaneButton;