import React, { useState, useEffect } from 'react';

const PanelSlider = ({ isMenuOpen, children, activePanelIndex, setActivePanelIndex }) => {
  const [sliderPosition, setSliderPosition] = useState(0);

  const activeClass = isMenuOpen ? 'mapFilter-options--open' : '';

  useEffect(() => {
    const panelWidth = 280;
    const panelTotalLRMargin = 20;
    setSliderPosition(-(panelWidth + panelTotalLRMargin) * activePanelIndex);
  }, [activePanelIndex]);

  return (
    <div
      className={`mapSelector-options mapFilter-options ${activeClass}`}
      style={{ transform: `translateX(${sliderPosition}px)` }}
    >
      <div className="mapFilter-flex">
        {children}
      </div>
    </div>
  );
};

export default PanelSlider;
