import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const EndPersonalization = ({
  cookieDataDump,
  triggerWelcomeMessage,
  welcomeMessageSaved,
}) => {
  const [buttonText, setButtonText] = useState('Copy Link');
  const [welcomeButtonText, setWelcomeButtonText] = useState(
    'Personalize Welcome Message'
  );

  const resident = cookieDataDump;
  const myFavoritesPage = cookieDataDump.my_favorites_page;

  useEffect(() => {
    const displayedFees = JSON.parse(localStorage.getItem('userDisplayedFees'));
    if (displayedFees) {
      localStorage.removeItem('userDisplayedFees');
    }
    document.body.classList.remove(
      'toggleDisplayFees',
      'toggleDisplayFees__finished'
    );

    const savedMessage = cookies.get('welcome_message');
    const favoritesPageMessage = resident?.favorites_page_message;

    if (savedMessage) {
      setWelcomeButtonText('Edit Welcome Message');
    } else if (favoritesPageMessage) {
      cookies.set('welcome_message', favoritesPageMessage, {
        path: '/',
        maxAge: 14400, // 4 hour cookie expiration
        domain: window.location.hostname,
      });
      setWelcomeButtonText('Edit Welcome Message');
    }
  }, [cookieDataDump]);

  const POBBlurbMessage = () => {
    const nameArray = [resident.first_name];
    if (resident.person2_first_name !== '') {
      nameArray.push(resident.person2_first_name);
    }
    const residentNames = nameArray.join(' and ');
    return `${residentNames}'s personalized digital brochure can be accessed by visiting the link below.`;
  };

  const copyLink = () => {
    const input = document.getElementById(
      'personalization__myFavoritesPage-input'
    );
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
    setButtonText('Link Copied');
  };

  const handleWelcomeMessageClick = (e) => {
    e.preventDefault();
    if (typeof triggerWelcomeMessage === 'function') {
      triggerWelcomeMessage();
    } else {
      console.error('triggerWelcomeMessage function not provided');
    }
  };

  return (
    <div className="personalization__not-found-step">
      {welcomeMessageSaved && (
        <div className="personalization__saved-message">
          Your Welcome Message has been saved.
        </div>
      )}
      <h3 className="personalization__form-header">
        Personalization is Now <span>Off</span>
      </h3>

      <div className="personalization__instructions">
        <POBBlurbMessage />
      </div>

      <input
        defaultValue={resident?.my_favorites_page}
        type="text"
        name="my_favorites_page"
        label="My Favorites Page"
        id="personalization__myFavoritesPage-input"
      />

      <a
        className="personalization__myFavoritesPage-link"
        href={myFavoritesPage}
        target="_blank"
        rel="noopener noreferrer"
      >
        {myFavoritesPage}
      </a>

      <div className="personalization__inline-buttons-end">
        <button
          type="button"
          className={classNames('personalization__cta', {
            'personalization__cta--inactive': buttonText === 'Link Copied',
          })}
          onClick={copyLink}
        >
          {buttonText}
        </button>
        <button
          type="button"
          className="personalization__cta-welcome"
          onClick={handleWelcomeMessageClick}
        >
          {welcomeButtonText}
        </button>
      </div>
    </div>
  );
};

EndPersonalization.propTypes = {
  cookieDataDump: PropTypes.shape({
    first_name: PropTypes.string,
    person2_first_name: PropTypes.string,
    my_favorites_page: PropTypes.string,
    field_favorites_page_message: PropTypes.string,
  }).isRequired,
  triggerWelcomeMessage: PropTypes.func.isRequired,
  welcomeMessageSaved: PropTypes.bool,
};

export default EndPersonalization;
