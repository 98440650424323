/* eslint-disable import/prefer-default-export */

import { processFees } from '../../utils/common';
import { finishCollectionNormalizeData } from '../FinishCollection/FinishCollectionNormalizeData';
import { processSerializedThreeSixties } from '../VirtualTour/VirtualTourDetailNormalizeData';

export function includedFloorPlansNormalizedData(
  serializedIncludedFloorPlansData,
  drupalIncluded,
  drupalRawIncluded,
) {
  let floorPlans = {};
  const levelsOfCare = {};
  const floorPlanCategories = {};
  let defaultLevelOfCare = '';

  if ( serializedIncludedFloorPlansData ) {
    // Massage the data into the shape we care about for floorplans.
    floorPlans = serializedIncludedFloorPlansData.map( ( fp ) => {
      // 2d floorplan
      let twoDFloorPlan = null;
      const twoDFpFieldId = fp.field2dFloorPlan?.id;
      if ( twoDFpFieldId ) {
        const twoDFpFieldData = drupalIncluded.find( ( include ) => include.id === twoDFpFieldId );
        twoDFloorPlan = {
          oneThirdScreen: twoDFpFieldData?.links?.one_third_screen?.href,
          modal: twoDFpFieldData?.links?.modal?.href,
        };
      }

      // 3d floorplan
      let threeDFloorPlan = null;
      const threeDFpFieldId = fp.field3dFloorPlan?.id;
      if ( threeDFpFieldId ) {
        const threeDFpFieldData = drupalIncluded.find(
          ( include ) => include.id === threeDFpFieldId,
        );
        threeDFloorPlan = {
          oneThirdScreen: threeDFpFieldData?.links?.one_third_screen?.href,
          modal: threeDFpFieldData?.links?.modal?.href,
        };
      }

      // Downloadable PDF.
      let downloadablePdfPath = null;
      const fpPdfFieldId = fp.fieldDownloadablePdf?.id;
      if ( fpPdfFieldId ) {
        const cmsUrl = process.env.REACT_APP_YOUR_TOUR_CMS_URL;
        const fpPdfFieldData = drupalIncluded.find(
          ( include ) => include.id === fpPdfFieldId,
        );
        downloadablePdfPath = cmsUrl + fpPdfFieldData.attributes.uri.url;
      }

      levelsOfCare[fp.fieldLevelOfCare?.id] = {
        name: fp.fieldLevelOfCare?.name,
        weight: fp.fieldLevelOfCare?.weight,
        id: fp.fieldLevelOfCare?.id,
        isDefault: fp.fieldLevelOfCare?.fieldDefaultLevelOfCare,
      };

      // this needs to be reworked to take into account multiple categories per floor plan
      // add 1 to the weight because "all floor plans" gets the 0 weight
      floorPlanCategories[fp.fieldFloorPlanCategory?.[0]?.id] = {
        name: fp.fieldFloorPlanCategory?.[0]?.name,
        weight: fp.fieldFloorPlanCategory?.[0]?.weight + 1,
        id: fp.fieldFloorPlanCategory?.[0]?.id,
      };

      // Set the default level of care.
      if ( fp.fieldLevelOfCare?.fieldDefaultLevelOfCare ) {
        defaultLevelOfCare = fp.fieldLevelOfCare?.id;
      }

      // Save fees into specific data structure
      const roomFees = processFees(
        fp.fieldEntranceFee1,
        fp.fieldEntranceFee2,
        fp.fieldMonthlyFee1,
        fp.fieldMonthlyFee2,
      );

      const roomDetails = fp.fieldRooms.map( ( roomData ) => ( {
        label: roomData.name,
        data: roomData.dimensions,
      } ) );

      // virtual tour
      const threeSixtiesData = fp?.fieldVirtualTour?.fieldThreeSixties;
      const threeSixties = processSerializedThreeSixties( threeSixtiesData );

      // finish collection
      const finishOptionData = fp?.fieldFinishCollection?.fieldFinishOption;
      const finishCollection = finishCollectionNormalizeData(
        finishOptionData,
        drupalRawIncluded,
      );

      // furniture configurator
      const furnitureConfiguratorUrl = fp?.fieldFurnitureConfiguratorUrl?.uri;

      // video
      let video = null;
      if ( fp?.fieldVideo?.fieldVideoService && fp?.fieldVideo?.fieldVideoId ) {
        video = {
          videoId: fp.fieldVideo.fieldVideoId,
          service: fp.fieldVideo.fieldVideoService,
          autoplay: false,
        };
      }

      // photo gallery
      const photoGalleryImages = [];
      if ( fp.fieldPhotoGallery && fp.fieldPhotoGallery.fieldGalleryPhotos ) {
        const serializedImages = fp.fieldPhotoGallery.fieldGalleryPhotos;
        serializedImages.forEach( ( serializedImage, i ) => {
          const imageId = serializedImage.id;
          const includedImageData = drupalIncluded.find( ( include ) => include.id === imageId );
          // i can't find the caption data right now
          const image = {
            id: imageId,
            image: {
              modal: includedImageData?.links?.modal?.href,
            },
            caption: null,
          };
          photoGalleryImages.push( image );
        } );
      }

      // If the floor plan has Den, Show it in details.
      let withDen = 'No';
      if ( fp.fieldWithDen ) {
        withDen = 'Yes';
      }

      return {
        id: fp.id,
        title: fp.title,
        description: fp.fieldDescription,
        floorplan2d: twoDFloorPlan,
        floorplan3d: threeDFloorPlan,
        downloadablePdf: downloadablePdfPath,
        bed: fp.fieldBedrooms,
        withDen: withDen,
        bath: fp.fieldBathrooms,
        studio: fp.fieldStudio,
        sqft: fp.fieldSquareFootage,
        levelsOfCare: fp.fieldLevelOfCare?.id,
        levelsOfCareName: fp.fieldLevelOfCare?.name,
        floorPlanCategory: fp.fieldFloorPlanCategory?.[0]?.id,
        floorPlanCategoryName: fp.fieldFloorPlanCategory?.[0]?.name,
        roomData: roomDetails,
        roomFees,
        threeSixties,
        finishCollection,
        furnitureConfiguratorUrl,
        video,
        photoGalleryImages,
      };
    } );
  }
  return {
    floorPlans,
    levelsOfCare,
    defaultLevelOfCare,
    floorPlanCategories,
  };
}
